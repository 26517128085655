@import url("../../style/index.less");

.messageMediaItem {
  min-width: 50%;
  max-width: 75%;
  background: @background-message-green-color;
  padding: 10px 16px 9px 12px;
  border-radius: 18px;

  &.expired {
    width: 70%;
  }

  &.accepted {
    background-color: #2e904a !important;
  }
}

.validationBtn {
  width: 25px !important;
  height: 25px !important;
}

.validationCircle {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 31px;
  height: 31px;
  background-color: @white-color !important;
  border-radius: 50%;

  &.disabled {
    background-color: @grey-color !important;
  }
}

.disabled {
  background-color: @grey-color !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.sendMediaButton {
  width: 100%;
  padding: 5px 15px;
  margin-top: 10px;
  background-color: #ffffff !important;
  font-family: @primary-font-medium;
  color: #000000 !important;
  border: none !important;
  border-radius: 12px;

  &:disabled {
    background-color: #a2a2a2 !important;
  }
}
