@import url("../../../../style/index.less");

.collection-training-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: end;
  z-index: 1000;
  overflow: hidden;

  .collection-training-overlay-content {
    padding: 0px 40px 0;
    width: 100%;

    .collection-training-overlay-gif {
      width: 150px;
    }

    .collection-training-overlay-text {
      font-family: @primary-font-semi-bold;
      font-size: 15px;
      color: #ffffff;
      line-height: 26px;
      text-align: center;
    }
  }

  .collection-training-overlay-footer {
    text-align: center;
    padding: 20px;
    width: 100%;
    margin-bottom: 10px;

    .collection-training-overlay-button {
      font-family: @primary-font-semi-bold;
      background-color: #ffffff;
      color: #000000;
      border: none;
      padding: 15px;
      width: 85%;
      max-width: 500px;
      border-radius: 30px;
      cursor: pointer;
      font-size: 19px;
    }
  }
}
