@import url("../../../../style/index.less");

.creator-formations-page-wrapper {
  min-height: 100dvh !important;
  padding: 0;
  background-color: #000000;

  .formation-header {
    position: relative;
    padding: 20px 20px;
    height: 250px;
    background: linear-gradient(180deg, #181818 0%, #0e40f0 100%);

    .back-icon {
      position: absolute;
      top: 30px;
      left: 30px;
    }

    .formation-header-creator-name-row {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 50px;

      .formation-header-creator-name-text {
        display: flex;
        text-align: center;
        font-family: @primary-font-light;
        font-size: 16px;
        color: #ffffff;
      }
    }

    .formation-header-creator-title-row {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 10px;
      padding: 0 15px;

      .formation-header-creator-title-text {
        display: flex;
        text-align: center;
        font-family: @primary-font-black;
        font-size: 30px;
        line-height: 42px;
        color: #ffffff;
      }
    }
  }

  .formation-content {
    position: relative;
    padding: 10px 10px 20px;
    margin-top: -55px;

    .formation-item {
      border-radius: 15px;
      background-color: #161619;
      padding: 0;
      margin-bottom: 12px;
      min-height: 200px;
      max-height: 300px;
      display: flex;
      flex-direction: column;

      &.h-180 {
        min-height: 180px !important;
      }

      .formation-item-img-container {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;

        .formation-item-img {
          position: relative;
          width: 90px;
          object-fit: contain;

          &.lock {
            width: 50px;
            padding-top: 10px;
          }

          &.media-push-first {
            width: 45px;
            height: auto;
            margin-right: 15px;
          }

          &.media-push-second {
            width: 55px;
            height: auto;
          }

          &.collection {
            margin-top: 10px;
            width: 90%;
          }

          &.chat {
            margin-top: 10px;
            width: 60%;
          }

          &.gift {
            width: 60%;
          }

          &.search {
            margin-top: 10px;
          }

          &.profile-first {
            width: 55px;
            height: auto;
          }

          &.profile-second {
            position: absolute;
            top: 0;
            right: 0;
            width: 65px;
            height: auto;
          }

          &.paiement-first {
            width: 45px;
            height: auto;
            margin-right: 30px;
            margin-bottom: 10px;
          }

          &.paiement-second {
            width: 40px;
            height: auto;
          }
        }

        &.linear-background-blue {
          background: linear-gradient(180deg, #3f53e8 0%, #9caffd 100%);
        }

        &.lineard-background-dark {
          background: linear-gradient(180deg, #00080f 0%, #070708 100%);
        }

        &.media {
          align-items: end;
        }

        &.profile {
          padding-left: 15px;
          justify-content: space-between;
        }

        &.paiement {
          align-items: end;
          min-height: 100px;
        }
      }

      .formation-item-text-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 15px;
      }

      .formation-item-title {
        font-family: @primary-font-heavy;
        font-size: clamp(1rem, 1.5vw, 2.7rem);
        color: #ffffff;
        text-transform: uppercase;
      }

      .formation-item-description {
        margin-top: 3px;
        font-family: @primary-font-regular;
        font-size: clamp(0.85rem, 1.2vw, 2rem);
        color: #9ba1b3;
      }

      .formation-item-button {
        display: inline-block;
        background-color: #ffffff;
        border: none;
        border-radius: 30px;
        cursor: pointer;
        font-family: @primary-font-medium;
        font-size: 14px;
        padding: 10px 28px;
      }

      .formation-item-left-section {
        display: flex;
        flex-direction: column;
        padding: 15px;
        gap: 6px;
        width: 78%;
      }

      .formation-item-right-section {
        width: 22%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .profile-trunk-img {
          max-width: 80%;
          height: auto;
        }
      }

      .py-20 {
        padding: 0 20px;
      }

      .discover-container {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .discover-text {
          font-family: @primary-font-regular;
          font-size: clamp(0.85rem, 1.2vw, 2rem);
          color: #ffffff;
          margin-right: 5px;
        }

        .formation-chevron-right {
          width: 4px;
        }
      }

      &.generate-traffic {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }

    .formation-grid {
      display: flex;
      justify-content: space-between;
      gap: 10px;

      .formation-item {
        width: 50%;
      }
    }
  }
}
