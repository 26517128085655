@import url("../../../style/index.less");

.creatorNewResponseContent {
  padding-top: 15px;
  min-height: 100dvh !important;
  background-color: @light-black-color;

  .creatorNewResponseHeader {
    justify-content: space-between;
    padding: 20px 18px 18px 20px;
  }

  .creatorNewResponseTitle {
    width: 100%;
    padding: 27px 15px 0 15px;
  }

  .creatorNewResponseBody {
    width: 100%;
    padding: 0 35px 0 35px;

    .titleInputNewResponse {
      margin-top: 5px;
      font-family: @primary-font-bold;
      font-size: 48px;
      width: 100%;
      height: 100%;
      color: #ffffff !important;
      font-weight: 900;
      text-align: center;
      line-height: 60px;
      border-bottom: 1px solid @grey-color;
      border-radius: 0;
    }

    .textInputNewResponse {
      margin-top: 5px;
      font-family: @primary-font-regular;
      font-size: 30px;
      width: 100%;
      height: 100%;
      color: #ffffff !important;
      font-weight: 900;
      line-height: 60px;
      text-align: center;
      padding-bottom: 20px;
      border-bottom: 1px solid @grey-color;
      border-radius: 0;
    }

    .titleInputNewResponse::placeholder {
      color: #797979;
      font-size: 48px;
    }

    .textInputNewResponse::placeholder {
      font-family: @primary-font-bold;
      font-size: 35px;
      color: #797979;
    }
  }
}

.font-12-light {
  font-family: @primary-font-light;
  font-size: 12px;
}

// .input-border-bottom {
//   border-bottom: 1px solid @grey-color;
// }
