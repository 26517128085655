@import url("../../../style/index.less");

.CreatorAlbumContentWrapper {
  min-height: 100dvh !important;
  padding: calc(@creator-codePromotion-header-height) 1px 20px 1px;
  .p-1 {
    padding: 1px;
  }
  .selectIcon {
    right: 5px;
    top: 5px;
    z-index: 1;
  }

  .cover {
    width: 60px;
    height: 60px;
    right: 0;
    position: absolute;
    z-index: 2;
  }

  .fanBookmarksContent {
    .ant-col {
      max-height: 140px;
      min-height: 140px;
    }

    .ant-image {
      width: 100%;
      height: 100%;
      max-height: 140px;
      min-height: 140px;

      .ant-image-img {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover !important;
      }
    }

    .albumVideoIcon {
      position: absolute;
      z-index: 1;
      width: 30px;
      height: 30px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }

    .imagePlaceHolder {
      width: 100%;
      height: 100%;
      border: 1px solid @grey-color;
    }
  }
}

.header-container-title-quick-file {
  margin-top: -60px;
}
.creatorAlbumButton {
  padding: 20px 20px !important;

  .button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .half-width-button {
    width: 49% !important;
  }

  .CreatorAlbumActionButton {
    color: @black-color !important;
    font-size: 16px;
    font-family: @primary-font-medium;
    padding: 20px 20px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none !important;
    background-color: @white-color;

    &.disable {
      background: rgba(255, 255, 255, 0.4) !important;
    }
  }

  .justify-between {
    justify-content: space-between;
  }
  .justify-center {
    justify-content: center;
  }
}
.deleteCreatorThreeDotsModal {
  max-width: 308px;

  .ant-modal-content {
    background: linear-gradient(to top, #050505, #232323) !important;
    padding: 16px 0 0 0;
    border-radius: 27px;

    .ant-modal-header {
      background-color: transparent;
      padding: -2px 20px;
      margin-bottom: 0;

      .ant-modal-title {
        font-family: @primary-font-bold;
        font-size: 22px;
        line-height: 30px;
        min-height: 80px;
        text-align: center;
        color: @white-color;
      }
    }
  }

  .ant-modal-footer {
    text-align: center;
    margin-top: -10px;

    .row {
      width: 100%;
      justify-content: center;
      padding: 15px 0;

      .actionBtn {
        font-family: @primary-font-regular;
        font-style: normal;
        font-size: 18px;
        line-height: 21px;
        color: @black-color;
        height: 100%;

        .confirm-btn-background {
          font-family: @primary-font-bold;
          background-color: @white-color;
          padding: 10px 35px;
          border-radius: 20px;
        }
      }

      .rejectBtn {
        font-family: @primary-font-bold;
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        color: @white-color;
      }
    }
  }
}

.ant-image-preview {
  pointer-events: none;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-color: rgb(0 0 0 / 80%);
}

.video-preview-wrapper {
  pointer-events: all;
}

.CreatorAlbumFooter {
  padding: 30px 30px !important;
  background-color: transparent !important;
  position: fixed;
  z-index: 2;
  bottom: 0px;
  width: 100%;
  max-width: 980px;

  .CreatorAlbumSelectButton {
    width: 100%;
    color: #000000;
    font-size: 18px;
    font-family: @primary-font-medium;
    padding: 22px 40px;
    border-radius: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: #ffffff;
    z-index: 102;
    margin: 0 auto;
    &.disable {
      background: rgba(255, 255, 255, 0.4) !important;
      z-index: 102;
    }
  }
}

.header-album-container {
  width: 70%;
  display: flex;
  justify-content: center;

  .header-album-title {
    color: @white-color;
    font-family: @primary-font-black;
    font-size: 28px;
    text-align: center;
  }
}
