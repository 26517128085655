@import url("../../style/index.less");

.messageTextAreaWrapper {
  position: relative;
  width: 100%;

  .ant-input {
    height: 125px;
    color: @white-color;
    background-color: @background-input;
    padding: 12px 17px;
    border: 1px solid @border-input;
    border-radius: 16px;
    font-size: 16px;

    &::placeholder {
      color: @placeholder;
    }
  }

  img {
    position: absolute;
    right: 14px;
    bottom: 14px;
  }
}
