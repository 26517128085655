@import url("../../style/index.less");

.text-white {
  color: #ffffff !important;
}

.g-15 {
  gap: 15px !important;
}

.modal-divider {
  background-color: rgb(151, 151, 151) !important;
  height: 1px !important;
  width: 100%;
  margin: 0;
}

.requireShareMediaModal {
  max-width: 350px;

  .ant-modal-content {
    background: linear-gradient(to top, #050505, #232323) !important;
    padding: 16px 0 0 0;
    border-radius: 25px;

    .ant-modal-header {
      background-color: transparent;
      padding: 10px 8px 0 8px;
      margin-bottom: 0;

      .ant-modal-title {
        font-family: @primary-font-bold;
        font-size: 32px;
        line-height: 30px;
        text-align: center;
        color: @white-color;
      }
    }

    .ant-modal-body {
      padding: 16px 0px 16px 0px;

      .actionBtn {
        display: flex;
        justify-content: center;
        font-family: @primary-font-regular;
        font-style: normal;
        font-size: 18px;
        line-height: 21px;
        color: @white-color;
        height: 100%;

        .confirm-btn-background {
          width: 65%;
          font-family: @primary-font-medium;
          background-color: transparent;
          border: 1px solid #ffffff;
          padding: 20px 25px;
          border-radius: 30px;
        }
      }
    }

    .ant-modal-title {
      font-family: @primary-font-bold;
      font-size: 25px;
      line-height: 30px;
      text-align: center;
      color: @white-color;
    }

    .ant-modal-footer {
      margin-top: 5px;
    }
  }

  .ant-modal-footer {
    text-align: center;
    margin-top: 0;

    .requireSubscribeFooterRpw {
      width: 100%;
      justify-content: center;
      padding: 15px 0;

      button {
        font-family: @primary-font-bold;
        font-size: 18px;
        line-height: 24px;

        &.acceptBtn {
          color: @black-color;
        }

        &.rejectBtn {
          margin-top: -15px;
          font-size: 18px;
          color: @white-color;
        }
      }

      .backgroundBtn {
        width: 80%;
        padding: 8px 15px;
        background-color: @white-color;
        border-radius: 30px;
      }
    }
  }

  .title-modal {
    font-family: @primary-font-bold;
    font-size: 28px;
    line-height: 30px;
    color: @white-color;
  }
}
