@import "../../style/index.less";

.avatarWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 50%;
}

.avatar {
  border-radius: 50%;
  background: @black-color;
}

.ant-avatar {
  border: none !important;
}

.live-border {
  background: linear-gradient(45deg, #ff006b 13.47%, #ff79f2 86.53%);
  border-radius: 50%;
  padding: 3px;
  display: inline-flex;
}

.live-border .avatar {
  background: @black-color;
  border-radius: 50%;
}

.avatarBorder {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: @black-color;
  padding: 3px;
}

.live-badge {
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(252.74deg, #ff74ec 8.65%, #ff0570 93.44%);
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid white;
}

.avatar-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  .cross-icon {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 20px;
  }

  .avatar-large-image {
    width: 65vmin;
    height: 65vmin;
    object-fit: cover;
    border-radius: 50%;
  }
}

@media only screen and (max-width: 420px) {
  .responsive-name {
    font-size: 15px !important;
  }
}
