@import url("../../style/index.less");

.edit-profile-switch-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  background: #efefef1a;

  .edit-profile-switch-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-inline: 10px;
    padding: 2px;
    background: #34393f80;

    .edit-profile-switch {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px 15px;
      cursor: pointer;
      border-radius: 20px;
      width: 100%;

      &.selected {
        background: #525f6ceb;
      }

      .selected-icon {
        width: 12px;
        margin-right: 5px;
      }

      .edit-profile-switch-text {
        font-family: @primary-font-medium;
        font-size: 14px;
        color: #9f9fa1;
        white-space: nowrap;

        &.white {
          color: #ffffff;
        }
      }
    }
  }

  .edit-profile-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 16px;
    background: #efefef1a;

    .edit-profile-text {
      font-family: @primary-font-regular;
      font-size: 13px;
      line-height: 24px;
      color: @grey-color;
      text-align: center;
    }
  }
}
