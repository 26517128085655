@import url("../../style/index.less");

.FanAvatarWrapper {
  background-color: @dark-green-color;
  border-radius: 50%;
  margin: auto;
  text-transform: uppercase;
}
.label {
  font-family: @primary-font-semi-bold-rounded;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  padding: 2px 7px;
  color: @background-color;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}
.w-50 {
  width: 50px;
  height: 50px;
}
.w-60 {
  width: 60px;
  height: 60px;
}
.w-65 {
  width: 65px;
  height: 65px;
}
.w-65 > .label {
  font-size: 26px;
}
.w-70 {
  width: 70px;
  height: 70px;
}

.w-72 {
  width: 72px;
  height: 72px;
}
.w-72 > .label {
  font-size: 22px;
}
.w-50 > .label {
  font-size: 22px;
}
.w-30 {
  width: 30px;
  height: 30px;
}

.w-30 > .label {
  font-size: 12px;
}

.w-35 {
  width: 35px;
  height: 35px;
}

.w-35 > .label {
  font-size: 14px;
}
