@import url("../../../style/index.less");
.creatorQuickMessagesWrapper {
  min-height: 100dvh !important;
  padding: calc(@creator-monetizations-header-height + 20px) 10px 20px 10px;
}

.createQuickMessageButton {
  position: fixed;
  bottom: 30px;
  left: 0px;
  z-index: 10;
  padding: 0px 39px;
  width: 100%;
}
