@import url("../../style/index.less");

.switchAccountThreeDotsModal {
  max-width: 308px;

  .ant-modal-content {
    background: linear-gradient(to top, #0f0f0f, #4a4a4a) !important;
    padding: 16px 0 0 0;

    .ant-modal-header {
      background-color: transparent;
      padding: 0 20px;
      margin-bottom: 0;

      .ant-modal-title {
        font-family: @primary-font-medium;
        font-size: 28px;
        line-height: 28px;
        text-align: center;
        color: @white-color;
      }
    }
  }

  .ant-modal-footer {
    text-align: center;
    margin-top: 0;

    .row {
      width: 100%;
      justify-content: center;
      padding: 15px 0;

      .confirmBtn {
        font-family: @primary-font-medium;
        font-style: normal;
        font-size: 18px;
        line-height: 18px;
        color: @black-color;

        .confirm-btn-background{
          font-family: @primary-font-bold;
          background-color: @white-color;
          padding: 10px 35px;
          border-radius: 20px;
        }
      }

      .deleteBtn{
        font-family: @primary-font-bold;
        font-style: normal;
        font-size: 18px;
        line-height: 18px;
        color: @red-color;
      }

      .rejectBtn {
        margin-top: 10px;
        font-family: @primary-font-bold;
        font-style: normal;
        font-size: 16px;
        line-height: 18px;
        color: @white-color;
      }
    }
  }
}
