@import url("../../../style/index.less");

.progress-bar-wrapper {
  min-width: 40%;
  max-width: 55%;

  .progress-bar-title-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
    background: rgba(0, 0, 0, 0.5);
    padding: 8px 5px;

    .progress-bar-title {
      font-family: @primary-font-bold;
      font-size: 13px;
      color: #ffffff;
      text-align: center;
    }
  }

  .progress-bar-info-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.2);

    .progress-bar-price-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      .progress-bar-text {
        font-family: @primary-font-Thin;
        font-size: 16px;
        color: #ffffff;
        text-shadow: 0.5px 0.5px 0.5px black;

        .progress-bar-price {
          font-family: @primary-font-black;
          font-size: 16px;
          color: #ffffff;
        }
      }
    }

    .progress-bar-container {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      background: transparent;
      border-radius: 30px;
      border: 1px solid #ffffff;
      padding: 2px;
      overflow: hidden;

      .progress-bar {
        height: 12px;
        border-radius: 30px;
        background: linear-gradient(90deg, #ffcb3b 0%, #ff0022 91.5%);
      }

      .progress-bar-icon {
        position: absolute;
        height: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .progress-bar-success {
      width: 100%;
      display: flex;
      justify-content: center;

      .progress-bar-success-text {
        font-family: @primary-font-bold;
        font-size: 17px;
        color: #ffffff;
      }
    }
  }
}

@media (max-width: 380px) {
  .progress-bar-title {
    font-size: 11px !important;
  }
}
