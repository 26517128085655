@import url("../../../../../style/index.less");

.subscribe-view-collections-button-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;

  .subscribe-button,
  .view-collections-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48%;
    height: 46px;
    border: none;
    border-radius: 30px;
    font-family: @primary-font-medium;
    font-size: 14px;
    cursor: pointer;
    padding: 0 15px;

    img {
      object-fit: contain;
      margin-right: 10px;
      display: inline-block;
      vertical-align: middle;
      max-width: 10px;
      max-height: 10px;
      width: auto;
      height: auto;
    }
  }

  .subscribe-button {
    background: #001b7857;
    color: #4995ff;
  }

  .view-collections-button {
    background: #0d43ff;
    color: white;
  }
}
