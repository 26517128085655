@import url("../../style/index.less");

.direct-links-container {
  display: inline-flex;
  gap: 10px;

  .direct-link-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 30px;
    padding: 0 15px;
    background: #4d4d4d33;
    border-radius: 30px;

    .direct-link-text {
      font-family: @primary-font-regular;
      font-size: 12px;
      color: #828796;
    }
  }
}
