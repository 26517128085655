@import url("../../style/index.less");

.AutomaticMessageComponentWrapper {
  margin-top: 35px;
  width: 100%;

  .contentBox {
    background-color: #4d4d4d40;
    width: 100%;
    padding: 10px;
    border-radius: 12px;
    display: flex;
    gap: 15px;
    max-width: 95%;
    margin: auto;

    .add-media {
      border: 2px dashed @white-color !important;
      width: 100%;
      border-radius: 10px !important;
      order: -1;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #d9d9d900;
      max-height: 210px;
    }

    .rectangularShape {
      min-width: 88px !important;
      max-width: 95px;
      border-radius: 8px !important;
      overflow: hidden;
      max-height: 210px;
    }

    .addCircle {
      display: flex;
      justify-content: center;
      align-self: center;
      width: 45px;
      height: 45px;
      background-color: @white-color;
      border-radius: 50%;
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    width: 100%;

    h2 {
      color: white;
      margin: 5px 0 0 0;
      font-size: 19px;
      line-height: 25px;
    }

    .message {
      color: #9ba1b3;
      margin-top: 2.5px;
      text-align: left;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: @primary-font-regular;
      font-size: 14px;
      line-height: 22px;
      margin-top: 10px;
    }

    .modifyButton {
      background-color: @white-color;
      color: #050505 !important;
      font-family: @primary-font-semi-bold;
      font-size: 14px;
      padding: 7px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 23px;
      margin-bottom: 4px;
      border: none;
      width: fit-content;
      min-width: 150px;
    }

    .switch {
      max-width: 60px;
      min-width: 50px;
      width: 100%;
      height: 30px;

      &.ant-switch-checked {
        .ant-switch-handle {
          inset-inline-start: calc(100% - 30px) !important;
        }
      }
    }

    .switch.revenueSwitch .ant-switch-handle {
      width: 25px;
      height: 25px;
    }

    .buttonGroup {
      display: flex;
      gap: 1rem;
      justify-content: space-between;
    }
  }
}

.w-100 {
  width: 100%;
}

.fit-cover {
  object-fit: cover;
}
