@import url("../../../style/index.less");

.CreatorCodePromoContentWrapper {
  min-height: 100dvh !important;
  padding: @creator-codePromo-header-height 20px 160px 20px;
  .CreatorCodePromoName {
    font-size: 16px;
    line-height: 18.75px;
    color: @white-color;
    font-family: @primary-font-medium;
  }
  .creatorCodePromoDiscount {
    color: @grey-color;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
  }

  .creatorCodePromoCopyButton {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    font-family: @primary-font-bold;
    border-radius: 13px;
    font-size: 13px;
    height: 0 !important;
    padding: 20px 25px;
    border: none;
    background-color: @radio-button-color;
    color: #ffffff;
    margin-right: 3px;

    &:focus {
      outline: none !important;
    }
  }
  .right-align {
    text-align: end;
  }
  .createPromoButton {
    position: fixed;
    bottom: 30px;
    left: 0px;
    z-index: 10;
    padding: 0px 39px;
    width: 100%;
  }
  .creatorPromoCodeDeleteWrapper {
    position: fixed;
    width: 100%;
    padding: 0 20px;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -40%);
    .ant-space {
      gap: 0px;
    }
    .creatorPromoCodeDelete {
      background: linear-gradient(to top, #050505, #232323) !important;
      padding-top: 24px;
      padding-bottom: 7px;
      border-radius: 52px;
      text-align: center;
    }
    .creatorCodePromoDiscount {
      color: @white-color;
      font-size: 20px;
      line-height: 16px;
      font-family: @primary-font-bold;
    }
    .creatorCodePromoSend {
      color: @grey-color;
      font-size: 20px;
      line-height: 16px;
      font-family: @primary-font-regular;
    }
    .mb-17 {
      margin-bottom: 17px;
    }
    .creatorCodePromoDeleteButton {
      color: @black-color;
      font-family: @primary-font-bold;
      font-size: 18px;
      position: relative;
      background-color: #ffffff;
      padding: 10px 50px;
      border-radius: 20px;
    }
    .creatorCodePromoHortLine {
      padding: 15px 0px;
    }
    .creatorCodePromoHortLine::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 1px;
      background-color: @dark-grey-color;
    }
    .creatorCodePromoCancelButton {
      color: @white-color;
      font-size: 18px;
      font-family: @primary-font-regular;
    }
  }
  .creatorPromoExpireTime {
    color: @white-color;
  }
}

.creatorCodePromoName {
  font-size: 18px !important;
  line-height: 18.75px;
  color: @white-color;
  font-family: @primary-font-bold;
  text-transform: uppercase;
}
