@import url("../../style/index.less");

.childrenContainer {
  .ant-upload-select {
    border: none !important;
  }
  .ant-upload-disabled:not([role*="button"]) {
    border: none !important;
  }
}

.addMediaWrapper {
  .addCircle {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 31px;
    height: 31px;
    border-radius: 50%;
  }

  .ant-upload-select {
    width: 60px !important;
    height: 60px !important;
    border: none !important;
    border-radius: 50% !important;
    background-color: rgba(46, 46, 46, 0.65) !important;
    margin-inline-end: 3px !important;
    order: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  .ant-upload-disabled:not([role*="button"]) {
    width: 60px !important;
    height: 60px !important;
    border: none !important;
    border-radius: 50% !important;
    background-color: rgba(46, 46, 46, 0.65) !important;
  }

  .disabled {
    background-color: transparent !important;
  }

  .ant-upload-list {
    display: flex;
    flex-direction: row;
    overflow: scroll;
    align-items: center;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .ant-upload-list-item-container {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-inline-end: 3px !important;
  }

  .ant-upload-list-item {
    width: 70px !important;
    height: 70px !important;
    border: 1px solid @grey-color !important;
    border-radius: 10px;
    overflow: hidden;
  }
}

.addMediaSquareDash {
  .addCircle {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 31px;
    height: 31px;
    border-radius: 50%;
  }

  .ant-upload-select {
    width: 60px !important;
    height: 60px !important;
    border: none !important;
    border: 3px dashed rgba(77, 77, 77, 0.45) !important;
    border-radius: 18px !important;
    background-color: transparent !important;
    margin-inline-end: 3px !important;
    order: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  .ant-upload-disabled:not([role*="button"]) {
    width: 60px !important;
    height: 60px !important;
    border: none !important;
    border-radius: 50% !important;
    background-color: rgba(46, 46, 46, 0.65) !important;
  }

  .disabled {
    background-color: transparent !important;
  }

  .ant-upload-list {
    display: flex;
    flex-direction: row;
    overflow: scroll;
    align-items: center;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .ant-upload-list-item-container {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-inline-end: 3px !important;
  }

  .ant-upload-list-item {
    width: 70px !important;
    height: 70px !important;
    border: 1px solid @grey-color !important;
    border-radius: 10px;
    overflow: hidden;
  }
}

.addMediaWrapperCercleGreen {
  .addCircleGreen {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 85px;
    height: 85px;
    background-color: @medium-green-color;
    border-radius: 50%;
  }

  .ant-upload-select {
    border: none !important;
  }

  .disabled {
    background-color: @grey-color !important;
  }
}

.addMediaWrapperPublication {
  width: 100%;
  align-items: center;

  .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
    width: unset;
    height: unset;
    border: unset;
  }

  .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container {
    width: unset;
    height: unset;
    display: unset;
  }

  .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item {
    height: 250px;
    border: unset;
    border-radius: 12px;

    .ant-upload-list-item-thumbnail {
      border-radius: 12px !important;
    }
  }

  .addCircleGreen {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 230px;
    height: 230px;
    background-color: @medium-green-color;
    border-radius: 50%;
  }

  .disabled {
    background-color: @grey-color !important;
  }
}

.addUnlockLinkMediaWrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;

  .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
    width: unset;
    height: unset;
    border: unset;
  }

  .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container {
    width: unset;
    height: unset;
    display: unset;
  }

  .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item {
    height: 250px;
    border: unset;
    border-radius: 12px;

    .ant-upload-list-item-thumbnail {
      border-radius: 12px !important;
    }
  }
  .addCircleGreen {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 230px;
    height: 230px;
    background-color: @medium-green-color;
    border-radius: 50%;
  }
}

/* .ant-upload-list-item-image{
  object-fit: cover !important;
  border-radius: 24px !important;
} */

.ant-upload-list-item-undefined {
  aspect-ratio: 12/16 !important;
  overflow: hidden !important;
}

.custom-img-crop .ant-modal-footer {
  text-align: center !important;

  button {
    width: 100px;
  }
}

.lockIcon {
  position: relative;
  top: -100px;
  right: -80px;
  color: white;
}

.videoIcon {
  position: relative;
  top: -99px;
  right: -50px;
  z-index: 10;
  width: 13px;
  height: 13px;
}

.postVideoIcon {
  top: -240px !important;
  right: inherit !important;
  left: 10px !important;
}

.unlockVideoIcon {
  position: relative;
  top: -230px;
  right: -20px;
  z-index: 10;
  width: 25px;
  height: 25px;
}

.mediaPushMediaWrapper {
  display: flex;
  justify-content: center;

  .addCircleGreen {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 170px;
    height: 170px;
    background-color: @medium-green-color;
    border-radius: 50%;
  }

  .addCircle {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 31px;
    height: 31px;
    // background-color: @white-color;
    border-radius: 50%;
  }

  .ant-upload-select {
    border: 4px dashed @white-color!important;
    display: none;
  }

  .ant-upload-disabled:not([role*="button"]) {
    border: 4px dashed @grey-color!important;
  }

  .disabled {
    background-color: @grey-color !important;
  }
}

.addMediaWrapperRectangularShape {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 10px;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 10px;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .addCircle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    padding: 15px;
    background-color: @white-color;
    border-radius: 50%;
  }

  .rectangularShape {
    width: 100px !important;
    height: 160px !important;
    border: 2px dashed @white-color !important;
    border-radius: 10px !important;
    order: -1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-upload-select {
    display: none;
  }

  .ant-upload-disabled:not([role*="button"]) {
    display: none;
  }

  .disabled {
    background-color: transparent !important;
  }

  .ant-upload-list {
    display: flex;
    flex-direction: row;
    gap: 1px;
  }

  .ant-upload-list-item {
    min-height: 160px !important;
    width: 100px !important;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border: 1px solid @grey-color !important;
    border-radius: 10px;
    overflow: hidden;
  }

  .ant-upload-list-item-thumbnail {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .ant-upload-list-item-thumbnail img {
    height: 100% !important;
    width: 100% !important;
    object-fit: cover !important;
  }

  .ant-upload-wrapper{
    min-height: 160px;
  }
}

.collection-thumbnail {
  position: relative;
  top: -150px;
  right: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(85.96deg, #ff3ba5 0%, #ff2323 108.65%);
  border-radius: 12px;
  width: 55px;
  height: 20px;

  .collection-thumbnail-text {
    font-family: @primary-font-bold;
    font-size: 10px;
    color: #ffffff;
  }
}

.coversationMediaWrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 10px;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 10px;

  -ms-overflow-style: none;
  scrollbar-width: none;

    .addCircleGreen {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      background-color: rgba(46,46,46,.65)!important;
      border-radius: 50%;

      img{
        width: 30px;
        height: 30px;
      }

      .disabled {
        background-color: rgba(46,46,46,.65)!important;
      }
    }

  &::-webkit-scrollbar {
    display: none;
  }

  .ant-space-item{
    display: flex;
    // width: 65px;
    // height: 65px;
    flex-wrap: wrap;
  }

    .addCircle {
      // display: none;
      justify-content: center;
      align-self: center;
      width: 31px;
      height: 31px;
      border-radius: 50%;

      img{
        width: 30px;
        height: 30px;
      }

      .disabled {
        background-color: rgba(46,46,46,.65)!important;
      }
    }
  
    .rectangularShape {
      width: 63px !important;
      height: 63px !important;
      border: 3px dashed rgba(77, 77, 77, 0.45) !important;
      border-radius: 12px !important;
      order: -1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .ant-upload-select {
      display: none;
    }
  
    .ant-upload-disabled:not([role*="button"]) {
      width: 60px !important;
      height: 60px !important;
      border: none !important;
      border-radius: 50% !important;
      background-color: rgba(46, 46, 46, 0.65) !important;
    }
  
    .ant-upload-list {
      display: flex;
      flex-direction: row-reverse;
      overflow: scroll;
      align-items: start;
      gap: 10px;
      margin-left: -10px;
  
      -ms-overflow-style: none;
      scrollbar-width: none;
  
      &::-webkit-scrollbar {
        display: none;
      }
    }
  
    .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container{
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      margin-inline-end: 3px !important;
      width: 65px;
      height: 65px;
    }
  
    .ant-upload-list-item {
      width: 65px !important;
      height: 65px !important;
      border: 1px solid @grey-color !important;
      border-radius: 10px;
      overflow: hidden;
  }
}
