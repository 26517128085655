@import url("../../style/index.less");

.fanEventsTabContent {
  padding: 0 0 100px 10px;

  .evenItemImg {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 6px;
  }

  .noEventsMessage {
    color: #a2a2a2;
    padding: 20px;
    font-size: 15px;
  }
}

.row-gap-10 {
  row-gap: 10px;
}

.todayLiveSectionContainer {
  display: flex;
  overflow-x: auto;
  padding: 0;
  gap: 10px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  white-space: nowrap;
  max-width: 100vw;

  .todayLiveSectionItem {
    position: relative;
    cursor: pointer;
    width: 300px !important;
    height: 300px;
    border-radius: 10px;
    overflow: hidden;
    flex-shrink: 0;
  }

  .todayLiveSectionImageWrapper {
    position: relative;
    width: 100%;
    height: 100%;

    .todayLiveImage {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .todayLiveHoursOverlay {
      position: absolute;
      top: 0;
      left: 0;
      width: auto;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 8px;
      margin-top: 10px;
      border-radius: 15px;
      background-color: rgba(3, 3, 3, 0.4);

      .todayLiveHours {
        font-family: @primary-font-bold;
        font-size: 11px;
        color: #ffffff;
        margin-left: 3px;
      }
    }

    .todayLiveInfoOverlay {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 5px 10px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 70%);
      color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 12px;

      .nameRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        flex-wrap: nowrap;
      }

      .todayLiveTitle {
        font-family: @primary-font-black;
        font-size: 37px;
        color: #ffffff;
        margin-bottom: 2px;
      }

      .todayLiveTitle::first-letter {
        text-transform: uppercase;
      }

      .todayLiveDescription {
        font-family: @primary-font-light;
        font-size: 14px;
        color: #dadada;
      }
    }
  }
}

.todayLiveSectionContainer::-webkit-scrollbar {
  display: none;
}

.scheduleLiveSectionContainer {
  width: 100%;
  margin-top: 20px;
  padding: 0 20px 0 0;
}

.upcomingLiveSectionImageWrapper {
  position: relative;
  width: 100%;
  height: 240px;

  .upcomingLiveImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }

  .upcomingLiveInfoOverlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5px 10px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 70%);
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 0 0 6px 6px;
    padding-bottom: 10px;
    padding-top: 15px;

    .upcomingLiveTitle {
      font-family: @primary-font-black;
      font-size: 16px;
      color: #ffffff;
    }

    .upcomingLiveTitle::first-letter {
      text-transform: uppercase;
    }

    .upcomingLiveDescription {
      font-family: @primary-font-regular;
      font-size: 12px;
      color: #dadada;
      margin-bottom: 2px;
    }
  }
}

.font-13-light {
  font-family: @primary-font-light;
  font-size: 13px;
  color: #ffffff;
}
