@import url("../../../style/index.less");

.CreatorQuickLinkContentWrapper {
  min-height: 100dvh !important;
  padding: calc(@creator-codePromotion-header-height) 15px 25px 15px;
  .addCircleGreen {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 161px;
    height: 161px;
    background-color: @medium-green-color;
    border-radius: 50%;
  }
  .CreateNewAlbum {
    font-size: 14px;
    color: @grey-color;
    font-family: @primary-font-regular;
  }
  .MyAlbums {
    font-size: 28px;
    color: @white-color;
    font-family: @primary-font-bold;
  }
  .doubleCard {
    margin-left: 10px;
    flex-shrink: 0;
  }
  .QuickLinkNumberOfFeeds {
    font-size: 14px;
    color: #b3b3b3;
    font-family: @primary-font-regular;
  }
  .underline {
    color: @grey-color;
    margin-top: 0;
  }
  .justify-between {
    justify-content: space-between;
  }
  .rightArrow {
    margin-left: 25px;
  }

  .albumRowContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .albumRow {
      display: flex;
      width: 100%;
      height: 58px;
      justify-content: space-between;
      align-items: center;
      padding: 6px 6px;
      border-radius: 35px;
      background: rgba(77, 77, 77, 0.3);
      overflow: hidden;

      .elementsRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;

        .QuickLinkAlbumName {
          display: block;
          margin-left: 10px;
          font-size: 18px;
          color: @white-color;
          font-family: @primary-font-medium;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: calc(100% - 130px);
        }

        .rightElements {
          display: flex;
          align-items: center;
          margin-left: auto;
          flex-shrink: 0;
        }

        .chevron-right {
          width: 40px;
          height: 40px;
          margin-left: 15px;
          padding: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .trashIcon {
      margin-left: 10px;
    }
  }
}
