@import "../../../style/index.less";

.searchWrapper {
  background-color: #25282d !important;
  padding: 7px 20px 7px 18px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-input {
    color: @white-color;
    background-color: #25282d;
    box-shadow: none !important;
    outline: none !important;
  }

  .ant-input:focus,
  .ant-input-focused {
    box-shadow: none !important;
    outline: none !important;
  }

  .ant-input-prefix {
    img {
      width: 19px;
    }
  }
}

.searchWrapper:-ms-input-placeholder {
  color: @grey-color !important;
}

.searchWrapper:hover {
  background-color: @white-color;
}

.searchWrapper input {
  background-color: #25282d;
  font-size: 17px;
  height: 30px;
}

.searchWrapper input::placeholder {
  color: @grey-color;
  font-size: 17px;
}

.searchWrapper span {
  margin-inline-end: 11px !important;
}
