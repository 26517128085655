@import "../../style/index.less";

.sticky-bottom-button-container {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 980px;
  display: flex;
  justify-content: center;
  padding: 16px;
  background: linear-gradient(0deg, @black-color 0%, @black-color 25%, #0707061a 89%, #00000000 100%) !important;
  z-index: 1000;

  .sticky-bottom-button {
    width: 100%;
    height: 50px;
    border-radius: 24px;
    background-color: #fff;
    color: #000;
    font-family: @primary-font-semi-bold;
    font-size: 16px;
    border: none;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
    margin-top: 40px;
    margin-bottom: 10px;

    &.background-green {
      background-color: @medium-green-color;
    }

    &:disabled {
      background-color: @grey-color;
      color: @black-color;
      border-color: unset;
    }

    .spinner-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
