@import url("../../style/index.less");

.authGoogleBtn {
  width: 100%;
  height: fit-content;
  font-family: @primary-font-bold;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  border: 1px solid @grey-color;
  border-radius: 28px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 10px;
  justify-content: flex-start;

  & > span {
    flex: 1;
    text-align: center;
    padding-right: 32px;
  }

  &:hover {
    color: @grey-color!important;
    border: 1px solid @grey-color!important;
  }

  &:active {
    border: 1px solid @grey-color!important;
  }
}

.publicAuthGoogleBtn {
  width: 100%;
  height: 70px;
  font-family: @primary-font-bold;
  font-size: 16px;
  color: #ffffff;
  border: none;
  border-radius: 40px;
  background: rgba(77, 77, 77, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 10px;
  justify-content: flex-start;

  & > span {
    flex: 1;
    text-align: center;
    padding-right: 32px;
  }

  &.small {
    height: 60px !important;
  }
}
