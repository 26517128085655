@import url("../../../style/index.less");

.creatorHistoryMediaPushContentWrapper {
  min-height: 100dvh !important;
  padding: calc(@creator-historyMediaPush-header-height + 20px) 20px 120px 20px;
}

.subscriber-block {
  background-color: #ffffff;
  color: #000000;
  padding: 6px 20px;
  border-radius: 20px;
}

.border-bottom-history-push {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 0.3px solid #4c4c4c;
}
