@import "../../style/index.less";

.header-title-page-row {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .header-title-back-icon {
    position: absolute;
    left: 0;
    width: 13px;
    cursor: pointer;
    padding: 10px;
    box-sizing: content-box;
  }

  .header-title-text {
    font-family: @primary-font-black;
    color: @white-color;
    margin-left: auto;
    margin-right: auto;
  }
}
