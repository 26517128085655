@import url("../../../style/index.less");

.creatorCodePromotionContentWrapper {
  min-height: 100dvh !important;
  padding: calc(@creator-codePromotion-header-height) 20px 120px 20px;

  .codePromoRadioButton {
    width: 100%;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: @light-black-color;
    border: unset;
    border-radius: 6px;

    &.ant-radio-button-wrapper-checked {
      background-color: @medium-green-color!important;

      span {
        color: @black-color;
      }
    }

    span {
      font-family: @primary-font-bold;
      font-style: normal;
      font-size: 13px;
      line-height: 15px;
      color: @white-color;
    }
  }
}
