@import url("../../../style/index.less");

.creatorPaymentMethodContentWrapper {
  min-height: 100dvh !important;
  padding: @creator-paymentMethod-header-height 25px 120px 25px;
}

.labelByDeault {
  display: flex;
  justify-content: center;
  align-items: center;
  height: unset;
  width: 75px;
  color: @black-color;
  background: @white-color;
  border: unset;
}

.mt-5 {
  margin-top: 5%;
}

.btn-font-resp {
  @media only screen and (max-width: 525px) {
    font-size: 5vw !important;
  }
}

.three-dot {
  margin-left: auto;
  height: 20px;
  width: fit-content;
  display: flex;
  align-items: center;
}

.payment-switch {
  background-color: @dark-green-color !important;
  border: 1px solid @white-color;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.select-method-block {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 120px;
  margin-top: 10px;
  background-color: #1c1c1c;
  border-radius: 20px;
  overflow: hidden;
}

.style-bank {
  margin-top: 50px;
  margin-bottom: -16px;
}

.select-method-col {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  background-color: #1c1c1c;
  text-align: center;
}

.select-method-col.left {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.select-method-col.right {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.select-method-col.active {
  background-color: #305af1;
}

.select-method-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 8px;
}

.select-method-top {
  margin-bottom: auto;
  text-transform: uppercase;
}

.select-method-center {
  margin-bottom: auto;
}

.select-method-divider {
  width: 1px;
  background-color: gray;
  height: 100%;
}

.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.responsive-image {
  max-width: 100%;
  height: auto;
}

.card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.card-top-left {
  position: absolute;
  top: 32px;
  left: 35px;
}

.card-top-right {
  position: absolute;
  top: 45px;
  right: 30px;
}

.card-bottom-left {
  position: absolute;
  bottom: 20px;
  left: 35px;
  display: flex;
  flex-direction: column;
}

.title-card {
  font-family: @primary-font-bold;
  color: white;
  font-size: 36px;
  margin-bottom: 10px;
}

.default-option {
  font-family: @primary-font-bold;
  color: #ffffff;
  border: 2px solid white;
  border-radius: 24px;
  padding: 6px 18px;
  font-size: 14px;
}

.username-card {
  font-family: @primary-font-bold;
  font-size: 40px;
  margin-bottom: 10px;
}

.info-card {
  font-family: @primary-font-bold;
  color: white;
  font-size: 26px;
}

.text-gradient-color {
  background: linear-gradient(to bottom, #e7e7e7 0%, #f3f3f3 50%, #c3c3c3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.addAccountBtn {
  width: 100%;
  height: 60px;
  font-family: @primary-font-bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px !important;
  background-color: #ffffff;
  border-radius: 30px !important;
  font-size: 18px !important;
}

.sticky-button-method-payment {
  position: fixed;
  bottom: 30px;
  left: 0;
  z-index: 10;
  padding: 0 39px;
  width: 100%;
  //background: linear-gradient(0deg, #101010 0%, #101010 25%, #0707061a 89%, #00000000 100%) !important;
}

.change-payment-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

@media screen and (max-width: 548px) {
  .title-card {
    font-size: 32px !important;
  }
}

@media screen and (max-width: 520px) {
  .title-card {
    font-size: 24px !important;
  }
  .username-card {
    font-size: 24px !important;
  }
  .info-card {
    font-size: 24px !important;
  }
  .default-option {
    font-size: 10px !important;
  }
  .card-top-right {
    top: 35px !important;
  }
}

@media screen and (max-width: 428px) {
  .responsive-title {
    font-size: 24px !important;
    margin-left: 20px;
  }
  .title-card {
    font-size: 18px !important;
  }
  .username-card {
    font-size: 24px !important;
  }
  .info-card {
    font-size: 18px !important;
  }
  .default-option {
    font-size: 10px !important;
  }
  .card-top-right {
    top: 35px !important;
  }
}

@media screen and (max-width: 360px) {
  .responsive-title {
    font-size: 18px !important;
  }
}
