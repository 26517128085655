@import url("../../../../style/index.less");

.renew-presentation-container {
  position: relative;
  display: flex;
  padding: 10px;
  margin: 15px;
  border-radius: 12px;
  gap: 15px;
  background: linear-gradient(90deg, #0e0e0e 34%, #161618 69%);

  .renew-presentation-description-container {
    display: flex;
    flex-direction: column;

    .renew-presentation-title {
      font-family: @primary-font-black;
      font-size: 18px;
      color: #ffffff;
      text-transform: uppercase;
    }
    .renew-presentation-description {
      font-family: @primary-font-regular;
      font-size: 10px;
      color: #dadada;
      margin-top: 5px;
      width: 75%;
    }

    .renew-presentation-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      width: 40%;
      background-color: #ffffff;
      color: #000000;
      font-family: @primary-font-semi-bold;
      font-size: 12px;
      padding: 0 25px;
      border: none;
      border-radius: 30px;
      margin-top: 15px;
    }
  }

  .microphone-img {
    position: absolute;
    bottom: 5px;
    right: 10px;
    object-fit: cover;
    width: 25%;
  }
}
