@import url("../../style/index");

.liveJoinWaitWrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  .liveJoinWaitContent {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .liveJoinWaitBlurBg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #454240ad;
      filter: blur(18px);
    }

    .liveJoinWaitLabelWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      margin: auto;
    }
  }
}