@import "../../../style/index.less";

.cancel-edit-mode-sticky-bottom-container {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 980px;
  display: flex;
  justify-content: center;
  padding: 16px;
  background: linear-gradient(0deg, @black-color 0%, @black-color 25%, #0707061a 89%, #00000000 100%) !important;

  .cancel-edit-mode-sticky-button {
    width: 75%;
    height: 50px;
    border-radius: 24px;
    background: #34393f80;
    color: #9f9fa1;
    font-family: @primary-font-semi-bold;
    font-size: 16px;
    border: none;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
    margin-top: 40px;
    margin-bottom: 10px;
  }
}
