@import url("../../../style/index");

.fanDirectLiveWhoLikesItWrapper {
  padding: 0 0 0 16px;

  .liveWhoLikesItTitle {
    font-family: @primary-font-bold;
    font-size: 24px;
    color: #ffffff;
  }

  .liveWhoLikesItContainer {
    display: flex;
    overflow-x: auto;
    padding: 5px 0;
    gap: 10px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    white-space: nowrap;
    max-width: 100vw;

    .liveWhoLikesItItem {
      position: relative;
      cursor: pointer;
      width: 150px !important;
      height: 265px;
      border-radius: 6px;
      overflow: hidden;
      flex-shrink: 0;
    }

    .liveWhoLikesItImageWrapper {
      position: relative;
      width: 100%;
      height: 100%;

      .liveWhoLikesItImage {
        width: 100%;
        height: 100%;
        object-fit: cover;

        &.blurImage {
          filter: blur(10px);
        }
      }

      .liveWhoLikesItTopInfo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 5px 5px;
        display: flex;
        margin-top: 3px;

        .liveWhoLikesItTopInfoUsernameContainer {
          padding: 2px 5px;
          border-radius: 10px;
          background: linear-gradient(45deg, #ff3a9c 0%, #ff2736 100%);

          .liveWhoLikesItTopInfoUsername {
            font-family: @primary-font-bold;
            font-size: 10px;
            color: #ffffff;
          }
        }
      }

      .liveWhoLikesItPrivateOverlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: #ffffff;
        text-align: center;
        padding: 10px;

        .privateLinkText {
          font-family: @primary-font-bold;
          color: #ffffff;
          font-size: 22px;
          margin-bottom: 8px;
        }

        .privateLinkButtonButton {
          display: flex;
          align-items: center;
          background-color: #ffffff;
          padding: 5px 15px;
          font-family: @primary-font-bold;
          font-size: 14px;
          border-radius: 20px;
          border: none;
        }
      }

      .liveWhoLikesItInfoOverlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 5px 10px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 70%);
        color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .liveWhoLikesItInfoContainer {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          overflow: hidden;
        }

        .liveWhoLikesItName {
          font-family: @primary-font-bold;
          font-size: 14px;
          color: #ffffff;
          margin-left: 5px;
        }

        .liveWhoLikesItName::first-letter {
          text-transform: uppercase;
        }

        .liveWhoLikesItDescription {
          font-family: @primary-font-regular;
          font-size: 12px;
          color: #dadada;
          margin-bottom: 2px;
        }
      }
    }
  }

  .liveWhoLikesItContainer::-webkit-scrollbar {
    display: none;
  }
}
