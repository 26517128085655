@import url("../../style/index.less");

.fanPaymentRadioButton {
  display: flex;
  align-items: center;
  width: 100%;
  background: transparent;
  height: 48px;
  border-radius: 5px !important;
  border: 1px solid @grey-color;
  padding-left: 10px;
  padding-right: 16px;

  span {
    &:nth-child(n + 2) {
      width: 100%;
    }
  }

  &.ant-radio-button-wrapper-checked {
    background: transparent;
    border-color: #ffffff !important;
  }

  .ant-typography {
    font-family: @primary-font-regular;
    font-style: normal;
    font-size: 15px;
    line-height: 18px;
    color: @white-color;
    margin-left: 8px;
  }

  .ant-radio-button {
    display: flex;
    align-items: center;
    position: relative;
    width: fit-content;
    height: fit-content;
    z-index: 1;

    input {
      width: 21px;
      height: 21px;
      margin: unset;
      opacity: 1;
    }
  }
}
