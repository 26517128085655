@import "./style/index.less";

.app {
  min-width: 350px;
  min-height: 100dvh;
  max-width: 100vw;
  max-height: 100dvh;
  font-family: @primary-font-regular;
}

.cursor-pointer {
  cursor: pointer;
}
