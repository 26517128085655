@import url("../../style/index.less");

.google-unavailable-content-wrapper {
  min-height: 100dvh !important;
  padding: 0 10px 20px 10px;

  .show-icon-container {
    display: flex;
    justify-content: end;
    width: 100%;
    margin-top: 10px;

    .show-icon {
      width: 38px;
      margin-right: 30px;
    }
  }

  .auth-google-insta-title-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;

    .auth-google-insta-title {
      font-family: @primary-font-bold;
      font-size: 27px;
      line-height: 38px;
      color: #ffffff;
      text-align: center;
    }
  }
}

.auth-google-insta-img-description-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;

  .google-auth-insta-img {
    width: 100%;
    object-fit: cover;
  }

  &.public-design {
    margin-top: 5px !important;
  }
}

.auth-google-insta-content-description-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  margin-top: 20px;
  padding: 0 15px;

  .description-text {
    font-family: @primary-font-Thin;
    font-size: 15px;
    color: #ffffff;
  }

  &.public-design {
    margin-top: 5px !important;
  }
}

.auth-google-insta-cancel-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 50px;

  .cancel-text {
    font-family: @primary-font-regular;
    font-size: 15px;
    color: #ffffff;
    text-align: center;
  }

  &.public-design {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
}
