@import url("../../style/index.less");

.conversation-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;

  .conversation-details {
    display: flex;
    align-items: center;
    overflow: hidden;
    max-width: 92%;

    &.to-deliver {
      max-width: 60%;
    }

    .avatar-container {
      margin-right: 12px;
    }

    .conversation-info-container {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .conversation-info-user {
        display: flex;
        flex-direction: row;
        align-items: center;

        .messageNameLabel {
          font-family: @primary-font-regular;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          color: @white-color;
          margin-right: 5px;
        }
      }

      .conversation-info-status {
        margin-top: 4px;
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;

        .messageStatusLabel {
          width: 100%;
          font-family: @primary-font-regular;
          font-style: normal;
          font-size: 16px;
          line-height: 19px;
          color: @grey-dark-color-2;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;

          &.text-white-color {
            color: #ffffff;
          }
        }
      }
    }
  }

  .conversation-clock-badge {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-left: 8px;

    .clock-background {
      width: auto;
      background: #ffffff1a;
      border-radius: 12px;
      padding: 10px 10px;
    }

    .messageNewBadge {
      .ant-badge-status-dot {
        width: 13px !important;
        height: 13px !important;
        background-color: #ffffff !important;
      }
    }
  }
}

.avatarOnlineDot {
  .ant-badge-dot {
    width: 10px !important;
    height: 10px !important;
    box-shadow: 0 0 0 3px #ffffff;
  }
}

.not-conversation-text {
  width: 100%;
  font-family: @primary-font-regular;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.unread-container {
  position: absolute;
  right: 0;
  top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  border: 1px solid #b5b9bd;
  border-radius: 18px;
  width: auto;

  &.unread-active {
    background-color: #ffffff;
    border: none;

    .unread-text {
      color: #000000;
    }
  }

  .unread-text {
    font-family: @primary-font-medium;
    font-size: 12px;
    color: #b5b9bd;
    margin-right: 3px;
  }

  .unread-icon {
    object-fit: cover;
    width: 15px;
  }
}
