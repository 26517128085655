@import url("../../../style/index.less");

.creatorRevenueContentWrapper {
  padding: @creator-revenue-header-height 0 calc(@footer-height + 10px) 0;
  min-height: 100dvh !important;

  .payment-information {
    margin: 0 20px;
    padding: 15px 20px;
    background: rgba(46, 46, 54, 0.3);
    border-radius: 20px;

    .payment-information-first-container {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;

      .next-payment-container {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;

        .text-description-first {
          font-family: @primary-font-light;
          font-size: 12px;
          color: #ffffff;
        }

        .text-description-second {
          font-family: @primary-font-light;
          font-size: 12px;
          color: #8f8f91;
          margin-right: 5px;
        }
      }

      .currency-img {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
        border-radius: 5px;
        width: 60px;
        height: auto;

        .eur-text {
          font-family: @primary-font-light;
          font-size: 12px;
          color: #8f8f91;
          margin-top: 5px;
        }
      }
    }

    .payment-information-seconde-container {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-top: 10px;

      .pending-payment-container {
        display: flex;
        flex-direction: column;
        gap: 5px;

        flex: 1;

        .text-description-first {
          font-family: @primary-font-light;
          font-size: 12px;
          color: #ffffff;
        }

        .text-description-second {
          font-family: @primary-font-light;
          font-size: 12px;
          color: #8f8f91;
          margin-right: 5px;
        }
      }
    }
  }

  .revenue-radio-container {
    margin: 20px 20px 10px 20px;
    gap: 0;
  }

  .revenuePeriodRadio {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    padding: 12px 16px 12px 16px;
    border: none;
    border-radius: 20px !important;
    border-inline-start: unset !important;
    min-width: 75px;
    max-width: auto;
    height: 40px;
    white-space: nowrap;

    &.ant-radio-button-wrapper-checked {
      background-color: #2e2e3699 !important;

      span {
        color: @white-color;
      }
    }

    span {
      font-family: @primary-font-bold;
      font-style: normal;
      font-size: 15px;
      line-height: 18px;
      color: #8f8f91;
    }
  }

  .chart-container {
    display: flex;
    justify-content: center;
    margin: 0 20px 20px 20px;
    padding: 20px 5px;
    background: rgba(46, 46, 54, 0.3);
    border-radius: 20px;

    .chart-data-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 85%;
      margin-bottom: 10px;

      .chart-price-text {
        font-family: @primary-font-bold;
        font-size: 20px;
        color: #ffffff;
      }

      .chart-description-text {
        font-family: @primary-font-light;
        font-size: 12px;
        color: #8f8f91;
      }
    }
  }

  .last-report-container {
    display: flex;
    justify-content: center;
    margin: 10px 20px 20px 20px;
    padding: 20px 5px;
    background: rgba(46, 46, 54, 0.3);
    border-radius: 20px;

    .last-report-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 90%;

      .information-management-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: #7ffaa10d;
        padding: 5px 12px;
        border-radius: 15px;

        .information-management-button-text {
          font-family: @primary-font-bold;
          font-size: 14px;
          color: #7ffaa1;
          margin-right: 8px;
        }
      }
    }

    .last-report-content {
      width: 100%;
      margin: 10px 20px 10px 20px;

      .detailed-report {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
      }
    }
  }
}

.ant-dropdown-menu {
  background-color: #1a1a1a !important;
  border: none !important;
  box-shadow: none !important;

  .ant-upload-wrapper {
    color: #ffffff !important;
  }
}

.ant-dropdown-menu-item {
  color: #ffffff !important;
  margin-bottom: 3px !important;
  margin-left: 8px !important;
}

.ant-dropdown-menu-item:hover {
  background-color: #333333 !important;
  color: #ffffff !important;
}

.ant-dropdown-menu-item-active {
  background-color: #444444 !important;
  color: #ffffff !important;
}

@media (max-width: 480px) {
  .revenuePeriodRadio {
    padding: 10px 14px 10px 14px !important;
    span {
      font-size: 14px !important;
    }
  }
}

@media (max-width: 400px) {
  .revenuePeriodRadio {
    padding: 10px 14px 10px 14px !important;
    span {
      font-size: 13px !important;
    }
  }
}

@media (max-width: 380px) {
  .revenuePeriodRadio {
    padding: 10px 14px 10px 14px !important;
    span {
      font-size: 12px !important;
    }
  }
}
