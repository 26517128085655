@import url("../../style/index");

.liveComment {
  margin-left: 10px;
}

.live-comments-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .top-icon {
    margin-left: 5px;
    width: 12px;
  }
}

.live-username-text {
  font-family: @primary-font-semi-bold;
  font-size: 12px;
  color: #b2b2b2;
}

.live-comment-text {
  font-family: @primary-font-medium;
  font-size: 13px;
  color: #ffffff;
}
