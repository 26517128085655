@import url("../../../style/index.less");

.select-billing {
  width: 100%;

  &.dark {
    .ant-select-selector {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 45px;
      background: @background-input;
      border-radius: 6px;
      border: 1px solid @border-input;

      .ant-select-selection-search {
        color: @white-color;
      }

      .ant-select-selection-placeholder {
        font-family: @primary-font-regular;
        font-size: 15px;
        color: @placeholder !important;
      }

      .ant-select-selection-item {
        display: flex;
        align-items: center;
        font-family: @primary-font-regular;
        font-style: normal;
        font-size: 15px;
        line-height: 18px;
        color: @white-color;
      }
    }

    .ant-select-arrow {
      color: @placeholder !important;
    }
  }
}
