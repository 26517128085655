@import url("../../style/index.less");

.modalFanPayment {
  max-width: 350px;
  border-radius: 20px;
  background: linear-gradient(to top, #050505, #232323) !important;

  .ant-modal-content {
    background-color: transparent;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .ant-modal-header {
      background-color: transparent;
      padding: 0 20px;
      margin-bottom: 0;
    }

    .ant-modal-body {
      padding: 6px 17px 16px 17px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .requireSubscribeModalDescription {
        font-family: @primary-font-regular;
        font-style: normal;
        font-size: 14px;
        line-height: 16px;
        color: #dadada;
        text-align: center;
      }
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 0;

    .fanModalFooter {
      width: 100%;
      display: flex;
      justify-content: center;
      padding-bottom: 30px;
    }
  }
}

.ant-modal-root {
  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.8) !important;
  }
}

.fanpagepopup {
  min-width: 100vw;
  min-height: 100dvh;
  background: linear-gradient(to top, #141414, #484848) !important;
  position: fixed;
  top: 0;
  z-index: 1;
}
.paymentSuccessMessage {
  width: 100%;
  justify-content: center;
  text-align: center;
  color: @medium-green-color;
  font-size: 25px;
  font-family: @primary-font-bold;
  margin-top: 22px;
}

.paymentSuccessPopupBtn {
  margin-top: 24px;
  color: @black-color !important;
  background-color: @medium-green-color;
  font-family: @primary-font-bold;
  font-size: 17px;
  width: 227px;
  border: none;
  box-shadow: 0px 1px 4px 4px rgba(0, 0, 0, 0.01);
  border-radius: 24px;
  height: 60px;
}
// payment failed pop up
.paymentFailedMessage {
  width: 100%;
  justify-content: center;
  text-align: center;
  color: @red-color;
  font-size: 22px;
  font-family: @primary-font-bold;
  margin-top: 22px;
}
.paymentFailedPopupBtn {
  margin-top: 24px;
  color: @white-color!important;
  background-color: @red-color;
  font-family: @primary-font-bold;
  font-size: 17px;
  width: 227px;
  border: none;
  box-shadow: 0px 1px 4px 4px rgba(0, 0, 0, 0.01);
  border-radius: 24px;
  height: 60px;
}
