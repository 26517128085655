@import url("../../../style/index.less");

.creatorHelpContactContentWrapper {
  min-height: 100dvh !important;
  padding: (@creator-help-contact-header-height + 20px) 20px 120px 20px;

  .subs-container-help-contact {
    margin-top: 20px;
    border-radius: 90px;
    padding: 6px 5px;
    background: rgba(77, 77, 77, 0.2);
    height: 140px;

    .imgSection {
      width: 115px;
      margin-left: 5px;
    }
    .firstSection {
      color: #ffffff;
      font-family: @primary-font-light;
      font-size: 11px;
    }
    .secondSection {
      color: #ffffff;
      font-family: @primary-font-black;
      font-size: 44px;
      line-height: 52px;
      margin-bottom: 5px;
    }
    .thirdSection {
      color: #ffffff;
      font-family: @primary-font-medium;
      font-size: 18px;
    }
    .thirdSection::before {
      content: "+";
      display: inline;
    }
  }
}

.helpMessageInput {
  font-family: @primary-font-regular;
  color: @white-color;
  background-color: rgba(77, 77, 77, 0.4) !important;
  border: none !important;
  border-radius: 15px !important;
  padding: 10px 15px;
  height: 50px;
  font-size: 16px;

  &::placeholder {
    color: @grey-color;
  }
}

.helpMessageTextArea {
  font-family: @primary-font-regular;
  height: 150px !important;
  color: @white-color;
  background-color: rgba(77, 77, 77, 0.4) !important;
  border: none !important;
  border-radius: 15px !important;
  padding: 10px 15px;
  font-size: 16px;

  &::placeholder {
    color: @grey-color;
  }
}

.gradient-background-help-creator {
  background: linear-gradient(180deg, #081a2d 0%, #131313 100%);
}

.avatarOnlineDotJerry {
  .ant-badge-dot {
    width: 16px !important;
    height: 16px !important;
    border: none !important;
    box-shadow: 0 0 0 3px #ffffff;
  }
}
