@import url("../../style/index.less");

.fhp-main-skelton {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 10px;
}

.fhp-skelton-bg .ant-skeleton-element,
.fhp-skelton-bg .ant-skeleton-avatar,
.fhp-skelton-bg .ant-skeleton-input,
.fhp-skelton-bg .ant-skeleton-title,
.fhp-skelton-bg .ant-skeleton-image {
  background-color: #222 !important;
}