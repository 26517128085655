@import "../../style/index.less";

.outer-container {
  display: flex;
  justify-content: center;
  height: auto;
}

.bordered-box {
  border: 1px solid #ffffff;
  border-radius: 26px;
  padding: 16px 16px;
  display: inline-flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

.font-22-black-perf {
  font-family: @primary-font-black;
  font-size: 22px;
}

.font-32-black-perf {
  font-family: @primary-font-black;
  font-size: 32px;
}

.font-36-black-perf {
  font-family: @primary-font-black;
  font-size: 36px;
}
