@import url("../../../style/index.less");

.live-event-container {
  position: relative;
  padding: 0 10px;
  margin-bottom: 15px;
  width: 100%;
  display: inline-block;

  .live-event-img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .live-event-overlay {
    position: absolute;
    width: 82%;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
  }

  .live-event-btn {
    width: 48%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: @primary-font-bold;
    font-size: 16px;
    border: none;
    border-radius: 3px;

    &.billeterie-btn {
      background-color: #ffffff;
      color: #000000;
    }
    &.more-info-btn {
      color: #ffffff;
      background: #74758b4d;
    }
  }

  .is-live-event-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0%);

    .is-live-event-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ff0044;
      border-radius: 10px;
      padding: 11px 18px 11px 12px;
    }

    .is-live-event-text {
      font-family: @primary-font-bold;
      font-size: 16px;
      color: #ffffff;
      margin-left: 3px;
      text-align: center;
      width: max-content;
    }
  }
}
