@import url("../../../../style/index.less");

.formation-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
  overflow: hidden;

  .formation-modal-content {
    width: 100%;
    max-width: 980px;
    height: 90%;
    max-height: 95%;
    background-color: #111111;
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
    box-sizing: border-box;
    position: relative;
    animation: slide-up 0.3s ease-out;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .sticky-button-container {
      position: sticky;
      bottom: 0;
      display: flex;
      justify-content: center;
      padding: 16px;
      background: linear-gradient(0deg, @black-color 0%, @black-color 25%, #0707061a 89%, #00000000 100%) !important;

      .sticky-button {
        width: 100%;
        height: 45px;
        border-radius: 24px;
        background-color: #fff;
        color: #000;
        font-family: @primary-font-semi-bold;
        font-size: 16px;
        border: none;
        cursor: pointer;
        text-align: center;
        transition: background-color 0.3s ease;
        margin-top: 40px;
        margin-bottom: 10px;
      }

      .sticky-button:hover {
        background-color: #e6e6e6;
      }
    }
  }
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
