@import url("../../../../style/index.less");

.upload-video-presentation-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 35px;
  margin-bottom: 25px;

  .without-video-upload-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .without-video-upload-container {
      display: flex;
      justify-content: center;
      align-self: center;
      width: 220px;
      height: 220px;
      background-color: @medium-green-color;
      border-radius: 50%;
    }

    .video-upload-description-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 3px;
      margin-top: 20px;

      .video-upload-description-primary-text {
        font-family: @primary-font-semi-bold;
        font-size: 18px;
        color: #ffffff;
      }

      .video-upload-description-secondary-text {
        font-family: @primary-font-regular;
        font-size: 14px;
        color: #8f8e93;
      }
    }
  }

  .with-video-upload-wrapper {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 100%;
    border-radius: 15px;
    background: linear-gradient(180deg, rgba(46, 46, 54, 0.4) 0%, rgba(46, 46, 54, 0) 100%);

    .with-video-upload-title {
      font-family: @primary-font-heavy;
      font-size: 18px;
      color: #ffffff;
      text-transform: uppercase;
    }

    .video-upload-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;

      .ant-upload-wrapper {
        .ant-upload {
          width: 100%;
        }
      }

      .video-upload-rectangle-shape {
        width: 100%;
        height: 175px;
        border: 2px dashed @white-color;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        grid-column: span 1;

        .add-circle-white {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 50px;
          padding: 15px;
          background-color: @white-color;
          border-radius: 50%;
        }
      }
    }
  }
}

.upload-video-presentation-button {
  width: 100%;
  display: flex;
  justify-content: center;

  .submit-button {
    font-family: @primary-font-semi-bold;
    font-size: 15px;
    background-color: #ffffff;
    color: #000000;
    border: none;
    border-radius: 20px;
    width: 75%;
    height: 40px;
  }
}
