@import url("../../../style/index.less");

.savedCardWrapper {
  .text-white {
    color: @white-color;
  }

  .gradient-background {
    width: 100% !important;
    background: linear-gradient(
      50deg,
      rgb(204, 0, 103),
      rgb(204, 0, 103),
      rgb(155, 29, 118),
      rgb(59, 82, 172),
      rgb(93, 123, 211),
      rgb(112, 145, 219),
      rgb(59, 82, 172)
    ) !important;
  }

  .SavePageContainer {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    gap: 1rem;
    color: @white-color;
  }

  .deleteLayer {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #0000006b;
    z-index: 1;
    border-radius: 8px;
  }

  .delete-button {
    display: flex;
    align-items: center;
    font-family: @primary-font-light;
    font-size: 12px;
    background-color: transparent;
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 15px;
    padding: 6px 15px;
    height: 28px;
  }

  .save-card-image-container {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .save-card-responsive-image {
    max-width: 100%;
    height: auto;
  }

  .save-card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .save-card-top-left {
    position: absolute;
    top: 26px;
    left: 35px;
  }

  .save-card-top-right {
    position: absolute;
    top: 26px;
    right: 30px;
  }

  .save-card-bottom-left {
    position: absolute;
    bottom: 20px;
    left: 35px;
    display: flex;
    flex-direction: column;
  }

  .title-save-card {
    font-family: @primary-font-bold;
    color: white;
    font-size: 20px;
    margin-bottom: 10px;
  }

  .username-save-card {
    font-family: @primary-font-bold;
    font-size: 22px;
  }

  .info-save-card {
    font-family: @primary-font-bold;
    color: white;
    font-size: 20px;
  }
}

.text-gradient-color {
  background: linear-gradient(to bottom, #e7e7e7 0%, #f3f3f3 50%, #c3c3c3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
