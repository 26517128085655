@import url("../../style/index.less");

.gradient-background {
  position: relative;
}

.auth-title {
  font-family: @primary-font-black;
  color: #ffffff;
  font-size: 40px !important;
  margin-bottom: 10px;
  margin-top: 15px;
  text-align: center;
}

.title-row {
  display: flex;
  align-items: center;
}

.title {
  margin-left: 10px;
}

.chevron-container {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 999;
}

.tabs-container {
  margin-top: 15px;
}
