@import url("../../style/index.less");

.requireSubscribeModalWrapper {
  background: linear-gradient(to top, #4163d8, #111111) !important;
  max-width: 350px;
  border-radius: 20px;

  .ant-modal-content {
    background-color: transparent;
    padding: 16px 0 0 0;

    .ant-modal-header {
      background-color: transparent;
      padding: 0 20px;
      margin-bottom: 0;

      .ant-modal-title {
        font-family: @primary-font-bold;
        font-size: 25px;
        line-height: 30px;
        text-align: center;
        color: @white-color;
      }
    }

    .ant-modal-body {
      padding: 6px 17px 16px 17px;

      .requireSubscribeModalDescription {
        font-family: @primary-font-regular;
        font-style: normal;
        font-size: 14px;
        line-height: 16px;
        color: #dadada;
        text-align: center;
      }
    }
  }

  .ant-modal-footer {
    text-align: center;
    margin-top: 0;

    .requireSubscribeFooterRpw {
      width: 100%;
      justify-content: center;
      padding: 15px 0;

      button {
        font-family: @primary-font-bold;
        font-size: 18px;
        line-height: 24px;

        &.acceptBtn {
          color: @black-color;
        }

        &.rejectBtn {
          margin-top: -15px;
          font-size: 18px;
          color: @white-color;
        }
      }

      .backgroundBtn {
        width: 80%;
        padding: 8px 15px;
        background-color: @white-color;
        border-radius: 30px;
      }
    }
  }

  .title-modal {
    font-family: @primary-font-bold;
    font-size: 28px;
    line-height: 30px;
    color: @white-color;
  }
}
