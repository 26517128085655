@import url("../../../../../../style/index.less");

.discover-search-bar-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-inline: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: transparent;
  height: 64px;
  z-index: 1000;

  .discover-search-bar {
    display: flex;
    align-items: center;
    padding-inline: 20px;
    border-radius: 15px;
    background: #1c1c1ea6;
    backdrop-filter: blur(4px);
    width: 100%;
    height: 100%;

    .discover-search-bar-text {
      font-family: @primary-font-regular;
      font-size: 16px;
      color: #d1d1d1;
      margin-left: 10px;
    }
  }

  .share-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 15px;
    border-radius: 15px;
    background: #25282d4d;
    backdrop-filter: blur(4px);
    height: 100%;

    img {
      width: 15px;
    }
  }
}
