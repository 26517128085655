@import url("../../../style/index.less");

.authForgotPasswordContentWrapper{
  min-height: 100dvh !important;
  padding: calc(@creator-personal-informations-header-height + 20px) 39px 120px
    38px;
}

.title {
  font-family: @primary-font-bold;
  font-size: 8vw !important;
  line-height: 11vw;
  text-align: center;
  color: @black-color;
}

.send-button{
  height: 20px;
}