@import url("../../style/index");

.creatorMessageAcceptMediaModal {
  max-width: 340px;

  .ant-modal-content {
    background: linear-gradient(to top, #101010 50%, #2f2f2f) !important;
    padding: 0;
    border-radius: 27px;

    .ant-modal-body {
      padding: 24px 28px;
    }

    .ant-modal-footer {
      text-align: center;
      margin-top: 0;

      .creatorMessageAcceptMediaFooterCancelRow {
        width: 100%;
        justify-content: center;
        padding-right: 30px;
        padding-left: 30px;

        .rejectBtn {
          font-family: @primary-font-bold;
          font-size: 15px !important;
          color: @white-color;
          padding-top: 25px;
          margin-bottom: 40px;
        }
      }
    }
  }
}

.accept-modal-input {
  font-family: @primary-font-medium;
  color: #ffffff;
  height: 45px;
  background-color: #1c1c1c !important;
  border-radius: 22px;
  border: 1px solid #424242;

  &.ant-input-affix-wrapper {
    background-color: #1c1c1c !important;
  }

  &::placeholder {
    color: @grey-color;
  }

  &:disabled {
    color: @grey-color;
    background-color: #1c1c1c !important;
  }

  .ant-input {
    color: @white-color;
    background-color: #1c1c1c !important;

    &::placeholder {
      color: @grey-color;
    }
  }

  .ant-input-suffix {
    color: @grey-color;
  }
}

.text-area-accept-modal {
  font-family: @primary-font-regular !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  background-color: #1c1c1c !important;
  padding: 5px 10px 5px 20px;
  border-color: #424242 !important;
  border-radius: 18px !important;
  color: @white-color;
  resize: none !important;

  &::placeholder {
    color: @grey-color;
  }
}
