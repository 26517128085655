@import url("../../../../style/index.less");

.presentation-video-button-container-wrapper {
  padding: 10px 0;

  .presentation-video-button-container {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    border-radius: 20px;
    background: #25282d;
    gap: 5px;
    cursor: pointer;

    .presentation-video-button-img {
      object-fit: cover;
      width: 15px;
    }

    .presentation-video-button-text {
      font-family: @primary-font-regular;
      font-size: 12px;
      color: #ffffff;
    }

    .presentation-video-button-notification {
      position: absolute;
      top: -1.5px;
      right: -3px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #ff3064;
    }
  }
}
