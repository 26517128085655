@import url("../../../style/index.less");

.helpandcontactwraper {
    min-height: 100dvh !important;
    padding: calc(@fan-setting-header-height + 13px) 25px 120px 25px;
}

.gradient-background-help-fan{
    background: linear-gradient(180deg, #4162D7 0%, #151515 46.5%);
  }

.header-title {
    font-size: 25px;
}

.item-title {
    font-family: @primary-font-regular;
    color: @white-color;
    font-size: 18px;
}

.helpandcontactwraper>a {
    color: @white-color;
    font-weight: 300;
    font-size: 16px;
}
