@import "../../style/index.less";

.fanFeedDetailsContentWrapper {
  height: calc(@vh-100 - @footer-height);
  padding-top: @header-height;
  //margin-bottom: @footer-height;
  overflow-y: auto;
  scrollbar-width: none !important;
}

.pub-content-container {
  //height: calc(@vh-100 - @header-height + @footer-height);
  padding-bottom: @footer-height;
}

.swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet {
  display: none;
}
