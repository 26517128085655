@import "../../style/index.less";

.cardWrapper {
  width: 100%;
}

.cardPost {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 230px;
  border-radius: 6px;
  cursor: pointer;
  background: linear-gradient(to bottom, #121212, #2A2A2A) !important;
}

.discoverButton{
  margin-top: 10px;
  background-color: @medium-green-color;
  border: none;
  border-radius: 12px;
  font-family: @primary-font-bold;
  width: 75%;
}

.cardAvatar {
  margin-top: 10px;
}
.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}
.UserName {
  font-weight: 600;
  font-size: 14px;
  color: @white-color;
  font-family: @primary-font-bold;
}
.FirstName {
  font-size: 12px;
  color: #828796;
  font-family: @primary-font-regular;
}
