@import url("../../../style/index.less");

.fanEventContentWrapper {
  min-height: 100dvh !important;
  .fanEventImgWrapper {
    width: 100%;
    height: 50vh;
    border-radius: 18px;
    padding: 0 5px;
    object-fit: cover;
    margin-top: 10px;
  }
}

.eventHeaderImg {
  position: relative;
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .eventHeaderLeftArrow {
    position: absolute;
    top: 20px;
    left: 20px;
    height: auto;
  }

  .eventHeaderUserInfo {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-top: 20px;

    .fullname {
      font-family: @primary-font-black;
      font-size: 40px;
      color: #ffffff;
      line-height: 50px;
    }

    .descriptionEvent {
      width: 100%;
      font-family: @primary-font-regular;
      font-size: 16px;
      color: #dadada;
    }
  }
}

.mainContent {
  display: flex;
  flex-direction: column;
  padding: 0 20px 33px 20px;

  .rowVerified {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .titleEvent {
    font-family: @primary-font-bold;
    font-size: 32px;
    color: #ffffff;
    margin-top: 10px;
  }

  .timeInfo {
    display: flex;
    margin-top: 20px;
    margin-bottom: 35px;
    width: 100%;
    flex-wrap: nowrap;
  }

  .divTimeInfo {
    padding: 15px 30px;
    background-color: #242424;
    border-radius: 30px;

    .timeText {
      font-family: @primary-font-semi-bold;
      font-size: 16px;
      color: #ffffff;
    }
  }

  .reserveButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 55px;
    font-family: @primary-font-bold;
    font-size: 18px;
    border-radius: 30px;
    background-color: #ffffff;
    color: #000000;
  }

  .freeAccess {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 55px;
    font-family: @primary-font-bold;
    font-size: 18px;
    border-radius: 30px;
    background-color: transparent;
    color: #ffffff;
    border: 2px solid #ffffff;
  }
}

.row-img {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;

  img {
    width: 26rem;
    margin-bottom: -20px;
  }
}
