@import url("../../style/index.less");

.messageLabelWrapper {
  display: flex;
  align-items: center;
  position: relative;

  img {
    width: 18px;
    height: 18px;
    object-fit: contain;
    margin-right: 3px;
  }

  .messageNewIcon {
    position: absolute;
    right: -12px;
    top: -3px;
    width: 8px;
    height: 8px;
    background: #ffffff;
    border-radius: 50%;
  }
}

.label {
  display: flex;
  align-items: center;
}
