@import url("../../../style/index.less");

.creatorPublicationContentWrapper {
  min-height: 100dvh !important;
  padding: calc(@creator-publication-header-height + 19px) 31px 20px 25px;
  background-color: linear-gradient(180deg, #1b1a1e 0%, #0b0b0b 100%);

  .imageContent {
    width: 78px;
    height: 78px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 4px;
  }
  .captionInput {
    font-family: @primary-font-regular;
    font-style: normal;
    font-size: 16px;
    line-height: 17px;
    color: @white-color;
    background-color: #292929;
    padding: 20px;
    border-radius: 20px;

    &::placeholder {
      color: @grey-color;
    }
  }

  // .publication-divider {
  //   background: #393b41;
  //   margin-left: -25px;
  //   width: calc(100% + 31px + 25px);
  // }

  // .text-color-offwhite {
  //   color: #747880;
  // }

  .switch {
    &.ant-switch-checked {
      .ant-switch-handle {
        inset-inline-start: calc(100% - 30px) !important;
      }
    }
  }
}

// .creatorPublicationEditContainer {
//   min-height: 100dvh !important;
//   position: absolute;
//   width: 100%;
//   top: 0;
//   z-index: 999;

//   .creatorPublicationEditContentWrapper {
//     padding: calc(@creator-publication-header-height) 31px 20px 25px;
//     background: linear-gradient(180deg, #1b1a1e 0%, #0c0c0c 100%) !important;
//     min-height: 100dvh !important;
//     border-bottom: 1px solid #393b41;

//     .captionInput {
//       font-family: @primary-font-regular;
//       font-style: normal;
//       font-size: 16px;
//       line-height: 17px;
//       color: @white-color;
//       padding: 10px;
//       border-radius: 20px;
//       height: 90px !important;
//       min-height: 90px !important;

//       &::placeholder {
//         color: #747880;
//       }
//     }

//     .publication-divider {
//       background: #393b41;
//       margin-left: -25px;
//       width: calc(100% + 31px + 25px);
//     }

//     .text-color-offwhite {
//       color: #747880;
//     }

//     .ok-text {
//       font-family: @primary-font-semi-bold;
//       font-size: 20px;
//       position: absolute;
//       right: 0;
//       cursor: pointer;
//       width: 35px;
//       color: @medium-green-color;
//     }

//     .Identify-btn {
//       width: fit-content;
//       padding: 0.5rem 1rem;
//       border-radius: 12px;
//       opacity: 0px;
//       color: white;
//       background: #25282d;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       margin-top: 0.5rem;
//     }
//   }
// }
