@import url("../../style/index.less");

.datePicker {
  width: 100%;
  height: 45px;
  border: unset;
  border-radius: 15px;

  &.ant-picker-focused {
    box-shadow: unset;
  }

  &.darkDatePicker {
    background-color: @light-black-color;
    padding: 7px 8px 8px 23px;

    .ant-picker-input {
      input {
        color: @white-color;

        &::placeholder {
          color: @grey-color;
        }
      }
    }
  }

  &.whiteDatePicker {
    height: 36px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  }

  &.darkDatePickerSignup {
    border: 1px solid @border-input;
    background-color: @background-input;
    padding: 9px 8px 8px 10px;
    border-radius: 6px !important;

    .ant-picker-input {
      input {
        color: @white-color;

        &::placeholder {
          color: @placeholder;
        }
      }

      .ant-picker-suffix img {
        filter: invert(43%) sepia(0%) saturate(0%) hue-rotate(217deg) brightness(95%) contrast(84%);
      }
    }
  }
}
