@import url("../../../style/index");

.public-live-content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 15px;
  margin-top: 10px;

  .public-live-content-text {
    font-family: @primary-font-bold;
    font-size: 30px;
    color: #ffffff;
  }

  .public-live-content-description {
    font-family: @primary-font-regular;
    font-size: 15px;
    color: #dadada;
    margin-top: 5px;
  }
}

.date-row {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;

  .date-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background: rgba(36, 36, 37, 0.6);
    padding: 15px 20px;
    width: 45%;

    .date-text {
      font-family: @primary-font-semi-bold;
      font-size: 17px;
      color: #ffffff;
    }
  }
}

.live-event-btn-row {
  width: 100%;
  padding: 0 15px;
  margin-top: 26px;
  margin-bottom: 20px;

  .reserve-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: none;
    border-radius: 20px;
    height: 55px;
    background: #e3be77;
    color: #ffffff;
    font-family: @primary-font-bold;
    font-size: 18px;
  }

  .already-reserve-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 2px solid #ffffff;
    border-radius: 20px;
    height: 55px;
    background: transparent;
    color: #ffffff;
    font-family: @primary-font-bold;
    font-size: 18px;
  }
}

.live-event-btn-sticky-row {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  padding: 0 15px;
  background: linear-gradient(to top, rgba(1, 1, 1, 1) 0%, rgba(1, 1, 1, 0) 100%);
  z-index: 1000;

  .reserve-sticky-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: none;
    border-radius: 20px;
    height: 55px;
    background: #e3be77;
    color: #ffffff;
    font-family: @primary-font-bold;
    font-size: 18px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  }
}
