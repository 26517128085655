@import url("../../../style/index.less");

.fanEmailNotificationWrapper {
  min-height: 100dvh !important;
  background: linear-gradient(180deg, #4A4A4A 0%, #111111 100%);
  padding: calc(@creator-monetizations-header-height + 20px) 18px 120px 18px;

  .switch {
    &.ant-switch-checked {
      
      .ant-switch-handle {
        inset-inline-start: calc(100% - 30px) !important;
      }
    }

    .revenueSwitch {
        width: 42px;
        height: 16px;
    }

    .ant-switch-handle {
        width: 27px;
        height: 28px;
    }
  }

  .fanNotificationtitleandSubtile{
    max-width: 80%;
  }

  .FanNotificationItemTitle {
    font-family: @primary-font-bold;
    font-style: normal;
    font-size: 18px;
    line-height: 28px;
    color: @white-color;
  }

  .FanNotificationItemSubTitle {
    font-family: @primary-font-regular;
    font-style: normal;
    font-size: 13px;
    line-height: 15px;
    color: @grey-dark-color;
    max-width: 75%;
    text-wrap: wrap;
  }

  .FanNotificationBox{
    border-bottom: 1px solid rgba(50, 50, 50, 1);
    padding:15px 0;
  }

}
