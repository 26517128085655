@import url("../../../style/index.less");

.fanHomeContentFeedWrapper {
  height: @vh-100;
  overflow-y: auto;
  scrollbar-width: none !important;
}

.liveButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px 8px 6px;
  border-radius: 12px;
  border: none;
  background-color: #ffffff1a;
  margin-left: auto;
}

.feed-home-container {
  height: calc(@vh-100 - @header-height);
}

.fanHomeContentCreatorListWrapper {
  padding: 0px;
  width: 100%;
}

.FanHomeHeading {
  color: @white-color;
  font-size: 36px;
  font-family: @primary-font-bold;
  line-height: 42.96px;
}

.fanHomepara {
  font-family: @primary-font-regular;
  font-size: 13px;
  line-height: 15.23px;
  color: @grey-color;
  max-width: 250px;
}
.FanHomepageCreatorText {
  margin-top: 20px;
  font-size: 20px;
  color: @white-color;
  font-family: @primary-font-bold;
  line-height: 19.09px;
}
.FanHomePageCreatorSuggestionListWrapper {
  padding-bottom: 110px;

  .row-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
  }

  .FanHomePageCreatorContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .FanHomePageCreatorListName {
    font-size: 15px;
    color: @white-color;
    font-family: @primary-font-bold;
    line-height: 16.71px;
  }
  .FanHomePageCreatorListUserName {
    font-size: 13px;
    color: @grey-color;
    font-family: @primary-font-regular;
    line-height: 14.32px;
  }
  .fanHomePageDiscoverButton {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    line-height: 14.32px;
    color: @black-color;
    font-family: @primary-font-bold;
    background-color: #5dd466;
    border-radius: 13.5px;
    border: 1px solid #5dd466;
    height: 29px;
    width: 85px;
  }
}

.creatorsuggestionwrapper {
  padding: 0 16px;
}

.header-background-fan {
  // background: linear-gradient(to top, #111111 70%, #363636) !important;
  background: #111111;
}

.header-row {
  padding-top: 10px;
  padding-left: 8px;
  padding-right: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: @header-page-height;
  // background: linear-gradient(to top, #111111 1%, #262626) !important;
}

.header-icon {
  margin-left: 10px;
  margin-bottom: 6px;
}

.title-home-fan {
  color: @white-color;
  font-family: @primary-font-black;
  font-size: 35px;
  color: @white-color;
  font-family: @primary-font-black;
  font-size: 35px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 120px);
}

@media (max-width: 350px) {
  .title-home-fan {
    font-size: 22px;
  }
}

@media (min-width: 351px) and (max-width: 380px) {
  .title-home-fan {
    font-size: 24px;
  }
}

@media (min-width: 381px) and (max-width: 420px) {
  .title-home-fan {
    font-size: 26px;
  }
}

@media (min-width: 421px) and (max-width: 540px) {
  .title-home-fan {
    font-size: 30px;
  }
}

@media (min-width: 541px) {
  .title-home-fan {
    font-size: 35px;
  }
}
