@import url("../../style/index.less");

.fan-phone-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
  overflow: hidden;

  .fan-phone-modal-content {
    width: 100%;
    max-width: 980px;
    height: auto;
    background-color: #1c1c1e;
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
    box-sizing: border-box;
    position: relative;
    animation: slide-up 0.3s ease-out;
    display: flex;
    flex-direction: column;

    .fan-phone-header {
      position: relative;
      width: 100%;

      .header-arrow-back {
        position: absolute;
        left: 20px;
        top: 20px;
      }
    }

    .fan-phone-content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      padding: 10px 20px;
      padding-bottom: 20px;

      .title-content {
        font-family: @primary-font-bold;
        font-size: 26px;
        color: #ffffff;
      }

      .description-content {
        font-family: @primary-font-regular;
        font-size: 12px;
        color: #8f8f91;
      }
    }
  }
}

.fan-phone-modal-input {
  height: 50px;
  background-color: @background-input;
  border: none;
  border-radius: 14px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  &::placeholder {
    color: @placeholder;
  }
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.keyboard-open {
  transform: none;
  transition: none;
}
