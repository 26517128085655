@import url("../../../../style/index.less");

.challenge-live-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
  overflow: hidden;

  .challenge-live-modal-content {
    width: 100%;
    max-width: 980px;
    height: auto;
    background-color: #1c1c1e;
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
    box-sizing: border-box;
    position: relative;
    animation: slide-up 0.3s ease-out;
    display: flex;
    flex-direction: column;

    .challenge-live-header {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 28px;

      .challenge-live-title {
        font-family: @primary-font-heavy;
        font-size: 32px;
        color: #ffffff;
        text-align: center;
      }
    }

    .challenge-live-content {
      width: 100%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      padding: 10px 20px;
      padding-bottom: 20px;

      .challenge-input-label {
        font-family: @primary-font-regular;
        font-size: 20px;
        color: #707070;
      }

      .challenge-input-title {
        margin-top: 15px;
        font-family: @primary-font-bold;
        font-size: 48px;
        width: 100%;
        height: auto;
        color: #ffffff !important;
        text-align: center;
        line-height: 60px;
      }

      .challenge-input-title::placeholder {
        color: #3a3a3a;
      }

      .challenge-price-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 45px;

        .challenge-price-input {
          margin: 20px 10px;
          font-family: @primary-font-black;
          font-size: 55px;
          width: 100%;
          height: 100%;
          color: #3a3a3a;
          text-align: center;
          line-height: 60px;

          .ant-input-number-input {
            color: #3a3a3a !important;
            font-family: @primary-font-black;
            width: 100%;
            height: 100%;
            display: flex;
            text-align: center;
            line-height: 60px;
          }
        }

        .challenge-price-input-completed {
          margin: 20px 10px;
          font-family: @primary-font-black;
          font-size: 55px;
          width: 100%;
          height: 100%;
          color: #ffffff !important;
          text-align: center;
          line-height: 60px;

          .ant-input-number-input {
            color: #ffffff !important;
            font-family: @primary-font-black;
            width: 100%;
            height: 100%;
            display: flex;
            text-align: center;
            line-height: 60px;
          }
        }
      }

      .divider-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;

        .challenge-divider-input {
          display: flex;
          width: 85%;
          max-width: 930px;
          background-color: @grey-color;
          height: 0.7px;
        }
      }
    }

    .challenge-live-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 0 45px;
      margin-bottom: 40px;
    }

    .new-challenge-button-container {
      margin-top: 30px;
      padding: 0 20px;

      .new-challenge-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        background: #242426;
        border-radius: 14px;
        border: none;
        padding: 15px 0;

        .new-challenge-button-title {
          font-family: @primary-font-semi-bold;
          font-size: 16px;
          color: #f5f5f5;
        }

        .new-challenge-button-desc {
          font-family: @primary-font-regular;
          font-size: 11px;
          color: #8f8e93;
        }
      }

      .new-challenge-cancel-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        background: transparent;
        border-radius: 14px;
        border: none;
        padding: 15px 0;
        margin-top: 15px;
        margin-bottom: 15px;

        .new-challenge-cancel-button-title {
          font-family: @primary-font-medium;
          font-size: 12px;
          color: #f5f5f5;
        }
      }
    }
  }
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
