@import "./colors.less";
@import "./font.less";

.primary-font-regular {
  font-family: @primary-font-regular;
}

.primary-font-bold {
  font-family: @primary-font-bold;
}

.theme-background-color {
  background-color: @background-color !important;
}

.white-background-color {
  background-color: @white-color;
}

.light-black-background-color {
  background-color: @light-black-color;
}

.medium-green-background-color {
  background-color: @medium-green-color;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.justify-content-start {
  justify-content: start;
}

.justify-content-end {
  justify-content: flex-end;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.d-contents {
  display: contents;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.w-full {
  width: 100%;
}

.w-unset {
  width: unset !important;
}

.max-w-169 {
  max-width: 169px;
}

.max-w-206 {
  max-width: 206px;
}

.max-w-211 {
  max-width: 211px;
}

.max-w-267 {
  max-width: 267px;
}

.max-w-273 {
  max-width: 273px;
}

.max-w-287 {
  max-width: 287px;
}

.h-full {
  height: 100%;
}

.h-full-screen {
  height: 100dvh;
}

.text-white-color {
  color: @white-color;
}

.text-black-color {
  color: @black-color;
}

.text-grey-color {
  color: @grey-color;
}

.text-light-red-color {
  color: @light-red-color;
}

.text-red-color {
  color: @red-color;
}

.text-medium-green-color {
  color: @medium-green-color;
}

.text-center {
  text-align: center;
}

.text-underline {
  text-decoration: underline;
}

.font-10-bold {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 10px;
  line-height: 12px;
}

.font-11-bold {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 11px;
  line-height: 13px;
}

.font-12-bold {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 12px;
  line-height: 14.32px;
}

.font-13-bold {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 13px;
  line-height: 16px;
}

.font-14-bold {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
}

.font-15-bold {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 15px;
  line-height: 18px;
}

.font-16-bold {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
}

.font-18-bold {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 18px;
  line-height: 21.48px;
}

.font-20-bold {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 20px !important;
  line-height: 24px !important;
}

.font-22-bold {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 22px;
  line-height: 26px;
}

.font-24-bold {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
}

.font-25-bold {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 25px;
  line-height: 30px;
}

.font-26-bold {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 26px;
  line-height: 31px;
}

.font-27-bold {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 27px;
  line-height: 32px;
}

.font-28-bold {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 28px;
  line-height: 33px;
}

.font-30-bold {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 30px;
  line-height: 35px;
}

.font-32-bold {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
}

.font-36-bold {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 36px;
  line-height: 40px;
}

.font-40-bold {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 40px;
  line-height: 40px;
}

.font-48-bold {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 48px;
  line-height: 40px;
}

.font-11-medium {
  font-family: @primary-font-medium;
  font-style: normal;
  font-size: 11px;
}

.font-12-medium {
  font-family: @primary-font-medium;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
}

.font-14-medium {
  font-family: @primary-font-medium;
  font-style: normal;
  font-size: 14px;
}

.font-15-medium {
  font-family: @primary-font-medium;
  font-style: normal;
  font-size: 15px;
  line-height: 21px;
}

.font-16-medium {
  font-family: @primary-font-medium;
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
}

.font-27-medium {
  font-family: @primary-font-medium;
  font-style: normal;
  font-size: 27px;
}

.font-18-medium {
  font-family: @primary-font-medium;
  font-style: normal;
  font-size: 18px;
  line-height: 21px;
}

.font-20-medium {
  font-family: @primary-font-medium;
  font-style: normal;
  font-size: 20px;
  line-height: 23px;
}

.font-10-regular {
  font-family: @primary-font-regular;
  font-style: normal;
  font-size: 10px;
  line-height: 12px;
}

.font-11-regular {
  font-family: @primary-font-regular;
  font-style: normal;
  font-size: 11px;
  line-height: 13px;
}

.font-12-regular {
  font-family: @primary-font-regular;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
}

.font-13-regular {
  font-family: @primary-font-regular;
  font-style: normal;
  font-size: 13px;
  line-height: 15px;
}

.font-14-regular {
  font-family: @primary-font-regular;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
}

.font-15-regular {
  font-family: @primary-font-regular;
  font-style: normal;
  font-size: 15px;
  line-height: 18px;
}

.font-16-regular {
  font-family: @primary-font-regular;
  font-style: normal;
  font-size: 15px;
  line-height: 18px;
}

.font-18-regular {
  font-family: @primary-font-regular;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
}

.font-20-regular {
  font-family: @primary-font-regular;
  font-style: normal;
  font-size: 20px;
  line-height: 23.87px;
}

.font-24-regular {
  font-family: @primary-font-regular;
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 1px !important;
}

.gap-2 {
  gap: 2px !important;
}

.gap-3 {
  gap: 3px !important;
}

.gap-4 {
  gap: 4px !important;
}

.gap-5 {
  gap: 5px !important;
}

.gap-6 {
  gap: 6px !important;
}

.gap-7 {
  gap: 7px !important;
}

.gap-8 {
  gap: 8px !important;
}

.gap-9 {
  gap: 9px !important;
}

.gap-10 {
  gap: 10px !important;
}

.gap-11 {
  gap: 11px !important;
}

.gap-12 {
  gap: 12px !important;
}

.gap-13 {
  gap: 13px !important;
}

.gap-14 {
  gap: 14px !important;
}

.gap-15 {
  gap: 15px !important;
}

.gap-18 {
  gap: 18px !important;
}

.gap-20 {
  gap: 20px !important;
}

.gap-21 {
  gap: 21px !important;
}

.gap-22 {
  gap: 22px !important;
}

.gap-23 {
  gap: 23px !important;
}

.gap-24 {
  gap: 24px !important;
}

.gap-25 {
  gap: 25px !important;
}

.gap-26 {
  gap: 26px !important;
}

.gap-29 {
  gap: 29px !important;
}

.gap-36 {
  gap: 36px !important;
}

.gap-37 {
  gap: 37px !important;
}

.gap-39 {
  gap: 39px !important;
}

.gap-40 {
  gap: 40px !important;
}

.gap-53 {
  gap: 53px !important;
}

.p-0 {
  padding: 0px !important;
}

.pt-0 {
  padding-top: 0px;
}

.pt-4 {
  padding-top: 4px;
}

.pt-6 {
  padding-top: 6px;
}

.pt-8 {
  padding-top: 8px;
}

.pt-9 {
  padding-top: 9px;
}

.pt-12 {
  padding-top: 12px;
}

.pt-14 {
  padding-top: 14px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-34 {
  padding-top: 34px;
}

.pr-0 {
  padding-right: 0;
}

.pr-3 {
  padding-right: 3px;
}

.pr-4 {
  padding-right: 4px;
}

.pr-6 {
  padding-right: 6px;
}

.pr-11 {
  padding-right: 11px;
}

.pr-13 {
  padding-right: 13px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-17 {
  padding-right: 17px;
}

.pr-18 {
  padding-right: 18px;
}

.pr-21 {
  padding-right: 21px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-23 {
  padding-right: 23px;
}

.pr-24 {
  padding-right: 24px;
}

.pr-28 {
  padding-right: 28px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-39 {
  padding-right: 39px;
}

.pr-45 {
  padding-right: 45px;
}

.pl-0 {
  padding-left: 0;
}

.pl-4 {
  padding-left: 4px;
}

.pl-6 {
  padding-left: 6px;
}

.pl-11 {
  padding-left: 11px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-21 {
  padding-left: 21px;
}

.pl-24 {
  padding-left: 24px;
}

.pl-27 {
  padding-left: 27px;
}

.pl-28 {
  padding-left: 28px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-38 {
  padding-left: 38px;
}

.pl-51 {
  padding-left: 51px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pb-4 {
  padding-bottom: 4px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-7 {
  padding-bottom: 7px;
}

.pb-8 {
  padding-bottom: 8px;
}

.pb-9 {
  padding-bottom: 9px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-11 {
  padding-bottom: 11px;
}

.pb-12 {
  padding-bottom: 12px;
}

.pb-14 {
  padding-bottom: 14px;
}

.m-auto {
  margin: auto;
}

.m-10 {
  margin: 10px;
}

.mt--5 {
  margin-top: -5px;
}

.mt--68 {
  margin-top: -68px;
}

.mt--90 {
  margin-top: -90px;
}

.mt--120 {
  margin-top: -120px;
}

.mt-1 {
  margin-top: 1px;
}

.mt-3 {
  margin-top: 3px;
}

.mt-4 {
  margin-top: 4px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-7 {
  margin-top: 7px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-9 {
  margin-top: 9px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-11 {
  margin-top: 11px;
}

.mt-13 {
  margin-top: 13px;
}

.mt-14 {
  margin-top: 14px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-17 {
  margin-top: 17px;
}

.mt-18 {
  margin-top: 18px;
}

.mt-19 {
  margin-top: 19px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-21 {
  margin-top: 21px;
}

.mt-22 {
  margin-top: 22px;
}

.mt-23 {
  margin-top: 23px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-27 {
  margin-top: 27px;
}

.mt-29 {
  margin-top: 29px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-33 {
  margin-top: 33px;
}

.mt-34 {
  margin-top: 34px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-36 {
  margin-top: 36px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-42 {
  margin-top: 42px;
}

.mt-46 {
  margin-top: 46px;
}

.mt-49 {
  margin-top: 49px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-58 {
  margin-top: 58px;
}

.mt-62 {
  margin-top: 62px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-79 {
  margin-top: 79px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-130 {
  margin-top: 130px;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-3 {
  margin-bottom: 6px !important;
}

.mb-6 {
  margin-bottom: 6px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mb-110 {
  margin-bottom: 110px !important;
}

.mb-120 {
  margin-bottom: 120px !important;
}

.ml-3 {
  margin-left: 3px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px !important;
}

.mr-3 {
  margin-right: 3px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-7 {
  margin-right: 7px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.br-9 {
  border-radius: 9px;
}

.br-18 {
  border-radius: 18px;
}

.br-21-5 {
  border-radius: 21.5px;
}

.br-23-5 {
  border-radius: 23.5px;
}

.br-25-5 {
  border-radius: 25.5px;
}

.br-28 {
  border-radius: 28px;
}

.z-fan-live {
  z-index: @fan-live-z-index;
  padding: 25px 15px;
}

.footer-shadow {
  background-image: linear-gradient(#ffffff00, #000000db);
}

.border-bottom-grey-color {
  border-bottom: 0.1px solid @grey-color;
}

.authBottomLabel {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  color: @black-color;
}

.authBottomLink {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  color: @medium-green-color !important;
  margin-left: 5px;
}

.authRowFooter {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.header {
  position: fixed;
  top: 0;
  //left: 0;
  width: 100vw;
  min-width: 350px;
  max-width: 980px;
  //height: unset !important;
  background-color: @background-color !important;
  line-height: unset !important;
  padding-inline: unset !important;
  padding: 17px 14px 14px 17px !important;
  z-index: 100;
  height: @header-height !important;
}

.header-arrow-left {
  position: absolute;
  left: 0;
  top: 5px;
  cursor: pointer;
  width: 35px;
}

.header-title {
  color: @white-color;
  font-family: @primary-font-bold;
  font-size: 30px;
}

.header-title-bold {
  color: @white-color;
  font-family: @primary-font-bold;
  font-size: 32px;
  margin-top: -7px;
}

.header-title-black-35 {
  color: @white-color;
  font-family: @primary-font-black;
  font-size: 35px;
}

.header-title-black-32 {
  color: @white-color;
  font-family: @primary-font-black;
  font-size: 32px;
}

.header-title-black-30 {
  color: @white-color;
  font-family: @primary-font-black;
  font-size: 30px;
}

.header-title-black-28 {
  color: @white-color;
  font-family: @primary-font-black;
  font-size: 28px;
}

.header-title-black-26 {
  color: @white-color;
  font-family: @primary-font-black;
  font-size: 26px;
}

.header-title-black-24 {
  color: @white-color;
  font-family: @primary-font-black;
  font-size: 24px;
}

.header-title-black-22 {
  color: @white-color;
  font-family: @primary-font-black;
  font-size: 22px;
}

.header-title-black-20 {
  color: @white-color;
  font-family: @primary-font-black;
  font-size: 20px;
}

.header-title-container {
  margin-top: -40px;
}

.header-title-container-30 {
  margin-top: -30px;
}

.header-title-container-50 {
  margin-top: -50px;
}

.header-title-container-60 {
  margin-top: -60px;
}

.header-title-container-65 {
  margin-top: -65px;
}

.header-title-container-70 {
  margin-top: -70px;
}

.header-title-container-80 {
  margin-top: -80px;
}

.header-title-container-90 {
  margin-top: -90px;
}

.header-title-container-95 {
  margin-top: -100px;
}

.header-title-container-100 {
  margin-top: -100px;
}

.content {
  background: @background-color;
}

.content-title {
  font-family: @primary-font-bold;
  font-size: 25px;
  line-height: 24px;
  color: @white-color;
}

.fanSubscribeHeaderBg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 267px;
  background: linear-gradient(
    0deg,
    #5ad965 0%,
    rgba(87, 218, 98, 0.97) 1%,
    rgba(67, 227, 75, 0.75) 11%,
    rgba(49, 234, 55, 0.55) 21%,
    rgba(34, 241, 38, 0.38) 32%,
    rgba(22, 246, 24, 0.24) 43%,
    rgba(12, 250, 14, 0.13) 54%,
    rgba(5, 253, 6, 0.06) 67%,
    rgba(1, 254, 1, 0.01) 81%,
    rgba(0, 255, 0, 0) 99%
  );
  transform: rotate(-180deg);
}

.fanSubscribeContentWrap {
  width: 100%;
  min-height: 100dvh !important;
}

.fanSubscribeHeaderContent {
  position: relative;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  padding: 23px 22px 15px 23px;
}

.tabs {
  .ant-tabs-nav {
    margin: 0 0 20px 0;

    &::before {
      border-bottom: unset;
    }

    .ant-tabs-tab {
      padding-top: unset;
      padding-bottom: 3px;

      .ant-tabs-tab-btn {
        font-family: @primary-font-regular;
        font-style: normal;
        font-size: 18px;
        line-height: 21px;
        color: @dark-grey-color;
      }

      + .ant-tabs-tab {
        margin: 0 0 0 14px;
      }
    }

    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: @white-color;
      }
    }

    .ant-tabs-ink-bar {
      width: 40px !important;
      height: 2px !important;
      background: @white-color;
    }
  }
}

.ant-space-item {
  line-height: 1 !important;
}

.spectateursTag {
  display: flex;
  align-items: center;
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 8px;
  line-height: 10px;
  color: @white-color;
  padding: 5px 12px;
  background: rgba(0, 0, 0, 0.3);
  border: unset;
  border-radius: 15px;
  margin-right: unset;
}

.tipTag {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 11px;
  line-height: 13px;
  color: @white-color;
  background: rgba(0, 0, 0, 0.4);
  padding: 8px 9px;
  border: unset;
  border-radius: 16px;
  margin-right: unset;
}

.whiteButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: unset;
  width: 100%;
  color: @black-color;
  background: @white-color;
  border: unset;
  //font-size: 18px;
  //padding: 12px;

  &.disabled {
    background: @grey-color;
  }

  &.big {
    font-size: 18px;
    padding: 12px;
  }

  &:active,
  &:hover {
    color: @black-color !important;
  }
}

.lightBlackButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: unset;
  width: 100%;
  color: @white-color;
  background-color: @light-black-color;
  border: unset;
}

.whiteOutlinedButton {
  background-color: transparent;
  border-color: @white-color;

  &:active,
  &:hover {
    border-color: @white-color !important;
    color: @white-color !important;
  }
}

.greyOutlinedButton {
  background-color: transparent;
  border-color: @grey-color;

  &:active,
  &:hover {
    border-color: @white-color !important;
    color: @white-color !important;
  }
}

.switch {
  background: @light-grey-color;

  &.revenueSwitch {
    width: 59px;
    height: 33px;

    .ant-switch-handle {
      width: 29px;
      height: 29px;
    }
  }

  &.mediaPushSwitch {
    width: 59px;
    height: 33px;

    .ant-switch-handle {
      width: 29px;
      height: 29px;
    }

    &.ant-switch-checked {
      .ant-switch-handle {
        left: calc(100% - 31px);
      }
    }
  }

  &.mediaAmountSwitch {
    width: 35px;
    height: 20px;

    .ant-switch-handle {
      top: 1px;
      width: 18px;
      height: 18px;
    }
  }

  &.ant-switch-checked {
    background: @medium-green-color !important;

    &:hover {
      background: @medium-green-color !important;
    }
  }

  &:hover {
    background: @light-grey-color !important;
  }

  .ant-switch-handle {
    &::before {
      border-radius: 50%;
    }
  }
}

.blurMediaSwitch {
  background: @light-grey-color;
  width: 200px;
  height: 120px;
  position: relative;

  .ant-switch-handle {
    top: 50%;
    width: 100px;
    height: 100px;
    left: 10px;
    transform: translateY(-50%);
    position: absolute;
  }

  &.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 110px);
  }

  &.ant-switch-checked {
    background: @medium-green-color !important;

    &:hover {
      background: @medium-green-color !important;
    }
  }

  &:hover {
    background: @light-grey-color !important;
  }

  .ant-switch-handle {
    &::before {
      border-radius: 50%;
    }
  }
}

.phoneCodeFlag {
  width: 19px;
  height: 19px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;

  &.dark {
    background-color: @light-black-color;
  }
}

.phoneCodeDropDown {
  top: 100px !important;
  left: 120px !important;
  height: calc(100dvh - @footer-height - 100px);
  overflow: auto;

  &.dark {
    color: @white-color;

    .ant-dropdown-menu {
      background-color: @light-black-color;
    }
  }
}

.targetRadioButton {
  width: 100%;
  height: unset;
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 16px;
  line-height: unset;
  color: @white-color;
  background-color: @light-black-color;
  padding: 7px 15px 8px 13px;
  border: unset;
  border-inline-start: unset !important;

  &.ant-radio-button-wrapper-checked {
    background-color: @medium-green-color !important;
  }

  .ant-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 17px;
    font-family: @primary-font-bold;
    font-style: normal;
    font-size: 9px;
    line-height: 11px;
    background-color: @white-color;
    padding: 2px 6px;
    border: unset;
    border-radius: 6px;
    margin-right: unset;
  }
}

.mediaAmountRadioButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: unset;
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 13px;
  line-height: 15px;
  color: @white-color;
  background-color: #292929 !important;
  padding: 11px 0 12px 0;
  border-radius: 15px !important;
  border: unset;
  border-inline-start: unset !important;
  margin-bottom: 8px;

  &.ant-radio-button-wrapper-checked {
    color: @black-color !important;
    background-color: @medium-green-color !important;
  }

  &.acceptModal {
    border-radius: 40px !important;
    font-size: 13px;
  }

  &.createCodePromo {
    background-color: @radio-button-color !important;
    border-radius: 32px !important;
    font-size: 14px;
    height: 50px !important;

    &.ant-radio-button-wrapper-checked {
      color: @black-color !important;
      background-color: @medium-green-color !important;
    }
  }

  &.mediaPush {
    background-color: @radio-button-color !important;
    border-radius: 32px !important;
    font-size: 12px;
    height: 50px !important;

    &.ant-radio-button-wrapper-checked {
      color: @black-color !important;
      background-color: @medium-green-color !important;
    }
  }

  &.fanSub {
    border-radius: 40px !important;
    font-size: 14px;
  }

  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    border-color: unset !important;
  }
}

.arrowLeft {
  position: absolute;
  left: 0;
}

.arrowRight {
  position: absolute;
  right: 0;
}

.threeBarsRight {
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
}

.customAmountWrapper {
  max-width: 111px;
}

.ant-picker-datetime-panel {
  flex-direction: column;
}

.messageListContent {
  flex-grow: 1;
  margin-bottom: 20px;
  overflow: auto;

  .messageDateLabel {
    font-family: @primary-font-regular;
    font-style: normal;
    font-size: 9px;
    line-height: 11px;
    color: @white-color;
  }
}

.ant-upload-list-item-thumbnail {
  color: @white-color !important;
}

.ant-progress-bg {
  background-color: @dark-green-color !important;
}

.img-crop-modal {
  .ant-modal-content {
    background-color: @light-black-color-1;
    padding: 14px 0;

    .ant-modal-close {
      color: @white-color;
    }

    .ant-modal-header {
      background-color: @light-black-color-1;

      .ant-modal-title {
        text-align: center;
        color: @white-color;
      }
    }

    .ant-modal-body {
      padding: 6px 17px 16px 17px;

      .img-crop-control-zoom {
        .ant-slider {
          .ant-slider-rail {
            background-color: rgb(255 255 255 / 50%);
          }
        }

        button {
          color: @white-color;
          font-size: 22px;
        }
      }
    }
  }

  .ant-modal-footer {
    margin-right: 17px;

    button {
      font-family: @primary-font-bold;

      &.ant-btn-primary {
        color: @black-color;
        background-color: @medium-green-color;
      }

      &.ant-btn-default {
        color: @black-color;
      }
    }
  }
}

.ant-btn-primary {
  color: @black-color;
  background-color: @medium-green-color;
}

.ant-popconfirm {
  z-index: 10000;
}

.gradient-background {
  background: linear-gradient(to top, #101010 50%, #2f2f2f) !important;
}

.gradient-background-black {
  background: linear-gradient(to top, #0f0f0f 45%, #4a4a4a) !important;
}

.gradient-background-blue {
  background: linear-gradient(180deg, #3d567a 0%, #151515 34.5%) !important;
}

.gradient-background-black-blue {
  background: linear-gradient(180deg, #011324 0%, #0c0c0c 50%) !important;
}

.gradient-background-blue-settings {
  background: linear-gradient(to top, #101010 50%, #2946ab) !important;
}

.gradient-background-profile {
  background: linear-gradient(180deg, #07192c 0%, #111111 25%);
}

.background-transparent {
  background-color: transparent !important;
}

.background-black {
  background-color: #000000 !important;
}

.swiper {
  width: 100%;
  height: 100%;
}

.savedCardbg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 400px !important;
  z-index: 0;
  background: linear-gradient(180deg, #4163da 0%, transparent 80%) !important;
}
.textAreaMessage {
  font-family: @primary-font-regular !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  background: #212121;
  padding: 5px 10px 5px 20px;
  border-color: #424242 !important;
  border-radius: 26px;
  color: @white-color;
  resize: none !important;

  &::placeholder {
    color: @grey-color;
  }
}

.custom-tabs {
  .ant-tabs-nav {
    &::before {
      content: none;
    }
    .ant-tabs-nav-list {
      background-color: #232323;
      border-radius: 40px;
    }
    .ant-tabs-tab {
      padding: 15px;
      margin: 0;
      height: 40px;

      .ant-tabs-tab-btn {
        font-family: @primary-font-bold;
        font-size: 16px;
        line-height: 28px;
        color: #ffffff;
      }
    }

    .ant-tabs-tab-active {
      background-color: @medium-green-color;
      padding-left: 20px;
      padding-right: 20px;
      border-radius: 40px;
      .ant-tabs-tab-btn {
        color: @black-color;
      }
    }

    .ant-tabs-ink-bar {
      display: none;
    }
  }
}

.custom-tabs.background-white .ant-tabs-tab-active {
  background-color: #ffffff;
  .ant-tabs-tab-btn {
    color: @black-color;
  }
}

.text-border {
  padding: 15px;
  border: 1px solid #ffffff;
  border-radius: 20px;
}

.text-grey-border {
  padding: 15px;
  border: 1px solid @grey-color;
  border-radius: 20px;
}

// used to hide scrollbar
.no-scrollbar::-webkit-scrollbar {
  display: none !important;
}

.no-scrollbar {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.overflow-hidden {
  overflow: hidden;
}

.ellipsis-single-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.uppercase {
  text-transform: uppercase !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.bottom-sticky-container {
  position: fixed;
  width: 100%;
  background: linear-gradient(0deg, @black-color 0%, @black-color 25%, #0707061a 89%, #00000000 100%) !important;
  height: 140px;
  z-index: 999;
  bottom: 0;
  left: 0;
  margin: 0;
}

img {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.saveBtn {
  width: 100%;
  height: 55px;
  font-family: @primary-font-bold;
  font-size: 18px !important;
  line-height: 28px;
  color: @black-color;
  background-color: @medium-green-color;
  padding: 12px 15px;
  border: none;
  border-radius: 50px;

  &:hover {
    color: @black-color!important;
    background-color: @medium-green-color;
    border: 1px solid @medium-green-color!important;
  }

  &:active {
    border: 1px solid @medium-green-color!important;
  }
  &:disabled {
    background-color: @grey-color;
    color: @black-color;
    border: none !important;
  }

  @media only screen and (max-width: 525px) {
    font-size: 4vw;
  }
}

// .ant-modal-root {
//   .ant-modal-mask {
//     background-color: rgba(0, 0, 0, 0.4) !important;
//   }
// }

.no-scroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  touch-action: none;
  overscroll-behavior: none;
}

.no-scroll-page {
  overflow: hidden;
  position: fixed;
  width: 100%;
  max-width: 980px;
  height: 100%;
}

.no-scroll-horizontal {
  touch-action: pan-y;
  overscroll-behavior: contain;
}

.ant-image-preview-operations .anticon-rotate-left,
.ant-image-preview-operations .anticon-rotate-right,
.ant-image-preview-operations .anticon-zoom-in,
.ant-image-preview-operations .anticon-zoom-out {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

// Bottom modal
.bottom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 9999;
  overflow: hidden;

  .bottom-modal-content {
    width: 100%;
    max-width: 980px;
    height: auto;
    background-color: #1c1c1e;
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
    box-sizing: border-box;
    position: relative;
    animation: slide-up 0.3s ease-out;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .bottom-modal-header {
      display: flex;
      justify-content: center;
      width: 93%;
      margin-top: 28px;
      padding: 0 10px;

      .bottom-modal-title {
        font-family: @primary-font-heavy;
        font-size: 32px;
        color: #ffffff;
        text-align: center;
        line-height: 44px;

        &.font-bold {
          font-family: @primary-font-bold;
        }

        &.promo-code {
          text-transform: uppercase;
          font-size: 36px;
          line-height: 50px;
        }

        &.f-30 {
          font-size: 30px !important;
          line-height: 40px;
        }

        &.f-28 {
          font-size: 28px !important;
          line-height: 38px;
        }

        &.f-26 {
          font-size: 26px !important;
          line-height: 36px;
        }

        &.f-24 {
          font-size: 24px !important;
          line-height: 34px;
        }

        &.f-22 {
          font-size: 22px !important;
          line-height: 32px;
        }

        &.f-20 {
          font-size: 20px !important;
          line-height: 30px;
        }
      }

      &.px-20 {
        padding: 0 20px !important;
      }
    }

    .bottom-modal-content {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 15px;
      padding: 0 20px;

      .bottom-modal-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 93%;
        background: #242426;
        border-radius: 20px;
        border: none;
        padding: 20px 0;

        &.subs-button {
          background-color: #ffffff;
        }

        &.cancelled-sub-button {
          background-color: #ffffff;
          padding: 15px 0;
          margin-bottom: 30px;
        }

        .bottom-modal-button-title {
          font-family: @primary-font-semi-bold;
          font-size: 16px;
          color: #f5f5f5;

          &.edit-collection {
            font-size: 18px;
          }

          &.subs-title {
            color: #000000;
          }
        }

        .bottom-modal-button-desc {
          font-family: @primary-font-regular;
          font-size: 11px;
          color: #8f8e93;
        }
      }

      .bottom-modal-cancel-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        background: transparent;
        border-radius: 14px;
        border: none;
        padding: 15px 0;
        margin-top: 15px;
        margin-bottom: 15px;

        .bottom-modal-cancel-button-title {
          font-family: @primary-font-medium;
          font-size: 14px;
          color: #f5f5f5;
        }

        &.mt-0 {
          margin-top: 0px !important;
        }
      }

      .bottom-modal-description {
        font-family: @primary-font-regular;
        font-size: 11px;
        color: #8f8e93;
        text-align: center;
        margin-bottom: 15px;
      }

      &.mt-10 {
        margin-top: 10px !important;
      }
    }
  }
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.formation-component-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px 15px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: linear-gradient(180deg, #0e41f4 0%, #111111 25.5%);

  &.modal-background {
    background: transparent !important;
  }

  .formation-basic-container {
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    padding: 25px 15px;
    width: 100%;
    margin-top: 25px;
    background-color: @background-formation-container;

    &.without-mt {
      margin-top: 0 !important;
    }
  }

  .stage-title {
    font-family: @primary-font-heavy;
    font-size: 20px;
    color: #ffffff;
    text-transform: uppercase;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;

    &.small {
      font-size: 16px;
    }

    &.small-15 {
      font-size: 15px;
    }

    &.small-14 {
      font-size: 14px;
    }
  }

  .stage-subTitle {
    font-family: @primary-font-heavy;
    font-size: 14px;
    color: #ffffff;
  }

  .stage-description {
    font-family: @primary-font-regular;
    font-size: 15px;
    line-height: 26px;
    color: #9ba1b3;

    .white {
      color: #ffffff;
    }
  }
}

.formation-modal-line-header {
  height: 4px;
  width: 25%;
  background-color: #929292;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: -12px;
}

.ant-image-preview-operations-icon {
  font-size: 25px !important; /* Adjust size as needed */
}
