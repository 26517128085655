@import url("../../style/index.less");

.promo-code-container {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .promo-code-modal-row {
    width: 100%;
    margin-top: 10px;
    text-align: center;
    font-family: @primary-font-bold;
    color: #ffffff;

    &.discount {
      font-size: 20px;
    }

    &.number-of-uses {
      font-family: @primary-font-regular;
      font-size: 18px;
      color: @grey-color;
    }
  }
}
