@import url("../../../style/index.less");

.layoutHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: unset !important;
  background-color: @white-color!important;
  line-height: unset !important;
  padding-inline: unset !important;
  padding: 17px 14px 14px 22px !important;
  z-index: 100;
  display: flex;
  justify-content: center;
}

.unlockLinkPaymentContentWrapper {
  min-height: 100dvh !important;
  padding: @unlock-link-payment-header-height 15px 30px;

  .applePayBtn {
    width: 100%;
    background-color: @black-color;
    border-color: @black-color;
    color: @white-color;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-form-item {
    margin-bottom: 15px;

    .ant-form-item-label {
      padding: unset;

      > label {
        color: @grey-color;
        font-size: 12px;
        font-weight: 500;
        height: unset;
      }
    }
  }
}

.custom-input {
  height: 36px;
  color: @white-color;
  background-color: @light-black-color!important;
  padding: 7px 8px 8px 10px;
  border: unset;
  box-shadow: unset !important;
  border: 1px solid #535353;
  border-radius: 5px;
  padding: 8px;

  &::placeholder {
    color: @grey-color;
  }

  &:hover {
    border-color: @light-black-color!important;
    box-shadow: unset;
  }

  .ant-input {
    background: @light-black-color;
    color: @grey-color;

    &::placeholder {
      color: @grey-color;
    }
  }
}

.custom-input:focus {
  border-color: #cdcdcd;
  outline: none;
}

.info-company-text {
  font-family: @primary-font-regular;
  font-size: 8px;
  color: @grey-dark-color !important;
}

.transaction-text {
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: @primary-font-regular;
  color: @grey-dark-color !important;
  font-size: 9px;
  text-align: center;
}
