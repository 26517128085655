@import url("../../../../../style/index.less");

.swiper-gif {
  position: absolute;
  bottom: 15%;
  z-index: 9999;
  width: 200px;
  height: auto;
  pointer-events: none;
}
