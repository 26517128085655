@import url("../../../style/index.less");

.fanRequestMediaName {
  font-family: @primary-font-regular;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: @white-color;
}

.fanRequestMediaSubscribeBtn {
  height: unset;
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 10px;
  line-height: 12px;
  color: @black-color;
  padding: 5px 8px;
  border-radius: 12px;

  &:active,
  &:hover {
    color: @black-color!important;
    border-color: @white-color!important;
  }
}

.fanRequestMediaStatus {
  font-family: @primary-font-regular;
  font-style: normal;
  font-size: 10px;
  line-height: 12px;
  color: @grey-color;
}

.fanRequestMediaWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100dvh;
  min-height: 100dvh !important;
  padding: calc(@creator-conversation-header-height) 10px 14px 10px;

  &.loading {
    justify-content: center;
  }
}

.creatorAcceptInfoText {
  width: 100%;
  max-width: 273px;
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: @white-color;
  margin: auto;
}

.header-chat {
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 980px;
  background-color: @background-color !important;
  z-index: 100;
  height: @header-height !important;
  padding: 14px 14px 17px 14px !important;
  box-sizing: border-box;
  overflow: hidden;
}

.header-chat-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  width: 100%;
}

.header-icon {
  flex-shrink: 0;
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.header-content {
  display: flex;
  align-items: center;
  margin-left: 5px;
  overflow: hidden;
}

.header-text-wrapper {
  display: flex;
  align-items: center;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.title-name-messagerie-header {
  font-family: @primary-font-black;
  font-size: 23px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 5px;
}

.three-dots-component {
  margin-left: auto;
  cursor: pointer;
  flex-shrink: 0;
}

.request-media-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: @primary-font-bold;
  font-size: 18px;
  color: #000000;
  background-color: #ffffff;
  border: none;
  border-radius: 30px;
  width: 80%;
  height: 50px;
  margin-bottom: 10px;
}

@media (max-width: 350px) {
  .title-name-messagerie-header {
    font-size: 18px;
  }
}

@media (min-width: 351px) and (max-width: 380px) {
  .title-name-messagerie-header {
    font-size: 20px;
  }
}

@media (min-width: 381px) and (max-width: 420px) {
  .title-name-messagerie-header {
    font-size: 21px;
  }
}

@media (min-width: 421px) and (max-width: 540px) {
  .title-name-messagerie-header {
    font-size: 22px;
  }
}

@media (min-width: 541px) {
  .title-name-messagerie-header {
    font-size: 23px;
  }
}
