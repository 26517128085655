@import url("../../../../style/index.less");

.creator-formation-page-wrapper {
  min-height: 100dvh !important;
  display: flex;
  flex-direction: column;
  padding-top: 18px;
  background-color: #000000;

  .formation-page-header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 20px;

    .formation-chevron-back {
      position: absolute;
      left: 0;
      margin-left: 30px;
      width: 13px;
    }

    .formation-title-page {
      font-family: @primary-font-black;
      font-size: 27px;
      color: #ffffff;
      text-align: center;

      &.small-title {
        font-size: 20px;
      }
    }
  }
}
