@import url("../../../style/index.less");

.creatorAllSubscribers {
  min-height: 100dvh !important;
  padding: calc(@creator-codePromotion-header-height) 15px 20px 15px;

  .d-flex {
    display: flex;
  }
  .justify-between {
    justify-content: space-between;
  }
  .fanSubscriptionPageAvatarImg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
  .fanSubscriptionCreatorName {
    font-size: 18px;
    color: @white-color;
    font-family: @primary-font-bold;
  }
  .fanSubscriptionStatus {
    font-size: 11px;
    font-family: @primary-font-regular;
    color: @grey-color;
  }
  .fanSubscriptionAssetButton {
    width: 57px;
    height: 24px;
    background-color: @white-color;
    box-shadow: 0px 1px 3px 0px #00000026;
    color: @black-color !important;
    font-size: 12px;
    font-family: @primary-font-bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .opacity-7 {
    opacity: 0.7;
  }
  .mb-5 {
    margin-bottom: 5px;
  }
}

.deleteButton {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  font-family: @primary-font-bold;
  flex-grow: 1;
  border-radius: 13px;
  font-size: 13px;
  height: 0 !important;
  padding: 18px 25px;
  border: none;
  background-color: @background-button-color;
  color: #ffffff;
  margin-right: 3px;
}

.modal-confirm-body-text {
  font-size: 18px;
  color: @white-color;
  font-family: @primary-font-regular;
  padding: 5px 5px 0px 5px;
}

.no-data-found {
  font-size: 18px;
  color: @medium-green-color;
  font-family: @primary-font-regular;
  padding-top: 50px;
  text-align: center;
}
