@import url("../../style/index.less");

.gradient-background-quick-message {
  background: linear-gradient(to top, #121212 50%, #2b2b2b) !important;
}

.creatorSavedResponsesModalWrapper {
  position: fixed;
  top: auto !important;
  bottom: 0 !important;
  left: 0;
  right: 0;
  max-width: 100%;
  margin: 0 auto;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  transform: none !important;

  &.ant-modal {
    padding-bottom: 0 !important;
  }

  .ant-modal-content {
    background: transparent;
    padding: unset;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
  }

  .creatorSavedResponsesModalHeader {
    justify-content: center;
    padding: 16px 21px 16px 15px;

    .creatorSavedResponseModalHeaderLine {
      display: block;
      margin: auto;
    }

    .align-center {
      display: flex;
      align-items: center;
    }
  }

  .creatorSavedResponsesModalContent {
    padding: 25px 13px 20px 18px;

    .creatorSavedResponsesList {
      height: 250px;
      padding-right: 7px;
      margin-bottom: 25px;
      overflow-y: auto;

      .creatorSavedResponsesItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 65px;
        margin-right: 10px;
        margin-left: -1px;
        cursor: pointer;
        padding: 10px 15px;
        border-radius: 20px;
        background-color: #1f1f1f;

        .ellipsis-text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          flex: 1;
        }
      }

      .chevron-icon-wrapper {
        display: flex;
        align-items: center;
      }
    }
  }
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #222222;
  border-radius: 50%;
  cursor: pointer;

  img {
    width: 16px;
    height: 16px;
  }
}

.centered-row {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
}
