@import url("../../style/index.less");

.messageTextItem {
  max-width: 75%;
  font-family: @primary-font-medium;
  font-style: normal;
  font-size: 15px;
  line-height: 15px;
  white-space: unset;
  color: @white-color;
  background: @background-message-green-color;
  padding: 8px 13px 9px 13px;
  border-radius: 18px;
  border: unset;
}

li::before {
  content: "";
  margin-left: -11px;
}

li::marker {
  font-size: 1.1em;
}
