@import url("../../style/index.less");

.dateTimePicker {
  padding: 10px 10px 11px 13px;
  width: 100%;
  border: unset;
  display: flex;
  align-items: center;
  border-radius: 18px !important;
  background-color: #292929 !important;
  border: 1px solid #4D4D4D;

  &.time{
    height: 47px !important;
  }

  &.ant-picker-focused {
    box-shadow: unset;
  }

  input {
    font-family: @primary-font-bold;
    font-style: normal;
    font-size: 16px !important;
    line-height: 19px;
    color: @white-color!important;

    &::placeholder {
      color: @white-color!important;
    }
  }

  .ant-select-arrow {
    display: none !important;
  }
}

.ant-picker-time-panel-column{
  overflow-y: scroll !important;
}

.custom-select .ant-select-selector {
  background-color: @light-black-color !important;
  border: unset !important;  
}

.custom-select .ant-select-selection-item, .ant-select-selection-placeholder{
  font-family: @primary-font-bold;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    color: @white-color!important;
}
