@import "../../style/index.less";

.main-content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  //height: 100%;
  background: transparent;
}

.main-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: unset !important;
  line-height: unset !important;
  padding-inline: unset !important;
  padding: 11px 16px !important;
  background: transparent !important;
}

.slideItemNameLabel {
  font-family: @primary-font-bold !important;
  font-size: 15px;
  line-height: 18px;
  color: @white-color !important;
}

.main-container-image {
  flex: auto;
  min-height: 250px;
  position: relative;
}

.slideItemPostImg {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  // background-size: cover;
  // background-position: center;
  // background-repeat: no-repeat;

  .postImg {
    width: 100%;
    height: 100%;
    max-height: 85dvh;
    max-width: 650px;
    object-fit: cover;

    @media only screen and (min-width: 1024px) {
      object-fit: contain;
    }
  }
}

.slideItemBookmark {
  position: absolute !important;
  top: 14px !important;
  right: 19px !important;
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.5));
  width: 25px !important;
  height: 25px !important;
  cursor: pointer;
}

.slideItemFooter {
  height: unset !important;
  line-height: unset !important;
  background: transparent !important;
  padding-inline: unset !important;
  padding: 24px 16px !important;
}

.slideItemLike {
  width: 23px;
  height: 20px;
}

.slideItemLikeLabel {
  font-family: @primary-font-regular;
  color: @white-color;
  font-size: 13px;
  line-height: 15.51px;
}

.slideItemLikeSubLabel {
  font-family: @primary-font-bold !important;
  color: @white-color;
  font-size: 13px;
  line-height: 15.51px;
  margin-left: 5px;
}

.slideItemLikeDescription {
  font-family: @primary-font-light !important;
  color: @white-color;
  font-size: 13px;
  font-weight: 100;
  line-height: 15.51px;
  margin-left: 8px;
}

.mb-8 {
  margin-bottom: 8px;
}

.privateContentOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);

  .privateContentText {
    text-align: center;
    color: white;

    .reserved-title {
      width: 100%;
      font-family: @primary-font-bold;
      color: #ffffff;
      font-size: 26px;
      margin-bottom: 10px;
    }

    .reserved-description {
      width: 100%;
      font-family: @primary-font-regular;
      color: #ffffff;
      font-size: 12px;
      margin-bottom: 20px;
    }
  }
}

.SubscriptionExtendBtn {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: @black-color;
  height: auto;

  .confirm-btn-background {
    font-family: @primary-font-bold;
    background-color: @white-color;
    padding: 10px 20px;
    border-radius: 20px;
    text-wrap: pretty;
  }
}