@import url("../../../style/index.less");

.creatorLiveEventDetailContentWrapper {
  min-height: 100vh !important;
  padding: calc(@creator-live-event-detail-height) 19px 0 19px;
  display: flex;
  flex-direction: column;

  .actionBtnWrapper {
    .ant-space-item {
      flex-grow: 1;
    }
  }

  .statWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
}

.liveEventMediaWrapper {
  width: 97px;
  height: 119px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;
}

.liveEventDeleteButton {
  width: 100%;
  height: 50px;
  color: @black-color;
  background-color: #ffffff;
  font-family: @primary-font-bold;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  text-align: center;
  padding: 8px 0;
  border-radius: 20px;

  &:active,
  &:hover {
    color: @light-red-color!important;
    border: 2px solid @grey-color!important;
  }
}

.liveProgrammedImg {
  position: relative;
  width: 100%;
  height: 300px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: -30px;
}
