@import url("../../style/index");

.liveTipModalWrapper {
  .ant-modal-content {
    background: linear-gradient(180deg, #3b53d7 0%, #95affc 100%);
    padding: 0;
    border-radius: 27px;

    .live-tips-img {
      width: 265px;
    }

    .ant-modal-body {
      padding: 19px 22px 34px 23px;

      .modal-title {
        width: 100%;
        font-family: @primary-font-black;
        font-size: 32px;
        line-height: 35px;
        color: #ffffff;
        text-transform: uppercase;
      }

      .row {
        width: 100%;
        justify-content: center;

        .actionBtn {
          width: 100%;
          font-style: normal;
          font-size: 16px;
          color: @white-color;
          height: 50px;
          font-family: @primary-font-bold;
          color: #000000;
          background-color: #ffffff;
          border: none;
          padding: 12px 25px;
          border-radius: 30px;

          &.disable {
            background: rgba(201, 201, 201, 0.8) !important;
          }
        }

        .cancelBtn {
          margin-top: -10px;
          margin-bottom: 10px;
          font-family: @primary-font-bold;
          font-style: normal;
          font-size: 16px;
          line-height: 24px;
          color: @white-color;
        }
      }
    }
  }
}

.live-tips-radio-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 14px;
  color: @white-color;
  background-color: transparent !important;
  padding: 11px 0 12px 0;
  border-radius: 40px !important;
  border: 2px solid #ffffff;
  border-inline-start: 2px solid #ffffff !important;
  margin-bottom: 8px;

  &.ant-radio-button-wrapper-checked {
    color: @black-color !important;
    background-color: #ffffff !important;
    border: 2px solid #ffffff !important;
    border-inline-start: 2px solid #ffffff !important;
  }

  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    border: 2px solid #ffffff !important;
    border-inline-start: 2px solid #ffffff !important;
  }
}

.personalized-amount-title {
  font-family: @primary-font-black;
  font-size: 25px;
  color: #ffffff;
}

.tipsInput {
  font-family: @primary-font-black;
  font-size: 54px;
  width: 100%;
  height: 100%;
  color: rgba(255, 255, 255, 0.3) !important;
  line-height: 60px;

  .ant-input-number-input {
    color: rgba(255, 255, 255, 0.3) !important;
    font-family: @primary-font-black;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 20px 0;
  }
}

.tipsInputCompleted {
  font-family: @primary-font-black;
  font-size: 54px;
  width: 100%;
  height: 100%;
  color: #ffffff !important;

  .ant-input-number-input {
    color: #ffffff !important;
    font-family: @primary-font-black;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 20px 0;
  }
}

@media (max-width: 350px) {
  .live-tips-img {
    width: 180px !important;
  }
}

@media (min-width: 351px) and (max-width: 380px) {
  .live-tips-img {
    width: 180px !important;
  }

  .liveTipModalWrapper {
    .ant-modal-content {
      .ant-modal-body {
        padding: 8px 22px 10px 22px;
        .modal-title {
          font-size: 26px !important;
          line-height: 30px !important;
        }

        .row {
          .actionBtn {
            font-size: 15px !important;
            height: 45px !important;
          }
        }
      }
    }
  }
}

@media (min-width: 381px) and (max-width: 420px) {
  .live-tips-img {
    width: 200px !important;
  }

  .liveTipModalWrapper {
    .ant-modal-content {
      .ant-modal-body {
        padding: 8px 22px 10px 22px;
        .modal-title {
          font-size: 26px !important;
          line-height: 30px !important;
        }

        .row {
          .actionBtn {
            font-size: 15px !important;
            height: 45px !important;
          }
        }
      }
    }
  }
}

@media (min-width: 421px) and (max-width: 540px) {
  .live-tips-img {
    width: 235px;
  }

  .liveTipModalWrapper {
    .ant-modal-content {
      .ant-modal-body {
        padding: 10px 22px 10px 22px;
        .modal-title {
          font-size: 30px !important;
          line-height: 34px !important;
        }
      }
    }
  }
}

@media (min-width: 541px) {
  .live-tips-img {
    width: 265px;
  }
}
