@import url("../../style/index");

.livesSwiperWrapper {
  .newLivesAvailableWrapper {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 1000;
  }

  .livesSwiper {
    background-color: @background-color;
  }

  .noLivesMessage {
    color: #a2a2a2;
    padding: 20px;
    font-size: 15px;
  }
}
