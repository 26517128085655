@import url("../../../style/index.less");
.recentVisted {
  background: transparent !important;
}

.row-recent-search {
  display: flex;
  align-items: center;
}
.RecentVisitedProfileHeader {
  line-height: 0px !important;
  height: auto !important;
  padding: 0 !important;
  margin: 5px 0px;
  background: transparent !important;

  .recentText {
    color: @white-color;
    font-family: @primary-font-bold;
    font-size: 16px;
  }
  .text-end {
    text-align: end;
  }
  .SeeEverythingText {
    color: @medium-green-color;
    font-family: @primary-font-bold;
    font-size: 16px;
  }
}
.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.recentVistedUserName {
  font-size: 16px;
  color: @white-color;
  font-family: @primary-font-semi-bold;
}
.recentVisitedFirstName {
  font-size: 14px;
  color: #828796;
  font-family: @primary-font-regular;
}
.recentVisitedTextWrapper {
  gap: 0px !important;
  margin-left: 15px;
}
.text-end {
  text-align: end;
}
