@import url("../../../style/index.less");

.creatorMediaPushContentWrapper {
  min-height: 100dvh !important;
  padding: @creator-media-push-header-height 20px 40px;

  .blur-media-row {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
    padding: 0 15px;
  }

  .switch {
    &.ant-switch-checked {
      .ant-switch-handle {
        inset-inline-start: calc(100% - 30px) !important;
      }
    }
  }
}

.segmentationCheckBox .ant-checkbox {
  display: none;
}

.ant-checkbox-wrapper-checked + label {
  background-color: @medium-green-color!important;
}

.segmentationCheckBox + label {
  background-color: @grey-color;
  width: 100%;
  height: 65px;
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 20px !important;
  line-height: unset;
  color: @white-color;
  background-color: @background-input;
  padding: 7px 25px 8px 20px;
  border: 1px solid @border-input;
  border-inline-start: 1px solid @border-input;
  border-radius: 28px;
  transition: background-color 0.2s;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.segmentationTag {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 11px;
  line-height: 11px;
  background-color: @white-color;
  padding: 4px 8px;
  border: unset;
  border-radius: 6px;
  margin-right: unset;
}

.stepOneDesign {
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
}

.center-container {
  margin-top: 100px;
}

.button-width {
  padding-left: 40px;
  padding-right: 40px;
}

.mediaPushDarkInput {
  height: 45px;
  color: @white-color;
  background-color: @background-input !important;
  padding: 7px 8px 8px 10px;
  border-color: @border-input;
  box-shadow: unset !important;
  border-radius: 12px;

  &.mx-w-94 {
    max-width: 94px;
  }

  &::placeholder {
    color: @placeholder;
  }

  &:disabled {
    color: @grey-color;
  }

  .ant-input {
    color: @white-color;
    background-color: @background-input !important;

    &::placeholder {
      color: @placeholder;
    }

    &:disabled {
      color: @grey-color;
    }
  }

  .ant-input-suffix {
    color: @placeholder;
  }

  &.rounded-16 {
    border-radius: 16px !important;

    &.with-border {
      border-color: @border-input !important;
    }

    &.without-border {
      border: none !important;
    }
  }
}

.mediaPushMediumGreenInput {
  height: 45px;
  color: @black-color;
  background-color: @medium-green-color !important;
  padding: 7px 8px 8px 23px;
  border: unset;
  border-radius: 12px;
  box-shadow: unset !important;

  &.mx-w-94 {
    max-width: 94px;
  }

  &::placeholder {
    color: @black-color;
  }

  .ant-input {
    color: @black-color;
    background-color: @medium-green-color !important;

    &::placeholder {
      color: @black-color;
    }
  }

  .ant-input-suffix {
    color: @black-color;
  }

  &.rounded-16 {
    border-radius: 16px !important;

    &.with-border {
      border-color: @border-input !important;
    }

    &.without-border {
      border: none !important;
    }
  }
}

.images-design {
  width: 95%;
  max-width: 450px;
  height: 20rem;
  border-radius: 20px;
  margin-top: 20px;
  object-fit: cover;
}

.mediaPushAmountPromoButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 13px;
  line-height: 15px;
  color: @white-color;
  background-color: @radio-button-color;
  padding: 11px 0 12px 0;
  border-inline-start: unset !important;
  margin-bottom: 8px;
  border: none !important;
  border-radius: 32px !important;

  &.ant-radio-button-wrapper-checked {
    color: @black-color !important;
    background-color: @medium-green-color !important;
  }
}

.padding-y {
  padding: 0 30px;
}

.header-arrow-left-media {
  position: absolute;
  left: -2px;
  top: 5px;
  cursor: pointer;
  width: 35px;
}
