@import url("../../../style/index.less");

.CreatorAddMediaContentWrapper {
  min-height: 100dvh !important;
  padding: calc(@creator-codePromotion-header-height) 1px 20px 1px;

  .addMediaAlbumWrapper {
    display: flex;
    align-items: center;
    margin-top: 20px;

    .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
      width: unset;
      height: unset;
      border: unset;
    }

    .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container {
      width: unset;
      height: unset;
      display: unset;
    }

    .ant-upload-list-item-container {
      width: 33.33% !important;
      height: 140px;
      min-height: 140px;
      max-height: 140px;
      min-width: 140px;
      display: block;
      margin: 0px !important;
      margin-block: 0px !important;
      margin-inline: 0px !important;
    }

    .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item {
      // height: 150px;
      width: 100% !important;
      width: max-content;
      border: unset;
    }

    .ant-upload-list-item {
      padding: 2px !important;
    }

    .ant-upload-list-item-thumbnail {
      width: 100% !important;

      img {
        object-fit: cover !important;
      }
    }

    .ant-upload-list.ant-upload-list-picture-card {
      display: flex;
      flex-wrap: wrap;
    }

    .ant-upload.ant-upload-select {
      width: 100% !important;
      margin-top: 20px;
    }

    .addCircleGreen {
      display: flex;
      justify-content: center;
      align-self: center;
      width: 220px;
      height: 220px;
      background-color: @medium-green-color;
      border-radius: 50%;
    }
  }

  .AlbumMediaUploadButton {
    color: @black-color !important;
    font-size: 21px;
    font-family: @primary-font-medium;
    padding: 30px 80px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  }

  .bg-white {
    background-color: @white-color !important;
  }

  .bg-grey {
    background-color: #595959 !important;
  }

  .full-width-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .centered-content {
    max-width: 270px;
    text-align: center;
    padding: 0 20px;
  }

  .creatorAddMediaText {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: @grey-color;
    font-family: @primary-font-regular;
  }

  .videoIcon {
    top: -130px !important;
    left: 10px !important;
    width: 20px !important;
    height: 20px !important;
  }
}
