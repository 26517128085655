@import url("../../../style/index.less");

.fanPrivateGalleryContentWrapper {
  min-height: 100dvh !important;
  padding: calc(@fan-privateGallery-header-height + 20px) 2px 20px 2px;

  .galleryItem {
    width: 100%;
    aspect-ratio: 1 / 1;
    background-position: center;
    background-size: cover;
    margin-top: 1px;
  }

  .albumVideoIcon {
    position: absolute;
    z-index: 100;
    width: 30px;
    height: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .galleryImage {
    object-fit: cover;
  }

  .not-file-text {
    width: 100%;
    font-family: @primary-font-regular;
    font-size: 18px;
    color: #ffffff;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: 50%;
  }
}
