@import url("../../style/index.less");

.ant-progress-circle-path {
  stroke: @medium-green-color !important;
}

.ant-progress-text {
  color: @white-color !important;
}

.ant-progress-inner {
  width: 90px !important;
  height: 90px !important;
  font-size: 22px !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.ant-progress {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.ant-spin {
  color: @white-color !important;
}

.ant-spin-text {
  text-shadow: none !important;
  margin-top: 50px;
}

.ant-spin-nested-loading .ant-spin-blur {
  opacity: 0.1 !important;
}