@import url("../../style/index.less");

.priceInput {
  font-family: @primary-font-black;
  background-color: transparent !important;
  font-size: 54px;
  width: 100%;
  height: 100%;
  color: @grey-color !important;
  font-weight: 900;
  line-height: 60px;
  border: none;
  outline: none;

  .ant-input-number-input {
    color: @grey-color !important;
    font-family: @primary-font-black;
    background-color: transparent !important;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 20px 0;
    border: none !important;
    outline: none !important;

    &:focus,
    &:active {
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }
}

.priceInputCompleted {
  font-family: @primary-font-black;
  background-color: transparent !important;
  font-size: 54px;
  width: 100%;
  height: 100%;
  color: #ffffff !important;
  border: none;
  outline: none;

  .ant-input-number-input {
    color: #ffffff !important;
    font-family: @primary-font-black;
    background-color: transparent !important;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 20px 0;
    border: none;
    outline: none;

    &:focus,
    &:active {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }
}
