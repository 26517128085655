@import url("../../../style/index.less");

.creatorConversationContentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100dvh;
  min-height: 100dvh !important;
  padding: calc(@creator-conversation-header-height) 10px 14px 10px;

  &.loading {
    justify-content: center;
  }
}

.gradient-background-conversation {
  background: linear-gradient(to top, #000000, #06192d) !important;
}

.header-title-container-conversation {
  margin-top: -50px;
}

.font-23-black {
  font-family: @primary-font-black;
  font-size: 23px;
}

.message-input-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10;
}

.mediaConversationBtn {
  font-family: @primary-font-bold;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  color: @black-color;
  background: @white-color;
  border: unset;
  padding: 6px 12px;
  border-radius: 30px;
  margin-left: 10px;

  &.small {
    height: 40px;
  }

  &.disabled {
    background: @grey-color;
  }

  &:active,
  &:hover {
    color: @black-color !important;
  }

  span {
    font-size: 18px;
  }
}
