@import url("../../../style/index.less");

.confirm-live-event-modal {
  .ant-modal-content {
    background: #282828;
    padding: 0;
    border-radius: 27px;

    .confirm-live-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .confirm-live-event-modal-row-title {
      display: flex;
      padding: 0 15px;
      margin-top: 20px;

      .confirm-live-event-modal-title {
        font-family: @primary-font-black;
        font-size: 28px;
        color: #ffffff;
      }
    }

    .confirm-live-event-modal-row-description {
      display: flex;
      padding: 0 15px;

      .confirm-live-event-modal-description {
        font-family: @primary-font-regular;
        font-size: 14px;
        color: #dadada;
      }
    }

    .confirm-live-event-modal-row-button {
      display: flex;
      padding: 0 15px;
      margin-top: 35px;

      .confirm-live-event-modal-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50px;
        background-color: #ffffff;
        border: none;
        border-radius: 24px;
        font-family: @primary-font-bold;
        font-size: 16px;
        color: #000000;
        margin-bottom: 20px;
      }
    }
  }
}
