@import url("../../style/index.less");

.countryDropdown {
  .countrySelect {
    font-family: @primary-font-regular;
    height: 45px;
    color: #ffffff !important;
    background: @background-input;
    border: 1px solid @border-input !important;
    padding: 7px 8px 8px 7px;
    border-radius: 5px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    font-size: 14px;
    padding-left: 15px;

    &.error {
      border-color: #ff4d4f !important;
    }

    .countrySelect::-ms-expand {
      display: none;
    }

    &::placeholder {
      color: @placeholder;
    }

    &:hover {
      border-color: @light-black-color!important;
      box-shadow: unset;
      color: @grey-color;
    }

    .ant-select-selection {
      border: unset !important;
    }

    .ant-select-selection-item {
      color: @grey-color !important;
    }
  }
}
