@import url("../../style/index");

.video-js {
  position: absolute;
  left: 0;
  top: 0;
  z-index: @agora-video-player-z-index;

  .vjs-tech {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
  }

  &.blur {
    filter: blur(18px);
  }
}

