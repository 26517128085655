@import url("../../style/index.less");

.no-publication-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  margin-top: 40px;

  .no-publication-img {
    object-fit: contain;
    width: 35%;
  }

  .no-publication-text {
    font-family: @primary-font-bold;
    font-size: 24px;
    color: #ffffff;
    text-align: center;
  }
}
