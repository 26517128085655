@import url("../../../style/index.less");

.creatorPutStoryContentWrapper {
  width: 100%;
  height: 100dvh;

  .imageWrapper {
    position: relative;
    width: 100%;
    height: calc(100dvh - 98px);

    img {
      position: absolute;
      left: 28px;
      top: 26px;
    }

    .imageContent {
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}
