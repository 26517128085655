@import url("../../../../../style/index.less");

.swiper-wrapper {
  position: relative;

  .swiper-container {
    width: 100%;
    overflow: hidden;

    .swiper {
      display: flex;
      flex-direction: column;
      scroll-snap-type: y mandatory;
      overflow-y: auto;
      //scroll-behavior: smooth;

      .swiper-slide {
        flex: none;
        scroll-snap-align: start;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background: #333;
        position: relative;
        overflow: hidden;
        height: calc(var(--vh, 100vh) - var(--header-height, 0px) - var(--footer-height, 0px));
        margin-bottom: env(safe-area-inset-bottom, 0px);

        &:last-child {
          margin-bottom: 70px;
        }

        img {
          max-width: 100%;
          object-fit: cover;
          transition: transform 0.3s ease-in-out;
        }
      }
    }
  }
}

.discover-suggestion-wrapper {
  margin-top: 70px;
  padding-left: 15px;
}

.hidden {
  display: none;
}
