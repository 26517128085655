@import url("../../../style/index.less");

.unlockLinkContentWrapper {
  min-height: 100dvh !important;
  padding: @unlock-link-header-height 15px 30px;

  .mediaImage {
    object-fit: cover;
    filter: blur(15px);
  }

  .container-img {
    position: relative;
    display: inline-block;

    .decrycptLinkImage {
      border-radius: 25px;
      object-fit: cover;
      max-height: 320px;
      min-height: 340px;
      max-width: 300px;
      min-width: 300px;
    }

    .video-icon {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 24px;
      height: 24px;
    }
  }

  .divider {
    display: flex;
    width: 100%;
    min-width: 100%;
    margin: 24px 0 10px;
    background-color: #636363;
    height: 1px;
  }
}

.bottom-container {
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  max-width: 980px;
  background: linear-gradient(0deg, @black-color 0%, @black-color 25%, #0707061a 89%, #00000000 100%) !important;
  height: 140px;
  z-index: 999;
  bottom: 0;
}

.bottom-sticky {
  display: flex;
  align-items: center;
  position: fixed;
  height: 65px;
  left: 20px;
  right: 20px;
  width: calc(100% - 40px);
  background-color: #fff;
  border-radius: 18px;
  padding-left: 20px;
  padding-right: 7px;
  z-index: 1000;
  bottom: 10px;
  max-width: 950px;
  margin: 0 auto;
}

.left-content {
  flex-grow: 1;
}

.price {
  font-family: @primary-font-bold;
  margin: 0;
  color: black;
  font-size: 20px;
  font-weight: 900;
}

.secure-payment {
  font-family: @primary-font-light;
  font-size: 12px;
  margin: 0;
  color: #808080;
}

.pay-button {
  background-color: @medium-green-color;
  font-family: @primary-font-bold;
  color: black;
  border: none;
  border-radius: 15px;
  padding: 0 25px;
  height: 80%;
  font-size: 17px;
}

.link {
  color: inherit !important;
  text-decoration: none;
}

.header-title-container {
  margin-top: -40px;
}

.priceDecryptFan {
  font-family: @primary-font-black;
  font-size: 54px;
  line-height: 60px;
  color: #ffffff;
}

.bottomDecrypt {
  padding: 0 20px;
}
