@import url("../../../../style/index.less");

.creator-create-collection-page-wrapper {
  min-height: 100dvh !important;
  padding: @creator-collection-header-height 5px 15px 5px;
}

.collection-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 120px;

  .collection-title-row {
    display: flex;
    justify-content: center;
    max-width: 85%;
    margin-bottom: 20px;
  }
}

.collection-data-wrapper {
  padding: 0 15px 100px 15px;

  .segmentationCollectionCheckBox .ant-checkbox {
    display: none;
  }

  .segmentationCollectionCheckBox + label {
    width: 75%;
    height: 50px;
    font-family: @primary-font-bold;
    color: @white-color;
    background-color: #222222;
    padding: 7px 25px 8px 20px;
    border: none;
    border-radius: 24px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }

  .create-collection-btn-sticky-row {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    padding: 0 15px;
    background: linear-gradient(to top, rgba(1, 1, 1, 1) 0%, rgba(1, 1, 1, 0) 100%);
    z-index: 1000;

    .collection-sticky-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border: none;
      border-radius: 20px;
      height: 55px;
      background: #e3be77;
      color: #ffffff;
      font-family: @primary-font-bold;
      font-size: 18px;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    }
  }
}

.creator-add-file-wrapper {
  min-height: 100dvh !important;
  padding: @creator-media-push-header-height 20px 40px;

  .img-collection-design {
    width: 95%;
    max-width: 450px;
    height: 26rem;
    border-radius: 20px;
    margin-top: 15px;
    object-fit: cover;
  }

  .add-file-switch-blur-container {
    margin-top: 25px;
    padding: 0 15px;

    .divider-collection {
      margin-top: 20px;
      display: flex;
      width: 100%;
      max-width: 980px;
      background-color: #323232;
      height: 0.5px;
    }

    .icon-title-blur-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      .lock-icon {
        width: 19.5px;
        margin-right: 20px;
      }

      .blur-title {
        font-family: @primary-font-regular;
        font-size: 16px;
        color: #ffffff;
      }
    }

    .icon-title-thumb-container {
      display: flex;
      flex-direction: row;
      align-items: start;
      flex: 1;

      .thumb-icon {
        width: 20px;
        margin-right: 20px;
        margin-top: 5px;
      }

      .title-subtitle-row {
        display: flex;
        flex-direction: column;
        max-width: 80%;

        .thumbnail-title {
          font-family: @primary-font-regular;
          font-size: 16px;
          color: #ffffff;
        }

        .thumbnail-subtitle {
          font-family: @primary-font-light;
          font-size: 12px;
          color: #83878c;
        }
      }
    }
  }

  .collection-file-switch {
    width: 44px;
    height: 26px;
    background: @light-grey-color;

    .ant-switch-handle {
      width: 22px;
      height: 22px;
      background: @medium-green-color !important;
      transition: left 0.3s ease;
      border-radius: 50%;

      &::before {
        border-radius: 50%;
      }
    }

    &.ant-switch-checked .ant-switch-handle {
      left: calc(100% - 23px);
    }

    &.ant-switch-checked {
      background: @medium-green-color !important;

      &:hover {
        background: @medium-green-color !important;
      }
    }

    &:hover {
      background: @light-grey-color;
    }
  }
}
