@import url("../../../../style/index.less");

.no-video-presentation-container {
  display: flex;
  padding: 10px;
  margin: 15px;
  border-radius: 12px;
  gap: 15px;
  background: linear-gradient(90deg, #0e0e0e 34%, #161618 69%);

  .no-video-presentation-gif-container {
    width: 60%;

    .plane-video {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .no-video-presentation-description-container {
    display: flex;
    flex-direction: column;

    .no-video-presentation-title {
      font-family: @primary-font-black;
      font-size: 18px;
      color: #ffffff;
      text-transform: uppercase;
    }
    .no-video-presentation-description {
      font-family: @primary-font-regular;
      font-size: 10px;
      color: #dadada;
      margin-top: 5px;
    }

    .no-video-presentation-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      width: 60%;
      background-color: #ffffff;
      color: #000000;
      font-family: @primary-font-semi-bold;
      font-size: 12px;
      padding: 0 25px;
      border: none;
      border-radius: 30px;
      margin-top: 15px;
      cursor: pointer;
    }
  }
}
