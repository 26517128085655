@import url("../../../style/index.less");

.fanMonetizationsContentWrapper {
  min-height: 100dvh !important;
  padding: calc(@creator-monetizations-header-height + 20px) 18px 120px 18px;

  .switch {
    &.ant-switch-checked {
      
      .ant-switch-handle {
        inset-inline-start: calc(100% - 30px) !important;
      }
    }
  }

}
