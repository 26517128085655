@import url("../../../../style/index.less");

.history-presentation-video-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: #2e2e3666;
  width: 100%;
  padding: 15px;
  margin-top: 25px;
  margin-bottom: 25px;

  .history-presentation-video-title {
    font-family: @primary-font-heavy;
    font-size: 22px;
    color: #ffffff;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  .history-presentation-video-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 15px;

    .history-presentation-video-row {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 15px;
      border-bottom: 0.7px solid #2e2e36;
      padding-bottom: 15px;

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      .history-presentation-video-avatar {
        object-fit: cover;
        border-radius: 50%;
        width: 75px;
        height: 75px;
        margin-right: 15px;
      }

      .history-presentation-video-info-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .history-presentation-video-date {
          font-family: @primary-font-semi-bold;
          font-size: 15px;
          color: #ffffff;

          .to-icon {
            width: 12px;
            margin-inline: 5px;
          }
        }

        .history-presentation-video-data-container {
          display: flex;
          justify-content: space-between;

          .history-presentation-video-data-text {
            font-family: @primary-font-light;
            font-size: 15px;
            color: #9ba1b3;
            text-transform: lowercase;

            .green {
              color: @medium-green-color;
            }
            .orange {
              color: #fecc4b;
            }
          }
        }
      }
    }
  }
}
