@import url("../../style/index.less");

.authSignupContentWrapper {
  min-height: 81vh !important;
  height: auto !important;
  //padding: 15px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}

.padding-container {
  padding: 15px 30px;
}

.signUpContainer {
  padding: 15px 30px !important;
}

.authTermsCheckBox {
  font-family: @primary-font-regular;
  font-style: normal;
  font-size: 10px;
  line-height: 15px;
  color: @grey-color;

  .text-color-green {
    color: @medium-green-color;
  }

  a {
    color: @grey-color;
    text-decoration: underline;
  }

  .ant-checkbox-input {
    width: 10px !important;
    height: 10px !important;
  }

  .ant-checkbox-inner {
    width: 12px !important;
    height: 12px !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: @grey-color !important;
    border-color: @grey-color !important;
  }
}

.custom-input-signup {
  height: 45px;
  color: @white-color;
  background-color: @background-input !important;
  padding: 7px 8px 8px 10px;
  border: unset;
  box-shadow: unset !important;
  border: 1px solid @border-input;
  border-radius: 5px;
  padding: 8px;

  &::placeholder {
    color: @placeholder;
  }

  &:hover {
    border-color: @border-input !important;
    box-shadow: unset;
  }

  .ant-input {
    background: transparent;
    color: @placeholder;

    &::placeholder {
      color: @placeholder;
    }
  }
}
