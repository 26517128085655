@import url("../../style/index.less");

.authInputLabel {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 13px;
  line-height: 15px;
  color: #ffffff;
}

.authInfoText {
  font-family: @primary-font-regular;
  font-size: 10px;
  line-height: 15px;
  color: @medium-green-color;
}

.authInput {
  font-family: @primary-font-regular;
  color: #ffffff;
  height: 45px;
  background: @background-input;
  border-radius: 6px;
  border: 1px solid @border-input;

  input {
    font-family: @primary-font-regular;
    color: #ffffff;
    background-color: transparent;

    &::placeholder {
      color: @placeholder !important;
    }
  }

  &.ant-input-disabled {
    color: #ffffff !important;
    border: 1px solid @border-input;
  }

  &.ant-input-affix-wrapper {
    background-color: @background-input !important;
  }

  &.input-error {
    border: 1px solid #ff6600;
  }
}

.authInput::placeholder {
  color: @placeholder;
}

.authInput:focus {
  border-color: @border-input !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
}

.authInput:hover {
  border-color: @border-input !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
}

.authInput.uppercaseInput input {
  text-transform: uppercase;
}
