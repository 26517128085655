@import url("../../../style/index.less");

.newMessageContentWrapper {
  min-height: 100dvh !important;
  padding: @creator-new-message-header-height 21px 21px 21px;

  .name-label {
    font-size: 16px;
    color: @white-color;
    font-family: @primary-font-semi-bold;
  }
}
