@import url("../../../style/index.less");

.fanSubscribeContentWrap {
  .fanSubscribeHeaderAvatarRow {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .premiumLabel {
    font-family: @primary-font-bold;
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
    color: @white-color;
  }

  .title {
    font-family: @primary-font-bold;
    font-style: normal;
    font-size: 30px;
    line-height: 36px;
    color: @white-color;
  }

  .mainContent {
    display: flex;
    flex-direction: column;
    padding: 0 20px 100px 20px;
    width: 100%;

    .subscribeListHeader {
      margin-top: 20px;

      .subscribeListHeaderLabel {
        font-family: @primary-font-bold;
        font-style: normal;
        font-size: 24px;
        line-height: 24px;
        color: @white-color;
      }
    }

    .subscribeListContent {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 20px;
      width: 100%;

      .subscribe-list-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 15px 10px;
        border-radius: 15px;
        background: #242426;

        .subscribe-list-left-side {
          display: flex;
          align-items: center;

          .subscribeItemLabel {
            font-family: @primary-font-regular;
            font-size: 14px;
            color: #e6e6e6;
            margin-left: 15px;
          }
        }
      }
    }
  }
}

.fanSubscribeHeaderImg {
  position: relative;
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.fanSubscribeHeaderLeftArrow {
  position: absolute;
  top: 20px;
  left: 20px;
  height: auto;
}

.fanSubscribeHeaderUserInfo {
  position: absolute;
  bottom: 1px;
  left: 20px;

  .fullname {
    font-family: @primary-font-black;
    font-size: 40px;
    color: #ffffff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  }
}

.premiumIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  border-radius: 50%;
}

.fanSubscribeContinueButtonWrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .w-button {
    width: 95%;
    display: flex;
    justify-content: center;
  }
}

.stickyButtonWrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  margin-bottom: 5px;
  width: 100%;
  padding: 10px;
  background-color: transparent;
  z-index: 1000;
}

@media (min-width: 600px) {
  .title {
    font-size: 3rem !important;
    line-height: 1 !important;
  }
}

@media (min-width: 900px) {
  .title {
    font-size: 5rem !important;
    line-height: 1.1 !important;
  }
}
