@import url("../../style/index.less");

.select {
  width: 100%;

  &.dark {
    .ant-select-selector {
      height: 36px;
      background-color: @light-black-color;
      border: unset;
      border-radius: 6px;

      .ant-select-selection-item {
        display: flex;
        align-items: center;
        font-family: @primary-font-regular;
        font-style: normal;
        font-size: 15px;
        line-height: 18px;
        color: @grey-color;
      }
    }

    .ant-select-arrow {
      color: @grey-color;
    }
  }
}

.disabled {
  cursor: not-allowed;
  border-radius: 6px;
  background: aliceblue !important;

  .ant-select-selector {
    height: 36px;
    background-color: rgba(197, 197, 197, 0.219) !important;
    border: unset;
    border-radius: 6px;
  }
}

.pause-text {
  color: @white-color;
  font-family: @primary-font-regular;
  font-style: normal;
  font-size: 18px;
  line-height: 18px;
}
