.video-player {
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
  background-color: transparent !important;
  position: relative;

  .thumbnail-container {
    width: 100%;
    height: 100%;
    max-height: 85dvh;
    max-width: 650px;
    position: relative;
    cursor: pointer;

    .thumbnail-image {
      width: 100%;
      height: 100%;
      max-height: 85dvh;
      max-width: 650px;
      object-fit: cover;
    }

    .play-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 72px;
      color: #fff;
      opacity: 0.8;
    }
  }

  .video-container {
    width: 100%;
    height: 100%;
    position: relative;

    video {
      width: 100%;
      height: 100%;
      max-height: 85dvh;
      object-fit: contain;
    }

    .video-watermark {
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
      pointer-events: none;
      z-index: 1;
    }

    .controls {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
      padding: 10px;
      display: flex;
      flex-direction: column;
      opacity: 0;
      transition: opacity 0.3s ease;

      &:hover {
        opacity: 1;
      }

      &.isPause {
        opacity: 1;
      }

      &.show {
        opacity: 1;
      }

      &.hide {
        opacity: 0;
      }

      .progress-bar {
        width: 100%;
        height: 5px;
        background-color: rgba(255, 255, 255, 0.3);
        margin-bottom: 10px;
        touch-action: none;

        .progress {
          height: 100%;
          background-color: #fff;
        }
      }

      .buttons {
        display: flex;
        justify-content: space-between;

        .control-button {
          background: transparent;
          border: none;
          color: #fff;
          font-size: 24px;
          cursor: pointer;
          padding: 0;
          margin: 0;
        }
      }
    }
  }
}
