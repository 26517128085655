@import url("../../../style/index.less");

.intro-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.gradient-background-intro {
  background: linear-gradient(to top, #121212 10%, #353535) !important;
}

.authContentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: calc(@fan-setting-header-height + 13px) 25px 100px 25px;
  overflow: hidden;
}

.intro-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.title-intro {
  font-family: @primary-font-bold;
  color: #ffffff;
  font-size: 30px !important;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
}

.row {
  width: 100%;
}

.register-row {
  margin-top: 50px;
}

.registerLink {
  font-family: @primary-font-bold;
  font-size: 16px;
  color: #ffffff;
}

.footer-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: auto;
}
