@import url("../../style/index.less");

.revenue-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
  overflow: hidden;

  .revenue-modal-content {
    width: 100%;
    max-width: 980px;
    height: auto;
    min-height: 50%;
    background-color: #121212;
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
    box-sizing: border-box;
    position: relative;
    animation: slide-up 0.3s ease-out;
    display: flex;
    flex-direction: column;

    .modal-content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding: 10px 20px;
      padding-bottom: 20px;

      &.center-loader {
        justify-content: center;
        align-items: center;
      }

      .detailed-revenue-wrapper {
        padding: 10px;

        .total-earning-container {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .date-label {
            font-family: @primary-font-regular;
            font-size: 12px;
            color: @grey-color;
          }

          .price-label {
            font-family: @primary-font-regular;
            font-size: 14px;
            color: #ffffff;
          }
        }

        .detailed-revenue-chart-container {
          margin-top: 10px;
          width: 100%;

          .detailed-revenue-chart {
            width: 100%;
            height: 20px;
            border: none;
            border-radius: 30px;
            display: flex;
            overflow: hidden;
          }

          .revenue-chart-segment {
            height: 100%;
            transition: width 0.3s ease;
          }
        }

        .detailed-revenue-container {
          display: flex;
          flex-direction: column;
          gap: 7px;
          margin-top: 15px;

          .detailed-revenue-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .circle-label-container {
              display: flex;
              align-items: center;
              flex-direction: row;

              .detailed-revenue-color {
                height: 10px;
                width: 10px;
                border-radius: 50%;
                margin-right: 15px;
              }

              .detailed-revenue-label {
                font-family: @primary-font-regular;
                font-size: 14px;
                color: #ffffff;
              }
            }

            .detailed-revenue-price {
              font-family: @primary-font-regular;
              font-size: 14px;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
