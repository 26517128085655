@import "../../style/index.less";

.live-tips-leaderboard-row {
  width: 100%;

  .live-tips-leaderboard-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 5px 10px;
    border-radius: 30px;
    background: #1616168c;
    margin-right: 10px;
    width: auto;
    position: relative;

    &:last-child {
      margin-right: 0;
    }

    &.first {
      opacity: 1;
    }

    &.second {
      opacity: 0.7;
    }

    &.third {
      opacity: 0.4;
    }

    .live-tips-leaderboard-item-icon {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }

    .live-tips-leaderboard-item-name {
      font-family: @primary-font-medium;
      font-size: 11px;
      color: #ffffff;
      margin-right: 5px;
    }

    .live-tips-leaderboard-item-price {
      font-family: @primary-font-black;
      font-size: 12px;
      color: #ffffff;
    }
  }

  @media (max-width: 480px) {
    .live-tips-leaderboard-item {
      padding: 5px 10px;
      .live-tips-leaderboard-item-icon {
        width: 18px;
        height: 18px;
      }
      .live-tips-leaderboard-item-name {
        font-size: 12px;
      }
      .live-tips-leaderboard-item-price {
        font-size: 12px;
      }
    }
  }

  @media (max-width: 450px) {
    .live-tips-leaderboard-item {
      padding: 5px 8px;
      .live-tips-leaderboard-item-icon {
        width: 16px;
        height: 16px;
      }
      .live-tips-leaderboard-item-name {
        font-size: 11px;
      }
      .live-tips-leaderboard-item-price {
        font-size: 12px;
      }
    }
  }

  @media (max-width: 410px) {
    .live-tips-leaderboard-item {
      padding: 4px 6px;
      .live-tips-leaderboard-item-icon {
        width: 14px;
        height: 14px;
      }
      .live-tips-leaderboard-item-name {
        font-size: 11px;
      }
      .live-tips-leaderboard-item-price {
        font-size: 11px;
      }
    }
  }

  @media (max-width: 375px) {
    .live-tips-leaderboard-item {
      padding: 4px 6px;
      .live-tips-leaderboard-item-icon {
        width: 14px;
        height: 14px;
      }
      .live-tips-leaderboard-item-name {
        font-size: 10px;
      }
      .live-tips-leaderboard-item-price {
        font-size: 10px;
      }
    }
  }
}
