@import url("../../style/index.less");

.fan-profile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
  overflow: hidden;

  .fan-profile-overlay-footer {
    text-align: center;
    padding: 20px;
    width: 100%;
    margin-bottom: 10px;

    .fan-profile-overlay-text {
      font-family: @primary-font-semi-bold;
      font-size: 30px;
      color: #ffffff;
      line-height: 44px;
      text-align: center;
    }

    .fan-profile-overlay-button {
      font-family: @primary-font-semi-bold;
      background-color: #ffffff;
      color: #000000;
      border: none;
      padding: 15px;
      width: 85%;
      max-width: 500px;
      border-radius: 30px;
      cursor: pointer;
      font-size: 19px;
      margin-top: 10px;
    }
  }
}
