@import url("../../../style/index.less");

.discover-header-wrapper {
  &.ant-layout-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: transparent;
    padding-inline: 0;
    width: 100%;
    height: auto;
  }

  .discover-header-is-searching-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px;

    .cancel-text-col {
      display: flex;
      justify-content: end;
    }
  }
}

.discover-content-wrapper {
  min-height: 100dvh !important;
  padding: 0;

  .padding-inline-container {
    padding-inline: 15px;
  }

  .direct-links-wrapper {
    overflow-x: auto;
    width: 100%;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding-left: 15px;
  }
}

.swiper-gif-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  pointer-events: none;
}
