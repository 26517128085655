@import url("../../../../../style/index.less");

.tips-presentation-videos-container {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 15px;
  margin-top: 25px;

  .tips-presentation-videos-container-title {
    font-family: @primary-font-black;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    text-transform: uppercase;
  }

  .tips-presentation-videos-container-desc {
    display: flex;
    flex-direction: column;
    width: 65%;
    margin-top: 15px;
    z-index: 11;

    .tips-presentation-videos-container-desc-text {
      font-family: @primary-font-regular;
      font-size: 15px;
      line-height: 26px;
      color: #30333d;
    }

    .tips-presentation-videos-container-button {
      font-family: @primary-font-semi-bold;
      font-size: 15px;
      background-color: #000000;
      color: #ffffff;
      border: none;
      border-radius: 20px;
      width: 75%;
      height: 40px;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }

  .tips-presentation-videos-animation {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 60%;
    border-radius: 15px;
    z-index: 10;
  }
}

@media (min-width: 458px) {
  .tips-presentation-videos-animation {
    max-width: 50% !important;
  }
}

@media (min-width: 540px) {
  .tips-presentation-videos-animation {
    max-width: 35% !important;
  }
}

@media (min-width: 620px) {
  .tips-presentation-videos-animation {
    max-width: 25% !important;
  }
}

@media (min-width: 780px) {
  .tips-presentation-videos-animation {
    max-width: 15% !important;
  }
}
