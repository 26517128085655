@import url("../../../style/index.less");

.fanSettingContentWrapper {
  min-height: 100dvh !important;
  padding: calc(@fan-setting-header-height + 13px) 15px 55px 15px;
}

.fanSettingGroup {
  margin-bottom: 15px;
  padding: 5px 15px;
  border-radius: 15px;
  height: 150px;
  background-color: rgba(77, 77, 77, 0.4);
  display: flex;
  flex-direction: column;
}

.cursor-disabled{
  cursor: not-allowed;
}

.fanSettingGroupItem {
  margin-bottom: 15px;
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 15px;
  background-color: rgba(77, 77, 77, 0.4);
}

.fanSettingGroupHeader {
  margin-bottom: 13px;
}

.fanSettingItem {
  margin: 20px;
}

.fanSettingItemTitle {
  font-family: @primary-font-medium;
  color: @white-color;
  font-size: 18px;
}

.SettingQuickManageButton {
  background-color: @white-color;
  color: #050505 !important;
  font-family: @primary-font-bold;
  font-size: 16px;
  padding: 20px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 23px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.communityButton {
  background-color: @white-color;
  color: #050505 !important;
  font-family: @primary-font-bold;
  font-size: 14px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 23px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  border: none;
}

.header-title-container-settings {
  margin-top: -60px;
}

.two-columns-wrapper {
  display: flex;
  justify-content: space-between;

  .bottom-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: auto;
  }

  .two-columns-title {
    font-family: @primary-font-semi-bold;
    font-size: 18px !important;
    color: white;
  }

  .two-columns-text {
    font-family: @primary-font-regular;
    font-size: 12px !important;
    color: #dadada;
  }
}

.two-columns-wrapper .fanSettingGroup {
  flex: 1;
  max-width: 48%;
}

.professionalize-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 20px;
  background: linear-gradient(101.52deg, #0d0d0d 3.31%, #0c071e 50.09%, #2b1487 98.79%);
}

.professionalize-content {
  flex: 2;
  max-width: 60%;

  .professionalize-title {
    margin-top: 10px;
    font-family: @primary-font-bold;
    font-size: 22px;
    line-height: 28px;
    color: @white-color;

    &.fanSection {
      font-size: 20px !important;
    }
  }

  .professionalize-description {
    margin-top: 5px;
    font-family: @primary-font-regular;
    font-size: 14px;
    color: #dadada;
    &.fanSection {
      font-size: 12px;
    }
  }

  .communityButton {
    background-color: @white-color;
    color: #050505 !important;
    font-family: @primary-font-medium;
    font-size: 14px;
    padding: 20px 24px;
    border-radius: 23px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0;

    &.fanSection {
      font-size: 15px;
    }
  }
}

.professionalize-image {
  flex: 1;
  max-width: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: -10px;
}

.fanSettingLogOut {
  margin-top: 20px;
  font-family: @primary-font-bold;
  font-size: 24px;
  line-height: 29px;
  color: @red-color;
}

@media only screen and (max-width: 380px) {
  .two-columns-title {
    font-size: 14px !important;
  }
}
