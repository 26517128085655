@import "../../style/index.less";

.fanFeedDetailsContentWrapper {
  height: @vh-100;
  padding-top: @header-page-height;
}

.swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet {
  display: none;
}
