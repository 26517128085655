@import url("../../../style/index.less");

.creatorChangePasswordContentWrapper {
  min-height: 100dvh !important;
  padding: calc(@creator-change-password-header-height) 20px 120px 20px;
}

.header-text-center {
  text-align: center;
}
