@import url("../../style/index.less");

.payment-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  background: #efefef0d;
  border-radius: 15px;
  padding: 20px;
  gap: 20px;

  .payment-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.7px solid #2b2b36;
    padding-bottom: 20px;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    .tag {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 28px;
      font-family: @primary-font-semi-bold;
      font-style: normal;
      font-size: 13px;
      line-height: 16px;
      background-color: @white-color;
      padding-left: 13px;
      padding-right: 12px;
      border: unset;
      border-radius: 12px;
    }
  }
}

.not-payment-text {
  width: 100%;
  font-family: @primary-font-regular;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 20%;
}
