@import "../../style/index.less";

.footer {
  height: @footer-height;
  position: fixed;
  bottom: 0;
  //left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 350px;
  max-width: 980px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 6%, rgba(0, 0, 0, 0.582781) 40.5%, #000000 81.5%) !important;
  padding: 10px !important;
  z-index: 10;
  gap: 10px;

  &.black {
    background: @black-color !important;
  }

  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .footerItem {
    width: 20%;
  }

  .footerItemMsg {
    width: 20%;
  }

  .footerItemLive {
    width: 20%;
  }

  .creatorHomeFooterItemWrapper {
    position: relative;
    margin-bottom: 12px;

    .creatorHomeFooterItem {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 35px;
      background: @white-color;
      border-radius: 14px;
      margin-inline: 10px;
      transform: translateX(-3px);
      //margin-inline-end: 0;
    }

    .creatorHomeSubFooterItemWrapper {
      -webkit-animation: fadeIn 700ms;
      animation: fadeIn 700ms;
      position: absolute;
      z-index: 1001;

      &.creatorPostFooterItem {
        left: -60px;
        top: -50px;
      }

      &.creatorStoryFooterItem {
        left: -40px;
        top: -80px;
      }

      &.creatorPushFooterItem {
        //uncomment this whene the story fomctionallity is ready
        //left: 10px;
        //top: -100px;
        left: -15px;
        top: -80px;
      }

      &.creatorLiveFooterItem {
        //uncomment this whene the story fomctionallity is ready
        //left: 60px;
        left: 50px;
        top: -80px;
      }

      &.creatorDecryptFooterItem {
        left: 100px;
        top: -50px;
      }

      &.creatorCloseFooter {
        left: 28px;
        top: -30px;
      }

      .creatorHomeSubFooterItemTag {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 38px;
        height: 38px;
        background-color: @white-color;
        border-radius: 50%;
        margin: 0;
      }

      .creatorHomeSubFooterItemText {
        font-family: @primary-font-regular;
        font-style: normal;
        font-size: 9px;
        line-height: 11px;
        color: @white-color;
      }

      .creatorHomeSubFooterCloseTag {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
        background-color: @white-color;
        border-radius: 50%;
      }
    }
  }
  .items-overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
}

.ant-badge-count {
  box-shadow: none !important;
  background-color: #ff3235 !important;
}

.profileDotNotification {
  .ant-badge-dot {
    width: 11px !important;
    height: 11px !important;
    box-shadow: none !important;
    background-color: #ff4d4f !important;
    transform: translate(35%, -40%) !important;
  }
}

.footer-modal-overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
}

.footer-modal-content {
  width: 100%;
  max-width: 980px;
  background-color: #1c1c1e;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-sizing: border-box;
  position: relative;
  animation: slide-up 0.3s ease-out;
  display: flex;
  flex-direction: column;
  padding: 20px 15px;

  .modal-line-header {
    height: 5px;
    width: 35%;
    background-color: #4e4e50;
    border-radius: 10px;
    margin-top: -13px;
  }

  .sub-menu-content {
    background-color: #242426;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding: 15px 15px;

    .sub-menu-row {
      margin: 15px 0;

      .footer-icon-img {
        width: 42px;
        margin-right: 10px;
      }

      .sub-menu-title {
        font-family: @primary-font-medium;
        font-size: 16px;
        color: #ffffff;
      }

      .sub-menu-description {
        font-family: @primary-font-regular;
        font-size: 11px;
        color: #8f8e93;
      }
    }
  }
}

.footer-modal-menu-formation-content {
  width: 100%;
  max-width: 980px;
  background: #111111;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-sizing: border-box;
  position: relative;
  animation: slide-up 0.3s ease-out;
  display: flex;
  flex-direction: column;
  padding: 20px 15px;

  .modal-line-header {
    height: 5px;
    width: 35%;
    background-color: #4e4e50;
    border-radius: 10px;
  }

  .formation-menu-content {
    background-color: #ffffff;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding: 15px 15px;
    margin-top: 15px;

    .formation-menu-title {
      font-family: @primary-font-heavy;
      font-size: 27px;
      color: #191a1d;
      line-height: 35px;
      text-transform: uppercase;
      margin-top: 10px;
    }

    .formation-menu-desc {
      font-family: @primary-font-regular;
      font-size: 15px;
      color: #191a1d;
      line-height: 28px;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .formation-menu-img-container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 15px;

      .formation-menu-img {
        object-fit: contain;
        width: 100%;
      }
    }
  }

  .formation-menu-button {
    width: 100%;
    height: 50px;
    border-radius: 30px;
    background-color: #ffffff;
    color: #000000;
    font-family: @primary-font-semi-bold;
    font-size: 16px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slide-down {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

.footer-modal-content.close-modal {
  animation: slide-down 0.3s ease-out forwards;
}

@media (max-width: 350px) {
  .footer-icon-img {
    width: 32px !important;
  }

  .sub-menu-title {
    font-size: 14px !important;
  }

  .sub-menu-description {
    font-size: 10px !important;
  }
}

@media (min-width: 351px) and (max-width: 380px) {
  .footer-icon-img {
    width: 35px !important;
  }

  .sub-menu-title {
    font-size: 14px !important;
  }

  .sub-menu-description {
    font-size: 10px !important;
  }
}
