@import url("../../style/index.less");
.ant-radio-button-wrapper {
  padding-inline: 10px !important;
}

.fanSubscribeTypeRadioButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: unset;
  font-family: @primary-font-bold;
  font-style: normal;
  line-height: 15px;
  color: #8f8f91;
  background-color: transparent;
  padding: 11px 0 12px 0;
  border-radius: 40px !important;
  font-size: 14px;
  border: unset;
  border-inline-start: unset !important;
  margin-bottom: 8px;

  &.ant-radio-button-wrapper-checked {
    color: @white-color !important;
    background-color: rgba(41, 41, 41, 0.4) !important;
  }

  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    border-color: unset !important;
  }
}
