@import url("../../../style/index");

.creatorLivePublishPage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100dvh;
  max-height: 100dvh;
  //padding: 32px 31px 14px 27px;
  overflow: hidden;

  .creator-live-header {
    font-size: clamp(14px, 5vw, 20px) !important;
  }

  .live-ends {
    position: absolute;
    top: 4px;
  }

  .alert-end-live {
    color: #ff90a3 !important;
    padding: 2px 12px !important;
    display: inline-block;
    animation: showHideText 3s infinite;
  }

  @keyframes showHideText {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
}

.header-container-left-live-creator {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 10px 4px 5px;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.3);

  .fullname {
    font-family: @primary-font-bold;
    font-size: clamp(13px, 5vw, 15px) !important;
    color: #ffffff;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    margin-left: 5px;
    margin-right: 3px;
  }

  .follow-button {
    display: flex;
    align-items: center;
    font-family: @primary-font-regular;
    font-size: 12px;
    color: #000000;
    background-color: #ffffff;
    padding: 3px 10px;
    border-radius: 15px;
    height: 25px;
    border: none;
    margin-left: 10px;

    &:active,
    &:hover {
      color: #000000 !important;
      border: none !important;
    }
  }
}

.tips-micro-row {
  display: flex;
  justify-content: space-between;
  margin: 0 5px;
}

.tips-row {
  display: flex;
  flex-direction: row;
  align-items: end;
}

.tips-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px !important;
  border-radius: 30px !important;
  border: 2px solid #ff03a9;
  background: linear-gradient(101.52deg, #0d0d0d 3.31%, #0c071e 50.09%, #2b1487 98.79%);

  .font-18-black {
    font-family: @primary-font-black;
    font-size: 18px;
  }

  &.last-tips {
    height: 80% !important;
    white-space: nowrap;
  }

  &.fan-style {
    height: 75% !important;
    padding: 6px 10px !important;
  }
}

.font-28-black {
  font-family: @primary-font-black !important;
  font-size: 28px !important;
  line-height: 35px;
}
