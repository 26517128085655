@font-face {
  font-family: "SF-Pro-Display-Ultralight";
  src: url("/assets/fonts/SF-Pro-Display-Ultralight.ttf");
}

@font-face {
  font-family: "SF-Pro-Display-Thin";
  src: url("/assets/fonts/SF-Pro-Display-Thin.ttf");
}

@font-face {
  font-family: "SF-Pro-Display-Light";
  src: url("/assets/fonts/SF-Pro-Display-Light.ttf");
}

@font-face {
  font-family: "SF-Pro-Display-Regular";
  src: url("/assets/fonts/SF-Pro-Display-Regular.ttf");
}

@font-face {
  font-family: "SF-Pro-Display-Medium";
  src: url("/assets/fonts/SF-Pro-Display-Medium.ttf");
}

@font-face {
  font-family: "SF-Pro-Display-Semi-Bold";
  src: url("/assets/fonts/SF-Pro-Display-Semibold.ttf");
}

@font-face {
  font-family: "SF-Pro-Display-Bold";
  src: url("/assets/fonts/SF-Pro-Display-Bold.ttf");
}

@font-face {
  font-family: "SF-Pro-Display-Heavy";
  src: url("/assets/fonts/SF-Pro-Display-Heavy.ttf");
}

@font-face {
  font-family: "SF-Pro-Display-Black";
  src: url("/assets/fonts/SF-Pro-Display-Black.ttf");
}

@font-face {
  font-family: "SF-Pro-Rounded-Semi-Bold";
  src: url("/assets/fonts/SF-Pro-Rounded-Semibold.otf");
}

@primary-font-ultra-light: "SF-Pro-Display-Ultralight";
@primary-font-Thin: "SF-Pro-Display-Thin";
@primary-font-light: "SF-Pro-Display-Light";
@primary-font-regular: "SF-Pro-Display-Regular";
@primary-font-medium: "SF-Pro-Display-Medium";
@primary-font-semi-bold: "SF-Pro-Display-Semi-Bold";
@primary-font-semi-bold-rounded: "SF-Pro-Rounded-Semi-Bold";
@primary-font-bold: "SF-Pro-Display-Bold";
@primary-font-heavy: "SF-Pro-Display-Heavy";
@primary-font-black: "SF-Pro-Display-Black";
