@import url("../../style/index.less");

.authOrDivider {
  .ant-divider-inner-text {
    font-family: @primary-font-bold;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    color: #727272;
  }

  &::before,
  &::after {
    border-block-start-color: #727272 !important;
  }
}
