@import url("../../style/index.less");

.confirm-modal-container {
  .ant-modal-content {
    background: linear-gradient(to top, #050505, #232323) !important;
    border-radius: 27px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 1px;

    .ant-modal-close {
      display: none;
    }
  }

  .modal-content {
    color: @white-color;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 23.87px;

    p {
      margin-bottom: 0px;
    }

    span {
      font-size: 12px;
      font-weight: normal;
    }
  }

  .ant-modal-footer {
    margin-top: 10px;
  }

  .modal-footer-actions {
    justify-content: center;

    .modal-divider {
      background-color: @white-color;
      margin: 15px 0;
      opacity: 0.2;
    }

    .confirm-btn {
      display: flex;
      align-items: center;
      font-family: @primary-font-bold;
      margin-top: 20px;
      color: @black-color;
      font-size: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: center;
      height: 60px;

      .confirm-btn-background {
        font-family: @primary-font-bold;
        background-color: @white-color;
        padding: 10px 12px;
        width: 50%;
        border-radius: 30px;
      }
    }

    .cancel-btn {
      margin-top: 15px;
      margin-bottom: 10px;
      color: @white-color;
      font-size: 16px;
      font-weight: 700;
      width: 100%;
      text-align: center;
    }
  }
}
