.dots-gap {
  gap: 4px !important;
}

.dots-wrapper {
  width: 40px;
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
