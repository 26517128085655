@import url("../../../style/index.less");

.creatorUnlockLinkContentWrapper {
  min-height: 100dvh !important;
  padding: @creator-create-unlock-link-header-height 15px 30px;

  :where(.css-dev-only-do-not-override-ixblex).ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload-list.ant-upload-list-picture-card
    .ant-upload-list-item::before {
    width: calc(100% - 0px);
    height: calc(100% - 0px);
    border-radius: 12px;
  }
}

.unlockLinkSettings {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.divider {
  display: flex;
  width: 100%;
  min-width: 100%;
  margin: 24px 0 10px;
  background-color: @grey-color;
  height: 1px;
}

.divider-input {
  display: flex;
  width: 100%;
  max-width: 930px;
  margin-top: -10px;
  background-color: @grey-color;
  height: 0.7px;
}

.divider-decrypt {
  display: flex;
  width: 100%;
  max-width: 980px;
  background-color: @grey-color;
  height: 0.7px;
}

.unlockLinkPriceWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  .unlockLinkPriceInput {
    margin: 20px 10px;
    font-family: @primary-font-black;
    font-size: 55px;
    width: 100%;
    height: 100%;
    color: @grey-color !important;
    font-weight: 900;
    text-align: center;
    line-height: 60px;

    .ant-input-number-input {
      color: @grey-color !important;
      font-family: @primary-font-black;
      width: 100%;
      height: 100%;
      display: flex;
      text-align: center;
      line-height: 60px;
    }
  }

  .unlockLinkPriceInputCompleted {
    margin: 20px 10px;
    font-family: @primary-font-black;
    font-size: 55px;
    width: 100%;
    height: 100%;
    color: #ffffff !important;
    text-align: center;
    line-height: 60px;

    .ant-input-number-input {
      color: #ffffff !important;
      font-family: @primary-font-black;
      width: 100%;
      height: 100%;
      display: flex;
      text-align: center;
      line-height: 60px;
    }
  }
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: @white-color;
  background-color: @medium-green-color;
  width: 20px;
  height: 20px;

  &:after {
    background-color: @medium-green-color;
  }
}

.ant-radio-wrapper .ant-radio-inner {
  border-color: @light-grey-color;
  background-color: transparent;
  width: 20px;
  height: 20px;

  &:after {
    background-color: transparent;
  }
}

.ant-slider {
  &:hover {
    .ant-slider-track {
      background-color: @medium-green-color;
    }

    .ant-slider-handle {
      &:after {
        background-color: @medium-green-color;
        box-shadow: 0 0 0 2px @medium-green-color;
        width: 12px;
        height: 12px;
      }
    }
  }

  .ant-slider-track {
    background-color: @medium-green-color;
  }

  .ant-slider-handle {
    &:after {
      background-color: @medium-green-color;
      box-shadow: 0 0 0 2px @medium-green-color;
      width: 12px;
      height: 12px;
    }

    &:focus {
      &:after {
        box-shadow: 0 0 0 4px @medium-green-color;
      }
    }
  }
}

.ant-slider.ant-slider-disabled {
  .ant-slider-track {
    background-color: @light-grey-color !important;
  }

  .ant-slider-handle {
    &:after {
      background-color: @light-grey-color;
      box-shadow: 0 0 0 2px @light-grey-color;
      width: 12px;
      height: 12px;
    }

    &:focus {
      &:after {
        box-shadow: 0 0 0 4px @light-grey-color;
      }
    }
  }
}

.infinityWrapper {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0 20px;
}

.generateLinkBtnWrapper {
  margin-top: 100%;
}

.shareDescritpion {
  width: 80%;
  margin: auto;
}

.copyLinkBtn {
  font-family: @primary-font-medium;
  background-color: transparent;
  color: @white-color;
  font-size: 20px;
  width: 175px;
  height: 55px;
}

.headerRow {
  text-align: center;
  margin-top: -50px;
}

.linkImage {
  max-height: 500px;
  max-width: 100%;
  border-radius: 12px;
  object-fit: cover;
}

.container-title {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  text-align: center;
}

.titleInput {
  margin-top: 5px;
  font-family: @primary-font-bold;
  font-size: 48px;
  width: 100%;
  height: 100%;
  color: #ffffff !important;
  font-weight: 900;
  text-align: center;
  line-height: 60px;
}

.textInput {
  margin-top: 5px;
  font-family: @primary-font-regular;
  font-size: 16px;
  width: 100%;
  height: 100%;
  color: #ffffff !important;
  font-weight: 900;
  text-align: center;
  line-height: 60px;
}

.titleInput::placeholder {
  color: #797979;
}

.textInput::placeholder {
  color: #797979;
}

.ant-upload-list-item {
  border: 1px solid white !important;
  padding: 0 !important;
  aspect-ratio: 12/12;
}
