@import url("../../style/index.less");

.oldPrice {
  text-decoration: line-through #959596;
  font-weight: bold;
  color: #959596;
}

.oldPrice-income {
  text-decoration: line-through #959596;
  font-weight: bold;
  color: #959596;
}

.containerDesign {
  padding: 7px 12px;
  border-radius: 14px;
  background-color: rgba(46, 46, 46, 0.45);
  margin-bottom: 5px;
}

.font-12-black {
  font-family: @primary-font-black;
  font-size: 12px;
}
