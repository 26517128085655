@import url("../../../style/index.less");

.emailVerifiedContentWrapper {
  min-height: 100dvh !important;
  padding: 30px;
}

.logo {
  display: flex;
  justify-content: center;
  align-self: center;
}

.title-verified-email {
  font-family: @primary-font-bold;
  font-size: 10vw;
  line-height: 11vw;
  text-align: center;
  color: @white-color;
}

.text {
  font-family: @primary-font-regular;
  color: @white-color;
  font-style: normal;
  font-size: 25px;
  line-height: 24px;
  text-align: center;
}
