@import url("../../../../style/index.less");

.presentation-video-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
  overflow: hidden;

  .presentation-video-modal-content {
    width: 100%;
    max-width: 980px;
    min-height: auto;
    max-height: 90%;
    background-color: #111111;
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
    box-sizing: border-box;
    position: relative;
    animation: slide-up 0.3s ease-out;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .presentation-video-modal-content-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      padding: 20px 20px 0;

      .presentation-video-modal-img {
        object-fit: contain;
        width: 50%;
        margin-bottom: 20px;

        &.w-65-p {
          width: 65%;
        }

        &.w-60-p {
          width: 60%;
        }

        &.w-50-p {
          width: 50%;
        }

        &.w-40-p {
          width: 40%;
        }

        &.m-30 {
          margin-top: 20px;
          margin-bottom: 30px;
        }
      }

      .presentation-video-modal-title {
        font-family: @primary-font-black;
        font-size: 30px;
        color: #ffffff;
        text-transform: uppercase;
        text-align: center;

        &.font-size-28 {
          font-size: 28px;
        }

        &.font-size-26 {
          font-size: 26px;
        }

        &.font-size-24 {
          font-size: 24px;
        }

        &.font-size-22 {
          font-size: 22px;
        }

        &.font-size-20 {
          font-size: 20px;
        }

        &.font-size-18 {
          font-size: 18px;
        }

        &.font-size-16 {
          font-size: 16px;
        }
      }

      .presentation-video-modal-secondary-title {
        font-family: @primary-font-black;
        font-size: 14px;
        color: #ffffff;
        text-transform: uppercase;
      }

      .presentation-video-modal-text-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 20px;
      }

      .presentation-video-modal-text {
        font-family: @primary-font-regular;
        font-size: 13px;
        line-height: 22px;
        color: @grey-color;
        text-align: center;

        .white {
          color: white;

          &.black-font {
            font-family: @primary-font-black;
            text-transform: uppercase;
          }
        }

        &.align-start {
          text-align: start;
        }
      }

      .presentation-video-modal-confirmation-button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        border: none;
        border-radius: 18px;
        width: 100%;
        height: 50px;
        font-family: @primary-font-semi-bold;
        font-size: 16px;
        color: #000000;
        margin-top: 40px;
      }

      .presentation-video-modal-cancel-button {
        font-family: @primary-font-semi-bold;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        margin-top: 15px;
        margin-bottom: 20px;
      }
    }

    .reason-for-refusal-container {
      margin-top: 20px;
      border-radius: 15px;
      background: linear-gradient(180deg, rgba(46, 46, 54, 0.37) 0%, rgba(46, 46, 54, 0) 100%);

      .reason-for-refusal-text-container {
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: start;
      }
    }

    .presentation-video-sticky-button-container {
      position: sticky;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 20px 20px;
      background: linear-gradient(0deg, @black-color 0%, @black-color 25%, #0707061a 89%, #00000000 100%) !important;

      .presentation-video-sticky-button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        border: none;
        border-radius: 18px;
        width: 100%;
        height: 50px;
        font-family: @primary-font-semi-bold;
        font-size: 16px;
        color: #000000;
        margin-top: 40px;
      }

      .sticky-button:hover {
        background-color: #e6e6e6;
      }

      .presentation-video-modal-cancel-button {
        font-family: @primary-font-regular;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        margin-top: 10px;
        cursor: pointer;
      }
    }

    .video-upload-modal-container {
      position: relative;
      width: 100%;
      min-height: 350px;
      max-height: 350px;
      margin-top: 30px;
      padding: 0 35px;

      .ant-upload-wrapper {
        .ant-upload {
          width: 100%;
        }
      }

      .video-upload-rectangle-shape {
        width: 100%;
        min-height: 350px;
        max-height: 350px;
        border: 2px dashed @white-color;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        grid-column: span 1;

        .add-circle-white {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 130px;
          height: 130px;
          padding: 15px;
          background-color: @white-color;
          border-radius: 50%;
        }
      }

      .video-upload-modal-thumbnail {
        object-fit: cover;
        width: 100%;
        min-height: 350px;
        max-height: 350px;
        border-radius: 20px;
      }

      .trash-icon {
        position: absolute;
        width: 20%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.presentation-video-status-container {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  width: 100%;
  background-color: @background-formation-container;

  .presentation-video-status-wrapper {
    position: relative;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;

    .presentation-video-status-thumbnail {
      width: 100%;
      height: 100%;
      border-radius: 15px;
      object-fit: cover;
      display: block;
    }

    // .presentation-video-status-play-icon {
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    //   width: 70px;
    // }
  }
}

.gradient-background-dark-blue {
  background: linear-gradient(359.76deg, #101010 43.39%, #0c0ecb 101.42%);
}
