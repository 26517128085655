@import url("../../../style/index.less");

.creatorImportationContentWrapper {
  min-height: 100dvh !important;
  padding: calc(@creator-importation-header-height + 18px) 21px 30px 22px;

  .mediaWrapper {
    width: 100%;
    max-width: 353px;
    aspect-ratio: 1/1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .mediaTypeRadioButton {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    height: 47px;
    font-family: @primary-font-bold;
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    color: @white-color;
    background-color: @light-black-color;
    border-color: @light-black-color!important;
    border-radius: 25.5px !important;

    &.ant-radio-button-wrapper-checked {
      color: @black-color!important;
      background-color: @medium-green-color!important;
    }

    .ant-radio-button {
      display: flex;
      align-items: center;
      position: relative;
      width: fit-content;
      height: fit-content;
      z-index: 1;

      input {
        width: 21px;
        height: 21px;
        margin: unset;
        opacity: 1;
      }
    }
  }
}
