@import url("../../../style/index.less");

.creatorSponsorshipContentWrapper {
  min-height: 100dvh !important;
  padding: @creator-sponsorship-header-height 15px 120px;
}

.price-container {
  padding: 20px 20px 20px 26px;
  border-radius: 24px;
  background-color: rgba(77, 77, 77, 0.2);
}

.creator-container {
  width: 100%;
  overflow: hidden;
  padding: 8px 10px;
  border-radius: 60px;
  background-color: rgba(77, 77, 77, 0.2);
  height: 120px;
}

.imgLingot {
  width: 340px;
  margin-top: 30px;
  margin-bottom: 50px;
}
.sponsorship {
  margin-top: 10px;
  margin-bottom: 30px;
}
.sponsorshipLink {
  position: relative;
  width: 100%;
}
.sponsorshipContent {
  background-color: transparent !important;
  border: 1px solid rgba(77, 77, 77, 0.4) !important;
  width: 100%;
  border-radius: 20px;
  height: 55px;
  color: @white-color !important;
  font-size: 15px;
  font-family: @primary-font-medium;
  padding-right: 100px;
}

.sponsorshipLinkCopy {
  position: absolute;
  height: 100%;
  background-color: rgba(77, 77, 77, 0.2);
  border: none;
  font-family: @primary-font-bold;
  font-size: 14px;
  color: @white-color !important;
  border-radius: 0px 15px 15px 0px;
  right: 0px;
  top: 0px;
  padding: 0px 20px;
}

.flex-container {
  display: flex;
  align-items: center;
  gap: 14px;
  width: 100%;
  overflow: hidden;
}

.flex-column {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.chevron-right {
  width: 60px;
  height: 60px;
  margin-right: 8px;
  margin-left: auto;
  padding: 20px;
  padding-left: 23px;
  border-radius: 50%;
  background-color: rgba(34, 34, 34, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-name-text {
  max-width: 100%;
  font-family: @primary-font-black;
  font-size: 22px;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.font-36-black {
  font-family: @primary-font-black;
  font-size: 36px;
}

.font-14-light {
  font-family: @primary-font-light;
  font-size: 14px;
}
