@import url("../../style/index.less");
.fanBookmarksContentWrapper {
  min-height: 100dvh !important;
  padding: calc(@creator-codePromotion-header-height) 1px 120px 1px;

  .container-bookmarks {
    position: relative;
    padding: 2px 3px 2px 3px;
    padding-bottom: 0;
  }

  .img-bookmarks {
    object-fit: cover;
    aspect-ratio: 16/16;
    border-radius: 8px;
  }

  .text-bookmarks {
    position: absolute;
    bottom: 3px;
    left: 0;
    width: 100%;
    text-align: center;
    background: linear-gradient(2.27deg, #000000 1.9%, rgba(8, 8, 8, 0.6) 36.53%, rgba(8, 8, 8, 0) 98.1%);
    padding: 5px 10px;
    border-radius: 0 0 7px 7px;
    color: white;
    font-size: 16px;
    font-weight: bold;
  }
}
