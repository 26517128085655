@import url("../../../style/index.less");

.creatorLanguageContentWrapper {
  min-height: 100dvh !important;
  padding: calc(@creator-language-header-height + 20px) 20px 120px 20px;
}

.container-row {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px;
  border-radius: 50px;
  background: rgba(77, 77, 77, 0.4);
  height: 100px;

  .circle {
    width: 80px;
    height: 80px;
    margin-right: 40px;
    margin-left: 5px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &.active {
      background-color: #ffffff !important;
    }
  }

  .text-container {
    flex: 1;
    display: flex;
    align-items: center;

    .text {
      font-family: @primary-font-black;
      font-size: 32px;
      color: rgba(255, 255, 255, 0.8);
      text-align: center;

      &.active {
        color: #ffffff !important;
      }
    }
  }
}
