@import url("../../../../style/index.less");

.event-ticket-wrapper {
  min-height: 100dvh !important;
  display: flex;
  flex-direction: column;
  background-color: #111111;

  .event-img {
    width: auto;
    height: auto;
    object-fit: cover;
  }
}
