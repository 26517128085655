@import url("../../../style/index.less");

.fanCategorySearchContentWrapper {
  min-height: 100dvh !important;
  padding: @fan-search-header-height 0 calc(@footer-height + 10px) 17px;

  .direct-links-wrapper {
    overflow-x: auto;
    width: 100%;
    white-space: nowrap;
    margin-top: 15px;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .direct-links-wrapper::-webkit-scrollbar {
    display: none;
  }

  .category-images-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-right: 17px;

    .image-col {
      margin-bottom: 10px;
      padding: 0 5px !important;
    }

    .image-wrapper {
      position: relative;
      width: 100%;
      height: 27.5vh;
      background-size: cover;
    }

    .categoryItem {
      width: 100%;
      height: 27.5vh;
    }
  }
}

.cancelText {
  text-align: center;
}
.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}
.UserName {
  font-size: 14px;
  color: @white-color;
  font-family: @primary-font-bold;
}
.FirstName {
  font-size: 12px;
  color: #828796;
  font-family: @primary-font-regular;
}

.mx-auto {
  margin-left: auto !important ;
  margin-right: auto !important ;
}
.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
// .px-5 {
//   padding-left: 5px;
//   padding-right: 5px;
// }
.pt-5 {
  padding-top: 5px;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title-category {
  font-family: @primary-font-medium;
  font-size: 3.5vw;
  padding: 2% 8%;
  background: linear-gradient(to right, #ff4b01, #ff26d4);
  color: white;
  border-radius: 60px;
  text-align: center;
}

.description {
  font-family: @primary-font-medium;
  position: absolute;
  bottom: 5px;
  left: 10px;
  color: white;
  font-size: 4vw;
  line-height: 20px !important;
  text-align: left;
  padding-right: 10%;
  line-height: 1;
}

.full-width-title {
  font-size: 4vw;
  padding: 2% 10%;
}

.full-width-description {
  font-size: 5.7vw;
  line-height: 30px !important;
}

@media screen and (min-width: 620px) {
  .title-category {
    font-size: 20px;
    padding: 10px 20px;
  }

  .description {
    font-size: 18px;
  }
}

@media screen and (min-width: 620px) {
  .category-images-container {
    padding: 0 15%;
    justify-content: space-between;
  }

  .image-col {
    width: calc(50% - 15%);
  }

  .image-wrapper {
    height: 40vh !important;
  }

  .categoryItem {
    height: 40vh !important;
  }
}

// @media screen and (min-width: 1000px) {
//   .category-images-container {
//     padding: 0 25%;
//     justify-content: space-between;
//   }

//   .image-col {
//     width: calc(50% - 25%);
//   }
// }
