@import url("../../../style/index.less");

.creatorLiveContentWrapper {
  min-height: 100dvh !important;
  padding: @creator-live-header-height 25px 50px 25px;

  .switch {
    &.ant-switch-checked {
      .ant-switch-handle {
        inset-inline-start: calc(100% - 30px) !important;
      }
    }
  }
}

.targetRadioButtonLive {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 50px;
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 16px;
  line-height: unset;
  color: @white-color;
  background-color: @radio-button-color;
  border: unset;
  border-radius: 20px !important;
  border-inline-start: unset !important;
  text-align: center;
  padding: 6px 11px 6px 11px;

  &.ant-radio-button-wrapper-checked {
    background-color: @medium-green-color !important;
    color: #000000 !important;
  }

  .ant-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 17px;
    font-family: @primary-font-bold;
    font-style: normal;
    font-size: 9px;
    line-height: 11px;
    background-color: @white-color;
    padding: 2px 6px;
    border: unset;
    border-radius: 6px;
    margin-right: unset;
  }

  &.when {
    font-size: 14px !important;
  }
}

.chatButtonLive {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 50px;
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 14px;
  line-height: unset;
  color: @white-color;
  background-color: @radio-button-color;
  border: unset;
  border-radius: 20px !important;
  border-inline-start: unset !important;
  text-align: center;
  padding: 6px 11px 6px 11px;

  &.active {
    background-color: @medium-green-color;
    color: black !important;
    border: none;
  }
}

.validateButton {
  width: 100%;
  height: 60px;
  font-family: @primary-font-bold;
  font-size: 18px !important;
  line-height: 28px;
  color: @black-color;
  background-color: @medium-green-color;
  padding: 12px 15px;
  border: none;
  border-radius: 50px;

  &:hover {
    color: @black-color!important;
    background-color: @medium-green-color;
    border: 1px solid @medium-green-color!important;
  }

  &:active {
    border: 1px solid @medium-green-color!important;
  }
  &:disabled {
    background-color: @grey-color;
    color: @black-color;
    border: none !important;
  }

  @media only screen and (max-width: 525px) {
    font-size: 4vw;
  }
}
