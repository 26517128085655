@import url("../../style/index.less");

.read-more-btn {
  font-family: @primary-font-regular;
  color: #fff;
  font-size: 13px;
  line-height: 15.51px;
  margin-left: 8px;
  cursor: pointer;
}
