@import url("../../style/index.less");

.intro-signin {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -30px;
  overflow: hidden;
}

.content-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.form-content {
  width: 100%;
}

.customInput {
  border: 1px solid @border-input;
  border-radius: 40px;
  color: #ffffff;
  background-color: @background-input !important;
  padding: 8px 30px 8px 30px;
  width: 100%;
  height: 60px;
  font-size: 16px;

  input {
    background-color: transparent !important;
    color: #ffffff !important;
  }

  input::placeholder {
    color: @placeholder;
    text-align: center;
  }

  &.ant-input-affix-wrapper {
    background-color: @background-input !important;
  }
}

.customInput::placeholder {
  color: @placeholder;
  text-align: center;
}

.ant-input-affix-wrapper {
  background-color: transparent !important;
}

.bottom-text-label {
  font-family: @primary-font-medium;
  color: #ffffff;
  margin-right: 5px;
}

.bottom-text-register {
  font-family: @primary-font-medium;
  color: @medium-green-color;
}

.footer-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.authForgotPasswordLabel {
  font-family: @primary-font-regular;
  font-style: normal;
  font-size: 12px;
  color: @grey-dark-color;
}

.authRememberMeCheckbox {
  font-family: @primary-font-regular;
  font-style: normal;
  font-size: 12px;
  color: @grey-dark-color;

  .ant-checkbox-checked {
    background-color: transparent;

    &::after {
      border-color: transparent !important;
    }

    .ant-checkbox-inner {
      background-color: @grey-dark-color !important;
      border-color: transparent !important;
    }
  }

  .ant-checkbox-inner {
    width: 14px;
    height: 14px;
    border-color: #d9d9d9 !important;

    &:hover {
      border-color: #d9d9d9 !important;
    }

    &::after {
      left: 25%;
      width: 3.7142857142857135px;
      height: 8.142857142857142px;
      border-color: @black-color;
    }
  }
}
