@import url("../../style/index.less");

.creatorSavedResponsesModalContent {
  padding: 25px 13px 20px 18px;

  .creatorSavedResponsesList {
    height: 100%;
    padding-right: 7px;
    overflow-y: scroll;

    .creatorSavedResponsesItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 65px;
      margin-right: 10px;
      margin-left: -1px;
      cursor: pointer;
      padding: 10px 15px;
      border-radius: 20px;
      background-color: #1f1f1f;

      .ellipsis-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1;
      }
    }

    .chevron-icon-wrapper {
      display: flex;
      align-items: center;
    }
  }

  .no-saved-responde-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-right: 7px;
    margin-bottom: 20px;
    overflow-y: scroll;

    .no-saved-responde-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 0;
      width: 100%;
      background: #4d4d4d66;
      border-radius: 20px;
      margin-bottom: 20px;

      .no-saved-responde-text {
        font-family: @primary-font-semi-bold;
        color: #ffffff;
        font-size: 14px;
      }
    }
  }
}
