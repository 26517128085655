@import url("../../../style/index.less");

.creatorEditProfileContent {
  min-height: 100dvh !important;
  padding: @creator-edit-profile-header-height 0 110px 0;

  .editCoverButton {
    background-color: #eee;
    border-color: #eee;
    border-radius: 9px;
    padding: 0 11px;
    color: #777;
  }

  .editProfileCoverWrapper {
    position: relative;
    width: 100%;
    height: 160px;
    background-color: @medium-green-color;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .editProfileCoverUploadWrapper {
      position: absolute;
      right: 15px;
      top: 15px;
    }

    .editProfileCoverUploadContent {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
      background-color: @white-color;
      border-radius: 50%;
      z-index: 20;
    }
  }

  .editProfileAvatarWrapper {
    position: relative;

    .editProfileAvatarContent {
      width: 120px;
      height: 120px;
      background-color: @white-color;
      padding: 4px;
      border-radius: 50%;

      .editProfileAvatarImage {
        width: 100%;
        height: 100%;
        background-color: @light-black-color;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .editProfileAvatarUploadContent {
      position: absolute;
      top: 5px;
      right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
      background-color: @white-color;
      border-radius: 50%;
      z-index: 20;
    }
  }
}

.vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.editInput {
  height: 45px;
  color: @white-color;
  background-color: rgba(77, 77, 77, 0.4) !important;
  padding: 7px 8px 8px 20px;
  border-radius: 15px;
  border: none !important;
  box-shadow: unset !important;

  &::placeholder {
    color: @grey-color;
  }

  &:disabled {
    color: @grey-color;
  }

  .ant-input {
    color: @white-color;
    background-color: #212121 !important;

    &::placeholder {
      color: @grey-color;
    }

    &:disabled {
      color: @grey-color;
    }
  }

  .ant-input-suffix {
    color: @grey-color;
  }
}

.bioEditTextArea {
  height: 106px !important;
  color: @white-color;
  background-color: rgba(77, 77, 77, 0.4) !important;
  border: none !important;
  border-radius: 15px;
  padding: 10px 15px;

  &:disabled {
    color: @grey-color;
  }
}

.registerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: @primary-font-bold;
  height: 50px;
  width: 100%;
  color: @black-color;
  background: @white-color;
  border: unset;
  border-radius: 30px;
  font-size: 18px;

  &.disabled {
    background: @grey-color;
  }

  &.big {
    font-size: 18px;
    padding: 12px;
  }

  &:active,
  &:hover {
    color: @black-color !important;
  }
}

.editDarkDatePicker {
  background-color: rgba(77, 77, 77, 0.4) !important;
  padding: 7px 8px 8px 23px;

  .ant-picker-input {
    input {
      color: @white-color;

      &::placeholder {
        color: @grey-color;
      }
    }
  }
}
