@import url("../../../../style/index.less");

.slide-bottom-overlay-fan-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding-inline: 15px;
  width: 100%;
  gap: 10px;
  z-index: 1000;
  background: linear-gradient(0deg, @black-color 0%, @black-color 25%, #0707061a 89%, #00000000 100%) !important;
}
