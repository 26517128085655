@import "../../style/index.less";

.username-text {
  font-family: @primary-font-regular;
  font-size: 11px;
  color: #ffffff;
  align-self: center;
  margin-right: 5px;

  &.fan-text {
    font-size: 9px;
  }
}

.price-text {
  font-family: @primary-font-black;
  font-size: 11px;
  color: #ffffff;
  align-self: center;

  &.fan-text {
    font-size: 9px;
  }
}

.tip-amount {
  background-color: #ffffff;
  align-self: center;
  padding: 5px;
  border-radius: 10px;
  text-align: center;
}

.avatarLiveTipWrap {
  padding: 2px;
}

.live-tips-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
