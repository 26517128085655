@import url("../../style/index.less");

.revenue-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;

  .icon-date-increase-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .increase-icon {
      margin-left: -5px;
      width: 45px;

      &.collection {
        margin-left: 0px !important;
      }
    }

    .date-increase-container {
      display: flex;
      flex-direction: column;
      margin-left: 15px;

      .daily-monthly-revenue-text {
        font-family: @primary-font-Thin;
        font-size: 10px;
        color: #9ba1b3;
        margin-top: 5px;

        .increased {
          font-family: @primary-font-regular;
          color: @medium-green-color;
          margin-right: 3px;
        }

        .decreased {
          font-family: @primary-font-regular;
          color: #fecc4b;
          margin-right: 3px;
        }
      }
    }
  }
}

.revenueTag {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 19px;
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 9px;
  line-height: 10px;
  padding: 0 7px;
  border: unset;
  border-radius: 15.5px;

  &.increased {
    color: @black-color;
    background-color: @white-color;
  }

  &.decreased {
    color: @white-color;
    background-color: @light-black-color;
  }
}
