@import url("../../../style/index.less");

.PaymentSuccess {
  height: 100vh;
  width: 100%;
  background: @white-color;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: @dark-green-color !important;

  .payment-title{
    color: @white-color;
    max-width: 350px !important;
    text-wrap: wrap;
  }

  :where(.css-dev-only-do-not-override-ixblex).ant-spin-lg .ant-spin-dot i {
    width: 35px;
    height: 35px;
  }

  :where(.css-dev-only-do-not-override-ixblex).ant-spin-lg .ant-spin-dot {
    font-size: 52px;
  }

  .ant-spin-text{
    font-size: 14px;
  }

  .ant-spin svg{
    fill: @dark-green-color;
  }
}

.modalFanPayment .fanPaymentPopUp{
  justify-content: center;
}