@import url("../../../style/index.less");

.creatorSubscriptionPageContentWrapper {
  min-height: 100dvh !important;
  padding: calc(@creator-codePromotion-header-height) 25px 20px 25px;
  .justify-between {
    justify-content: space-between;
  }
  .creatorSubscriptionPageAvatarImg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
  .creatorSubscriptionCreatorName {
    font-weight: 500;
    font-size: 18px;
    color: @white-color;
    font-family: @primary-font-regular;
  }
  .creatorSubscriptionStatus {
    font-size: 11px;
    font-family: @primary-font-regular;
    color: @grey-color;
  }
  .creatorSubscriptionAssetButton {
    width: 57px;
    height: 24px;
    background-color: @white-color;
    box-shadow: 0px 1px 3px 0px #00000026;
    color: @black-color !important;
    font-size: 12px;
    font-family: @primary-font-bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .opacity-7 {
    opacity: 0.7;
  }
  .mb-5 {
    margin-bottom: 5px;
  }
}
.creatorSubscriptionPageModel {
  max-width: 308px;

  .ant-modal-content {
    background-color: @light-black-color-1;
    padding: 16px 0 0 0;

    .ant-modal-header {
      background-color: @light-black-color-1;
      padding: 0 20px;
      margin-bottom: 0;

      .ant-modal-title {
        font-family: @primary-font-bold;
        font-size: 25px;
        line-height: 30px;
        text-align: center;
        color: @white-color;
      }
    }
  }
  .justify-center {
    justify-content: center;
  }
  .ant-modal-footer {
    text-align: center;
    margin-top: 0;
    .creatorSubscriptionValid {
      font-family: @primary-font-bold;
      font-size: 20px;
      line-height: 23.87px;
      text-align: center;
      color: @white-color;
      display: inline-block;
      margin-top: 10px;
    }
    .creatorSubscriptionStatus {
      font-family: @primary-font-regular;
      font-size: 16px;
      line-height: 19.09px;
      text-align: center;
      color: @grey-color;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .creatorSubscriptionValidStatus {
      font-family: @primary-font-regular;
      font-size: 16px;
      line-height: 19.09px;
      text-align: center;
      color: @grey-color;
      margin-top: 5px;
      margin-bottom: 10px;
      padding: 0 20px;
    }
    .row {
      width: 100%;
      justify-content: center;
      padding: 15px 0;
      border-top: 1px solid @grey-color;

      .closeBtn {
        font-family: @primary-font-regular;
        font-style: normal;
        font-size: 18px;
        line-height: 21px;
        color: @white-color;
      }

      .CancelBtn {
        font-family: @primary-font-bold;
        font-style: normal;
        font-size: 20px;
        line-height: 24px;
        color: @red-color;
      }
      .SubscriptionExtendBtn {
        font-family: @primary-font-bold;
        font-style: normal;
        font-size: 20px;
        line-height: 24px;
        color: @medium-green-color;
      }
    }
  }
}
