@import url("../../style/index.less");

.SaveCardContainer,
.SavePageContainer {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  overflow-x: scroll;
  gap: 1rem;
  padding: 0px 23px 0px 23px;
  color: @white-color;
  height: 200px;

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;

  .text-white {
    color: @white-color;
  }

  .ant-card {
    border: none;
    background: url("../../assets/images/grey-card.webp") no-repeat center center;
    background-size: cover;
    width: 90%;
    min-width: 300px;
    max-width: 300px;
    height: auto;
    min-height: 180px;
  }

  .ant-card-body {
    padding: 15px;
  }

  .d-flex {
    display: flex !important;
  }

  .justify-between {
    justify-content: space-between;
  }

  .align-center {
    align-items: center;
  }

  .flex-column {
    flex-direction: column !important;
  }

  .min-w-100 {
    min-width: 100% !important;
  }

  .border-none {
    border: unset;

    &:hover {
      color: unset;
    }
  }

  .background-transparent {
    &:hover {
      color: @white-color;
      border: 1px solid @white-color;
    }
  }

  .ant-btn {
    font-size: 10px;
    border-radius: 12px;
    padding: 0 12px;
  }
}

.select-card-button {
  display: flex;
  align-items: center;
  font-family: @primary-font-light;
  font-size: 12px !important;
  background-color: transparent;
  color: #ffffff !important;
  border: 1px solid #ffffff;
  border-radius: 15px;
  padding: 6px 15px;
  height: 28px;

  &:hover {
    outline: none !important; /* Supprimer le contour de focus */
    box-shadow: none !important;
    color: @white-color !important;
    border: 1px solid @white-color !important;
  }

  &.unselect {
    font-family: @primary-font-medium;
    background-color: #ffffff;
    color: #000000 !important;
  }
}

.selected-card {
  position: relative;
  border: 1px solid #ffffff;
  padding: 2px;
  border-radius: 10px;
}

.holder-name-card {
  font-family: @primary-font-bold;
  font-size: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
