@import url("../../style/index.less");

.authHeader {
  font-family: @primary-font-black;
  font-size: 14vw;
  line-height: 14vw;
  text-align: center;
  color: @white-color;
}

.padding-container {
  padding: 15px 30px;
}

.authSelectTypeHeader {
  font-size: 31px !important;
  line-height: 38px !important;
}

.authBillingHeader {
  font-size: 40px !important;
  line-height: 38px !important;
  margin-left: 35px;
}

.authVerifyIdentity {
  font-size: 32px !important;
  line-height: 32px !important;
}

.authSelectTypeDescription {
  font-family: @primary-font-regular;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  color: @grey-color;
  text-align: center;
}

.authTypeTag {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 12px;
  line-height: 20px;
  color: @grey-color;
  background-color: transparent;
  padding: 12px 13px 11px 13px;
  border: 2px solid @grey-color;
  border-radius: 23.5px;
}

.authSelectedTypeTag {
  color: @black-color;
  background-color: @medium-green-color;
}

.authRevenueHeader {
  text-wrap: nowrap;
  font-size: 28px !important;
  line-height: 32px !important;
}

.authStatusHeader {
  text-wrap: nowrap;
  font-size: 50px !important;
  line-height: 56px !important;
}

.authRevenueItemTitle {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
  color: @white-color;
}

.authRevenueItemImportantTitle {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 16px;
  line-height: 18.75px;
  color: @red-color;
}

.divider-identity {
  display: flex;
  width: 100%;
  min-width: 100%;
  margin: 24px 0 10px;
  background-color: @grey-dark-color;
  height: 1px;
}

.authRevenueItemSubTitle {
  font-family: @primary-font-regular;
  font-style: normal;
  font-size: 13px;
  line-height: 15px;
  color: @grey-dark-color;
}

.authRevenueInput {
  max-width: 94px;
  text-align: center;
}

.authRevenueInput::placeholder {
  color: #535353;
}

.authSwitch {
  background-color: @light-grey-color;

  &.monetizationSwitch {
    width: 75px;
    height: 40px;

    .ant-switch-handle {
      top: 5px;
      width: 30px;
      height: 30px;
      left: 2.5px;

      &::before {
        border-radius: 50%;
      }
    }

    &.ant-switch-checked .ant-switch-handle {
      left: 42.5px;
    }
  }
}

.container-button {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  text-align: center;
}

.btn-status {
  font-family: @primary-font-bold;
  width: 100%;
  background-color: transparent;
  color: #ffffff;
  height: 65px;
  border-radius: 40px;
  border-color: #696969;
  font-size: 16px;

  &.live {
    border-radius: 18px !important;
  }

  &.active {
    background-color: @medium-green-color;
    color: black !important;
    border: none;
  }
}

.bottom-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 40px;
}

.authIdUploadWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  background-color: @medium-grey-color;
  border-radius: 50%;

  .authIdUploadCameraIcon {
    position: absolute;
    right: 7px;
    bottom: 0;
    width: 46px;
    height: 46px;
  }

  .ant-upload-list {
    position: absolute;
    left: 0;
    bottom: -23px;
  }
}

.authCompletedTitle {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 26px;
  line-height: 33px;
  color: #939393;
}

.authCompletedTitleSpan {
  color: #ffffff !important;
}

.authCompletedContent {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  color: #939393;
}

.text-size {
  font-size: 14px !important;
}
.switchcolor {
  background-color: @medium-green-color !important;
}

.authPhoneDesign {
  height: 45px;
  background-color: @background-input;
  border: 1px solid @border-input;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  &::placeholder {
    color: @placeholder;
  }
}

.select-billing {
  width: 100%;

  &.dark {
    .ant-select-selector {
      height: 36px;
      background: #1c1c1c;
      border-radius: 6px;
      border: 1px solid #646464;

      .ant-select-selection-search {
        color: @white-color;
      }

      .ant-select-selection-item {
        display: flex;
        align-items: center;
        font-family: @primary-font-regular;
        font-style: normal;
        font-size: 15px;
        line-height: 18px;
        color: @white-color;
      }
    }

    .ant-select-arrow {
      color: @white-color;
    }
  }
}

.auth-thanks-page-wrapper {
  height: 100%;
  background-color: #0f0f10;

  .thanks-img {
    width: 100%;
    object-fit: contain;
    margin-top: -50px;
  }

  .auth-thanks-page-container {
    padding: 10px 20px;
    margin-top: -70px;

    .auth-thanks-page-title {
      font-family: @primary-font-black;
      font-size: 27px;
      color: #ffffff;
    }

    .auth-thanks-page-desc {
      font-family: @primary-font-medium;
      font-size: 15px;
      color: #ffffff;
    }

    .email-highlight {
      color: #709aff;
    }
  }
}

.minimum-price-text {
  font-family: @primary-font-regular;
  font-size: 12px;
  color: #ffd230;
  text-align: center;
}
