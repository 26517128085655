@import url("../../../style/index.less");

.fanLiveContentWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //width: 100vw;
  height: 100dvh;
  background: @background-color;
  overflow: hidden;
  //padding: 17px 17px 24px 11px;

  .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }

  .wide {
    max-width: 150px;
  }

  .narrow {
    max-width: 90px;
  }

  .fan-live-header {
    font-size: clamp(14px, 5vw, 20px) !important;
  }

  .fanLiveVerifyPaymentContent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #292929;

    .fanLiveVerifyPaymentContentWrapper {
      position: relative;
      max-width: 80%;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: auto;
    }
  }
}

.live-watermark {
  width: 100% !important;
  height: 100% !important;
  pointer-events: none;
  z-index: 1000;
}

.header-container-left-live {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 7px 2px 3px;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.3);

  .fullname {
    font-family: @primary-font-semi-bold;
    font-size: clamp(13px, 5vw, 15px) !important;
    color: #ffffff;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    margin-left: 5px;
    margin-right: 3px;
  }

  .follow-button {
    display: flex;
    align-items: center;
    font-family: @primary-font-semi-bold;
    font-size: 12px;
    color: #000000;
    background-color: #ffffff;
    padding: 3px 10px;
    border-radius: 15px;
    height: 25px;
    border: none;
    margin-left: 10px;

    &:active,
    &:hover {
      color: #000000 !important;
      border: none !important;
    }
  }
}

.tips-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
  border-radius: 20px;
  border: 2px solid #ff03a9;
  background: linear-gradient(101.52deg, #0d0d0d 3.31%, #0c071e 50.09%, #2b1487 98.79%);
}

// @headerHeight: 79px;
// @fullHeight: 100dvh;

// .video-player {
//   max-width: 100vw !important;
//   height: calc(@fullHeight - @headerHeight);
// }

.fan-footer-right-container {
  display: flex;
  align-items: center;

  .gift-icon {
    margin-left: 10px;
    width: 25px;
  }
}
