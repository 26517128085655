@import url("../../style/index.less");

.reportFeedThreeDotsModal {
  max-width: 308px;

  .ant-modal-content {
    background: linear-gradient(to top, #050505, #232323) !important;

    padding: 16px 0 0 0;
    border-radius: 27px;

    .ant-modal-header {
      background-color: transparent;
      padding: 0 20px;
      margin-bottom: 0;

      .ant-modal-title {
        font-family: @primary-font-bold;
        font-size: 20px;
        line-height: 30px;
        min-height: 80px;
        text-align: center;
        color: @white-color;
      }
    }
  }

  .ant-modal-footer {
    text-align: center;
    margin-top: -20px;

    .row {
      width: 100%;
      justify-content: center;
      padding: 15px 0;

      .actionBtn {
        font-family: @primary-font-regular;
        font-style: normal;
        font-size: 18px;
        line-height: 21px;
        color: @black-color;
        height: 100%;
        margin-bottom: -20px;

        .confirm-btn-background {
          font-family: @primary-font-bold;
          background-color: @white-color;
          padding: 10px 35px;
          border-radius: 20px;
        }
      }

      .rejectBtn {
        font-family: @primary-font-bold;
        font-style: normal;
        font-size: 18px;
        line-height: 24px;
        color: @white-color;
      }
    }
  }
}
