@import url("../../style/index.less");

.bioTextArea {
  height: 106px !important;
  color: @white-color;
  background-color: transparent;
  border-color: @white-color!important;

  &:disabled {
    color: @grey-color;
  }
}
