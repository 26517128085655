@import url("../../../../style/index.less");

.collection-detail-modal {
  max-width: 350px;

  .ant-modal-content {
    background: linear-gradient(to top, #050505, #232323) !important;
    padding: 16px 0 0 0;
    border-radius: 25px;

    .ant-modal-header {
      background-color: transparent;
      padding: 10px 8px 0 8px;
      margin-bottom: 0;

      .ant-modal-title {
        font-family: @primary-font-bold;
        font-size: 28px;
        line-height: 38px;
        text-align: center;
        color: @white-color;
      }
    }

    .ant-modal-body {
      padding: 16px 0px 16px 0px;

      .actionBtn {
        display: flex;
        justify-content: center;
        font-family: @primary-font-regular;
        font-style: normal;
        font-size: 18px;
        line-height: 21px;
        color: @white-color;
        height: 100%;

        .confirm-btn-background {
          width: 65%;
          font-family: @primary-font-medium;
          background-color: transparent;
          border: 1px solid #ffffff;
          padding: 20px 25px;
          border-radius: 30px;
        }
      }
    }
  }

  .ant-modal-footer {
    text-align: center;
    margin-top: 0;
    width: 100%;
    justify-content: center;
    padding: 15px 0;

    .cancel-btn {
      font-family: @primary-font-bold;
      font-size: 18px;
      line-height: 24px;
      margin-top: -15px;
      font-size: 18px;
      color: @white-color;
    }
  }
}
