@import url("../../../../style/index.less");

.header-info-discover-container {
  width: 100%;
  height: 45px;
  padding-inline: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #667dff;

  .header-info-discover-text {
    font-family: @primary-font-black;
    font-size: 0.8rem;
    text-transform: uppercase;
    color: #ffffff;
  }

  .header-info-discover-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 5px 12px;
    background: #ffffff1c;
    gap: 5px;
    margin-left: auto;

    .header-info-discover-button-text {
      font-family: @primary-font-semi-bold;
      font-size: 12px;
      color: #ffffff;
    }

    .to-icon {
      width: 12px;
      margin-left: 5px;
    }
  }
}
