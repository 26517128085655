@import url("../../style/index.less");

.messageClockWrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0;
  margin-right: 5px;
  height: 100%;
  width: auto;
  align-self: center;
}

.messageClockLabel {
  font-family: @primary-font-semi-bold;
  color: #fff;
  font-size: 14px;

  &.expired {
    color: #ff0000;
    font-size: 13px;
  }
}
