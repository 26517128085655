@import url("../../../style/index.less");

.creatorPerformanceContentWrapper {
  min-height: 100dvh !important;
  padding: calc(@creator-performance-header-height + 20px) 20px 120px 20px;
}

.price-component {
  align-items: center;
}

.centered-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: -50px;
}

.centered-image img {
  max-width: 60%;
}
