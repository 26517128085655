@import url("../../../style/index.less");

.fanSubscribeContentWrap {
  min-height: 100dvh;
}

.fanPaymentTitle {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 30px;
  line-height: 36px;
  color: @white-color;
}

.fanPaymentRecapLabel {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  color: @white-color;
}

.fanPaymentLargeLabel {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: @white-color;
}

.fanPaymentMiddleLabel {
  font-family: @primary-font-regular;
  font-style: normal;
  font-size: 15px;
  line-height: 18px;
  color: @white-color;

  &.tva {
    color: @grey-color;
    font-size: 12px;
    line-height: 14px;
  }
}

.fanPaymentSmallLabel {
  font-family: @primary-font-regular;
  font-style: normal;
  font-size: 13px;
  line-height: 16px;
  color: @grey-color;
}

.fanPaymentCodeBtnSuffix {
  font-family: @primary-font-bold;
  font-style: normal;
  font-size: 15px;
  line-height: 18px;
  color: @medium-green-color;
  background-color: transparent !important;
  padding: 0px;
  border: none;
}

.fanPaymentCodeBtnSuffix:hover,
.fanPaymentCodeBtnSuffix:focus,
.fanPaymentCodeBtnSuffix:active {
  color: @medium-green-color !important;
  background-color: transparent !important;
  box-shadow: none;
  outline: none;
}

.fanPaymentMainContent {
  padding: 0 22px 20px 23px;
}

.paymentTermsCheckBox {
  font-family: @primary-font-regular;
  font-style: normal;
  font-size: 12px;
  color: @grey-dark-color;

  .ant-checkbox-checked {
    background-color: transparent;

    &::after {
      border-color: transparent !important;
    }

    .ant-checkbox-inner {
      background-color: @grey-dark-color !important;
      border-color: transparent !important;
    }
  }

  .ant-checkbox-inner {
    width: 14px;
    height: 14px;
    border-color: #d9d9d9 !important;

    &:hover {
      border-color: #d9d9d9 !important;
    }

    &::after {
      left: 25%;
      width: 3.7142857142857135px;
      height: 8.142857142857142px;
      border-color: #ffffff;
    }
  }
}

.FanpaymentCvvInput {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.custom-col:first-child {
  padding-right: 5px;
}

.custom-col:last-child {
  padding-left: 5px;
}

.StripeElement {
  border: 1px solid @border-input !important;
  border-radius: 6px;
  padding: 12px;
}

.form-control {
  padding: 10px;
  border: 1px solid @border-input !important;
  width: 100% !important;
  border-radius: 10px !important;
  color: white !important;
}

.form-control .__privateShift4Component iframe {
  font-family: @primary-font-regular !important;
  color: #ffffff !important;
  height: 45px !important;
  background: @background-input !important;
  border-radius: 6px !important;
}

.info-company-text {
  font-family: @primary-font-regular;
  font-size: 8px;
  color: @grey-dark-color !important;
}

.transaction-text {
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: @primary-font-regular;
  color: @grey-dark-color !important;
  font-size: 9px;
  text-align: center;
}

.authBtn {
  width: 100%;
  max-width: 930px;
  height: fit-content;
  font-family: @primary-font-bold;
  font-size: 16px;
  line-height: 28px;
  color: @black-color;
  background-color: @medium-green-color;
  padding: 12px 15px;
  border: 1px solid @medium-green-color;
  border-radius: 50px;

  &:hover {
    color: @black-color!important;
    background-color: @medium-green-color;
    border: 1px solid @medium-green-color!important;
  }

  &:active {
    border: 1px solid @medium-green-color!important;
  }
  &:disabled {
    background-color: @grey-color;
    color: @black-color;
    border-color: unset;
  }

  @media only screen and (max-width: 525px) {
    font-size: 4vw;
  }
}
