@import "../../style/index.less";

.swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet {
  display: none;
}

// .swiper-home {
//top: calc(0vh - 102px);
// }

.ptr {
  overflow: visible !important;
  height: 100%;
  padding-bottom: @footer-height;
}

.m-negativ-50 {
  margin-bottom: -50px;
}

/* BeatLoader.css */
.beat-loader {
  display: inline-block;
  position: relative;
  width: 40px; /* Decrease width to reduce spacing */
  height: 40px; /* Decrease height to reduce spacing */
}

.beat-loader div {
  position: absolute;
  width: 10px; /* Smaller dot size */
  height: 10px; /* Smaller dot size */
  border-radius: 50%;
  background: rgb(98, 98, 98);
  animation: beat 1.4s infinite ease-in-out;
}

.beat-loader div:nth-child(1) {
  left: 6px; /* Adjusted position */
  animation-delay: -0.32s;
}

.beat-loader div:nth-child(2) {
  left: 22px; /* Adjusted position */
  animation-delay: -0.16s;
}

.beat-loader div:nth-child(3) {
  left: 36px; /* Adjusted position */
}

.next-page-loading {
  display: flex;
  justify-content: center;
  margin-top: -40px;
  margin-bottom: 60px;
}

@keyframes beat {
  0%,
  100% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(1);
  }
}
