@import url("../../../../../style/index.less");

.presentation-video-message-input {
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  padding: 5px 15px 5px 25px;
  background-color: #222222;
  border: none !important;
  border-radius: 15px;
  margin-bottom: 15px;

  input {
    font-family: @primary-font-regular;
    font-size: 16px;
    line-height: 30px;
    color: @white-color;
    background: transparent;
    border: none;
    outline: none;
    width: 100%;
    padding-right: 5px;

    &::placeholder {
      color: @placeholder;
    }

    &:focus {
      outline: none;
      border: none;
    }
  }

  .rc-input-suffix {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    flex-grow: 1;
    width: auto;
    white-space: nowrap;
    cursor: pointer;

    img {
      width: 20px;
      height: auto;
    }

    .message-send-label {
      font-family: @primary-font-bold;
      font-style: normal;
      font-size: 16px;
      line-height: 19px;
      color: @medium-green-color;
      width: auto;
    }
  }
}

.presentation-video-message-button {
  display: flex;
  align-items: center;
  justify-content: start;
  text-align: start !important;
  height: 45px;
  width: 100%;
  background-color: #222222;
  border: none !important;
  border-radius: 15px;
  margin-bottom: 15px;

  span {
    font-family: @primary-font-regular;
    font-size: 14px;
    line-height: 30px;
    color: @placeholder;
    background: transparent;
    border: none;
    outline: none;
    width: 100%;
  }
}
