@import url("../../style/index");

.rtmp-jvs {
  .video-js {
    position: absolute;
    left: 0;
    top: 0;
    z-index: @agora-video-player-z-index;
  
    .vjs-tech {
      transform: rotateY(0deg);
      -webkit-transform: rotateY(0deg);
      -moz-transform: rotateY(0deg);
    }
    
    &.blur {
      filter: blur(18px);
    }
  }
}