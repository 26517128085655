@import url("../../../../style/index.less");

.creator-edit-collection-page-wrapper {
  min-height: 100dvh !important;
  padding: 20px 15px;

  .edit-collection-item-container {
    display: flex;
    align-items: flex-start;
    border-radius: 15px;
    background-color: #161618;
    padding: 15px;
    cursor: pointer;

    .edit-collection-item-icon {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }

    .edit-collection-item-right-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .edit-collection-text-and-chevron-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .edit-collection-item-text-container {
          display: flex;
          flex-direction: column;

          .edit-collection-item-text-title {
            font-family: @primary-font-medium;
            font-size: 14px;
            color: #ffffff;
          }

          .edit-collection-item-text-description {
            font-family: @primary-font-regular;
            font-size: 12px;
            color: #8a8a8a;
          }
        }

        .edit-collection-chevron-icon {
          width: 15px;
          height: 15px;
        }
      }
    }

    .edit-collection-input-container {
      margin-top: 20px;
      width: 100%;

      .edit-collection-item-input-title {
        font-family: @primary-font-medium;
        font-size: 17px;
        color: @grey-color;
      }

      .input-and-button-container {
        display: flex;
        align-items: center;
        gap: 10px;

        .edit-collection-title-input {
          font-family: @primary-font-bold;
          font-size: 38px;
          color: #ffffff;
          border: none;
          border-bottom: 1px solid @grey-color;
          padding: 0;
          background-color: transparent;
          border-radius: 0;
          margin-top: 15px;

          &::placeholder {
            color: @grey-color;
          }

          &:focus {
            box-shadow: none;
            border-bottom: 1px solid @grey-color;
          }
        }

        .confirm-input-button-container {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #ffffff;
          border-radius: 10px;
          border: none;
          padding: 6px 15px;
          cursor: pointer;
          font-family: @primary-font-medium;
          font-size: 12px;
          color: #000000;
          margin-left: auto;
        }
      }
    }
  }
}
