@import url("../../style/index.less");

.paymentDarkBtn {
  height: 43px;
  color: @grey-color;
  background: #1c1c1c;
  border-color: #646464;

  &::placeholder {
    color: @grey-color;
  }

  &:hover {
    border-color: @light-black-color!important;
    box-shadow: unset;
  }

  .ant-input {
    background: #1c1c1c;
    color: @grey-color;

    &::placeholder {
      color: @grey-color;
    }
  }
}

.liveCommentInput {
  display: flex;
  align-items: center;
  font-family: @primary-font-regular;
  width: 100%;
  height: 40px;
  color: @lighter-grey-color;
  background: rgba(0, 0, 0, 0.3);
  border: unset;
  border-radius: 23.5px;
  padding-left: 20px;
  font-size: 15px;
}

.liveCommentInput.ant-input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.liveCommentInput.ant-input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.liveCommentInputSendBtn {
  width: 15%;
  height: 40px;
  color: @lighter-grey-color;
  background: rgba(0, 0, 0, 0.3);
  border: unset;
  border-radius: 0 23.5px 23.5px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.liveCommentInput::placeholder {
  color: @grey-color;
}

.liveCommentInput::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #eaeaea;
}

.darkInput {
  font-family: @primary-font-medium;
  color: #ffffff;
  height: 45px;
  background: @background-input !important;
  border-radius: 6px;
  border: 1px solid @border-input;

  &.ant-input-affix-wrapper {
    background: @background-input !important;
  }

  &.mx-w-94 {
    max-width: 94px;
  }

  &::placeholder {
    color: @placeholder;
  }

  &:disabled {
    color: @grey-color;
    background: @background-input !important;
  }

  .ant-input {
    color: @white-color;
    background: @background-input !important;

    &::placeholder {
      color: @placeholder;
    }

    &:disabled {
      color: @grey-color;
      background: @background-input !important;
    }
  }

  .ant-input-suffix {
    color: @placeholder;
  }

  &.rounded-16 {
    border-radius: 16px !important;

    &.with-border {
      border-color: @border-input !important;
    }

    &.without-border {
      border: none !important;
    }
  }

  &.createPromoCode {
    border: none !important;
    border-radius: 20px !important;
    height: 50px !important;
  }
}

.mediumGreenInput {
  font-family: @primary-font-medium;
  height: 36px;
  color: @black-color;
  background-color: @medium-green-color !important;
  padding: 7px 8px 8px 23px;
  border: unset;
  box-shadow: unset !important;

  &.ant-input-affix-wrapper {
    background-color: @medium-green-color !important;
  }

  &.createPromoCode {
    font-size: 16px !important;
    border: none !important;
    border-radius: 20px !important;
    height: 50px !important;
  }

  &.mx-w-94 {
    max-width: 94px;
  }

  &::placeholder {
    color: @black-color;
  }

  .ant-input {
    font-family: @primary-font-medium;
    color: @black-color;
    background-color: @medium-green-color !important;

    &::placeholder {
      color: @black-color;
    }
  }

  .ant-input-suffix {
    color: @black-color;
  }

  &.rounded-16 {
    border-radius: 16px !important;

    &.with-border {
      border-color: @border-input !important;
    }

    &.without-border {
      border: none !important;
    }
  }
}

.messageInput {
  font-family: @primary-font-regular !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  background: transparent;
  padding: 5px 10px 5px 20px;
  border-color: @grey-color!important;
  border-radius: 21.5px;
  color: @white-color;

  &::placeholder {
    color: @grey-color;
  }
}

.ant-input-password-icon {
  color: @placeholder !important;
  margin-right: 10px;
  margin-left: -5px;
}

&.input-error {
  border: 1px solid #ff6600;
}
