@import url("../../style/index.less");

.unlockLinkInfoModalModal {
  max-width: 328px;

  .ant-modal-content {
    background: linear-gradient(to top, #050505, #232323) !important;
    padding: 30px 20px;
    border-radius: 25px;

    .ant-modal-header {
      background-color: transparent;
      padding: 0 20px;
      margin-bottom: 0;

      .ant-modal-title {
        font-family: @primary-font-bold;
        font-size: 36px;
        line-height: 30px;
        text-align: center;
        color: @white-color;
      }
    }

    .ant-modal-body {
      p {
        color: @white-color;
        padding: 0 28px;
        text-align: center;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  .ant-modal-footer {
    text-align: center;
    margin-top: 0;

    .row {
      width: 100%;
      justify-content: center;
      padding: 15px 0;

      .actionBtn {
        font-family: @primary-font-regular;
        font-style: normal;
        font-size: 18px;
        line-height: 21px;
        color: @white-color;
        height: 100%;
      }

      .confirmBtn {
        font-family: @primary-font-medium;
        font-style: normal;
        font-size: 20px;
        line-height: 24px;
        color: @black-color;

        .confirm-btn-background {
          font-family: @primary-font-bold;
          background-color: @white-color;
          padding: 10px 35px;
          border-radius: 20px;
        }
      }
    }
  }
}

.unlock-link-info-modal-message {
  display: flex;
  font-family: @primary-font-regular;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
}
