@import url("../../../style/index.less");

.unlockLinkContentWrapper {
  min-height: 100dvh !important;
  padding: @creator-unlock-link-header-height 10px 30px 10px;
}

.gradient-background-decrypt {
  background: linear-gradient(to top, #101010 75%, #2946ab) !important;
}

.unlockLinkWrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;

  .unlockLink {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 150px;

    .linkImage {
      border-radius: 7px;
      object-fit: cover;
      max-height: 150px;
      min-height: 150;
      max-width: 130px;
      min-width: 130px;

      // width: 66px;
      // height: 91px;
      // object-fit: contain;
    }

    .unlockLinkImageWrapper {
      position: relative;

      .unlockVideoIcon {
        position: absolute;
        z-index: 100;
        width: 15px;
        height: 15px;
        top: 10px;
        left: 10px;
        right: auto;
        filter: drop-shadow(2px 4px 6px black);
      }
    }
  }
}

.subs-container {
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 90px;
  padding: 12px 6px;
  background: rgba(77, 77, 77, 0.3);
  height: 120px;
  overflow: hidden;

  .ant-space-item {
    width: 100%;
  }

  .fanSubscriptionPageAvatarImg {
    width: 95px;
    height: 95px;
    border-radius: 50%;
    object-fit: cover;
    margin-left: 5px;
  }

  .detailsWrapper {
    flex: 1;
    width: 100%;
    height: 100%;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    .topRow {
      align-items: center;
      width: 100%;

      .viewsWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #222222;
        padding: 0 6px 2px 6px;
        margin-right: 10px;
        border-radius: 20px;
        height: 100%;
        width: auto;

        .viewsNumber {
          font-family: @primary-font-bold;
          font-size: 10px;
          color: #ffffff;
          white-space: nowrap;
        }
      }
    }

    .middleRow {
      margin-top: 5px;
      margin-bottom: 5px;

      .title-decrypt-text {
        font-family: @primary-font-black;
        font-size: 24px;
        color: #ffffff;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .revenueWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border-radius: 8px;
      width: auto;
    }

    .revenue-label {
      margin-right: 8px;
    }
  }

  .chevron-right {
    width: 60px;
    height: 60px;
    margin-right: 8px;
    margin-left: auto;
    padding: 20px;
    padding-left: 23px;
    border-radius: 50%;
    background-color: rgba(34, 34, 34, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
