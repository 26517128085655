@import url("../../style/index.less");

.incomeMessage {
  display: flex;
  align-items: flex-end;
  max-width: 75%;
  gap: 6px;

  &.min-width-media {
    min-width: 61%;
  }

  .incomeMessageBox {
    flex-grow: 1;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 14px;
    padding-bottom: 17px;
    border-radius: 17px;
    background: rgba(77, 77, 77, 0.2) !important;

    .message-style {
      font-family: @primary-font-medium;
      font-size: 15px;
      line-height: 20px;
      color: #ffffff;
    }
  }
}

li::before {
  content: "";
  margin-left: -11px;
}

li::marker {
  font-size: 1.1em;
}

.accept-media-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: @black-color;
  background: @white-color;
  border: unset;
  font-family: @primary-font-bold;
  font-size: 13px;
  border-radius: 21.5px;
  height: 30px !important;

  &.disabled {
    background: @grey-color;
  }

  &:active,
  &:hover {
    color: @black-color !important;
  }
}

.reject-media-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: unset;
  width: 100%;
  color: @white-color;
  background-color: @light-black-color;
  border: unset;
  font-family: @primary-font-bold;
  font-size: 13px;
  border-radius: 21.5px;
  height: 30px !important;
}
