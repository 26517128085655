@import url("../../../style/index.less");

.authForgotPasswordContentWrapper {
  min-height: 100dvh !important;
  padding: calc(@creator-personal-informations-header-height + 20px) 39px 120px 38px;
}

.logo {
  display: flex;
  justify-content: center;
  align-self: center;
}

.title {
  font-family: @primary-font-bold;
  font-size: 10vw;
  line-height: 11vw;
  text-align: center;
  color: @black-color;
}
