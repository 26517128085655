@import url("../../../style/index.less");

.creator-presentation-video-wrapper {
  position: relative;
  min-height: 100dvh !important;
  padding: 0;

  .creator-presentation-video-header {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-inline: 15px;
    padding-top: 20px;
    z-index: 1000;

    img {
      width: 30px;
    }
  }
}
