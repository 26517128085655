@import url("../../../style/index.less");

.creatorNewPaymentMethodConentWrapper {
  min-height: 100dvh !important;
  padding: calc(@creator-newPaymentMethod-header-height + 10px) 20px 120px 20px;
}

.select {
  width: 100%;

  &.dark {
    .ant-select-selector {
      height: 36px;
      background-color: #212121 !important;
      border: 1px solid #424242;
      border-radius: 6px;

      .ant-select-selection-search {
        color: @white-color;
      }

      .ant-select-selection-item {
        display: flex;
        align-items: center;
        font-family: @primary-font-regular;
        font-style: normal;
        font-size: 15px;
        line-height: 18px;
        color: @white-color;
      }
    }

    .ant-select-arrow {
      color: @white-color;
    }
  }
}