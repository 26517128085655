@import url("../../style/index.less");

.authGenderRadioButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-inline: 10px;

  &.ant-radio-button-wrapper-checked {
    background-color: @medium-green-color;
    color: #ffffff !important;
    border-color: @medium-green-color!important;
  }

  &.ant-radio-button-wrapper-checked::before {
    background: #020202 !important;
    accent-color: rgb(10, 10, 10) !important;
    border-radius: 100%;
    color: white !important;
    content: "\00A0\2713\00A0" !important;
    border: 1px solid #d3d3d3;
    font-weight: bolder;
    font-size: 13px;
    position: absolute;
    z-index: 2;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0.8);
    left: 5px;
    top: 12px;
  }

  .ant-radio-button {
    position: relative;
    width: fit-content;
    height: fit-content;
    z-index: 1;

    input {
      width: 11px;
      height: 11px;
      margin: unset;
      opacity: 1;

      @media only screen and (max-width: 330px) {
        width: 9px;
        height: 9px;
      }
    }
  }

  span {
    font-family: @primary-font-bold;
    font-style: normal;
    font-size: 13px;
    line-height: 15px;
    color: #ffffff;
    @media only screen and (max-width: 330px) {
      font-size: 10px;
      margin-left: -2px;
    }
  }
}

.authGenderRadioButton.ant-radio-button-wrapper-checked span {
  color: #000000;
}

.css-dev-only-do-not-override-ixblex .ant-radio-button-wrapper:first-child {
  border-inline-start: 1px solid @border-input;
}
