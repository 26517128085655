@import url("../../../style/index.less");

.collection-item {
  padding: 3px;

  .collection-box {
    position: relative;
    height: 220px;
    width: 100%;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    .overlay-dimmed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 6px;
      z-index: 1;
      border-radius: 6px;
      pointer-events: none;
    }

    .hide-collection-img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 28%;
      height: auto;
      pointer-events: none;
      z-index: 2;
    }

    .hold-tap-gif {
      position: absolute;
      top: 35%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 9999;
      width: 200px;
      height: auto;
      pointer-events: none;
    }

    .collection-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
      -webkit-user-drag: none;
    }

    .overlay-left,
    .overlay-right {
      position: absolute;
      top: 8px;
      display: flex;
      align-items: center;
    }

    .overlay-left {
      left: 8px;
    }

    .overlay-right {
      right: 8px;
    }

    .overlay-icon {
      width: 13px;
      height: 13px;
      margin-right: 3px;
    }

    .overlay-text {
      font-family: @primary-font-bold;
      color: white;
      font-size: 11px;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    }

    .overlay-bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 50px;
      width: 100%;
      padding: 5px 10px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 40%, #000000 90%);
      color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;

      .collection-description-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow: hidden;

        .collection-description-text {
          font-family: @primary-font-bold;
          font-size: 16px;
          line-height: 20px;
          color: #ffffff;
          margin-left: 1px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.collection-item.edit-mode {
  animation: vibrate 0.3s infinite linear;
}

@keyframes vibrate {
  0% {
    transform: rotate(-2deg);
  }
  50% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(-2deg);
  }
}

.remove-icon {
  position: absolute;
  top: -3px;
  left: -3px;
  width: 25px;
  height: 25px;
  background: #9fa2a880;
  backdrop-filter: blur(10px);
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2;
}
