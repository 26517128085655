@import url("../../../style/index.less");

.unlockLinkUnlockedContentWrapper {
  min-height: 100dvh !important;
  padding: 20px 15px 30px;

  .linkImage {
    max-height: 340px;
    min-height: 340px;
    min-width: 250px;
    max-width: 100%;
    border-radius: 12px;
    object-fit: cover;
  }

  .video-wrapper {
    display: flex;
    justify-content: center;
    max-height: 420px;
    max-width: 80%;

    .linkVideo {
      object-fit: cover;
      border-radius: 12px;
    }
  }
}

.title-file {
  font-family: @primary-font-bold;
  font-size: 18px;
  color: #ffffff;
}

.downloadBtn {
  width: 70%;
  height: 60px;
  font-family: @primary-font-bold;
  font-size: 18px;
  line-height: 24px;
  color: @black-color;
  background-color: @medium-green-color;
  padding: 12px 15px 11px 15px;
  border: 1px solid @medium-green-color;
  border-radius: 15px;

  &:hover {
    color: @black-color!important;
    background-color: @medium-green-color;
    border: 1px solid @medium-green-color!important;
  }

  &:active {
    border: 1px solid @medium-green-color!important;
  }

  &:disabled {
    background-color: @dark-green-color;
    border-color: unset;
  }
}

.top-sticky {
  display: flex;
  align-items: center;
  position: relative;
  height: 66px;
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 20px);
  background-color: #fff;
  border-radius: 17px;
  padding-left: 20px;
  padding-right: 8px;
  margin-top: -55px;
  z-index: 1000;
}

.left-content {
  flex-grow: 1;
}

.text-description {
  font-family: @primary-font-regular;
  margin: 0;
  color: #808080;
  font-size: 11px;
}

.text-free {
  font-family: @primary-font-bold;
  font-size: 22px;
  margin: 0;
  color: #000000;
}

.register-button {
  background-color: @medium-green-color;
  font-family: @primary-font-bold;
  font-size: 16px;
  color: black;
  border: none;
  border-radius: 15px;
  padding: 5px 25px;
  height: 80%;
}

.decrypt-user-container {
  display: flex;
  justify-content: space-between;
  max-width: 550px;
  margin: 0;
  padding: 8px 8px 8px 15px;
  border-radius: 15px;
  border: none;
  background: linear-gradient(101.52deg, #0d0d0d 3.31%, #0c071e 50.09%, #2b1487 98.79%);
}

.decrypt-user-content {
  flex: 2;
  max-width: 65%;

  .decrypt-user-title {
    font-family: @primary-font-bold;
    font-size: 18px;
    line-height: 27px;
    color: @white-color;
  }

  .decrypt-user-description {
    margin-top: 10px;
    font-family: @primary-font-regular;
    font-size: 11px;
    color: #dadada;
  }

  .decrypt-user-button {
    width: 90%;
    background-color: @white-color;
    color: #050505 !important;
    font-family: @primary-font-semi-bold;
    font-size: 13px;
    padding: 16px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 23px;
    margin-top: 15px;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: auto;
  }
}

.decrypt-user-image-col {
  flex: 1;
  max-width: 35%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .decrypt-user-image {
    width: 100%;
    min-height: 120px;
    max-height: 150px;
    object-fit: cover;
    border-radius: 7px;
  }
}
