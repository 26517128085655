@import url("../../style/index.less");

.select {
  &.white {
    .ant-select-selector {
      height: 36px;
      background-color: @white-color;
      border: unset;
      border-radius: 6px;

      .ant-select-selection-item {
        display: flex;
        align-items: center;
        font-family: @primary-font-regular;
        font-style: normal;
        font-size: 15px;
        line-height: 18px;
        color: @grey-color;
      }
    }

    .ant-select-arrow {
      color: @grey-color;
    }
  }
}

.authLanguage {
  width: auto !important;

  .ant-select-selector {
    .ant-select-selection-item {
      font-size: 12px !important;
    }
  }
}

.ant-select-arrow {
  color: #ffffff !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
  border: none;
  color: #ffffff;
}
