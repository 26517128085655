@import url("../../../style/index.less");

.fanNotificationContentWrapper {
  min-height: 100dvh !important;
  padding: calc(@fan-notification-header-height + 23px) 100px
    calc(@footer-height + 20px) 38px;
}

.fanNotificationGroup {
  margin-bottom: 10px;
}

.fanNotificationGroupHeader {
  margin-bottom: 15px;
}

.fanNotificationItem {
  margin-bottom: 10px;
}

.fanNotificationItemContent {
  display: inline;
}

.fanNotificaitionVerifyIcon {
  margin-left: 8px;
}

.fanNotificationName {
  font-family: @primary-font-bold;
  font-size: 12px;
  line-height: 16px;
  color: @white-color;
}

.fanNotificationContent {
  font-family: @primary-font-regular;
  font-size: 12px;
  line-height: 16px;
  color: @white-color;
  margin-left: 8px;
}

.fanNotificationTime {
  font-family: @primary-font-regular;
  font-size: 10px;
  line-height: 12px;
  color: @grey-color;
  margin-left: 8px;
}
