@import url("../../../style/index.less");

.recent-search-suggestion-container {
  margin-top: 20px;

  .row-search-suggestion {
    display: flex;
    align-items: center;

    .recent-search-suggestion-text-container {
      margin-left: 15px;

      .recent-search-suggestion-text {
        font-family: @primary-font-regular;
        font-size: 16px;
        color: @grey-color;
      }
    }
  }
}
