@import url("../../../../../../style/index.less");

.slide-bottom-container {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
  z-index: 10;
  pointer-events: auto;
  cursor: pointer;
  z-index: 6;

  .user-profile-data-container {
    display: flex;
    flex-direction: column;
    padding-inline: 15px;
    padding-bottom: 15px;
    gap: 10px;
    background: linear-gradient(2.27deg, #000000 10%, rgba(0, 0, 0, 0) 90%);

    .user-profile-text {
      font-family: @primary-font-black;
      font-size: 2.6rem;
      color: #ffffff;
      line-height: 3rem;
    }

    .user-profile-images-container {
      display: flex;
      align-items: center;
      height: 55px;
      gap: 10px;

      .user-profile-images {
        object-fit: cover;
        height: 55px;
        max-width: 55px !important;
        flex: 1;
        border-radius: 8px;
      }
    }
  }

  .discover-profile-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 15px;
    width: 100%;
    height: 50px;
    background: transparent;
    background: #1c1c1e;

    .discover-profile-text {
      font-family: @primary-font-semi-bold;
      font-size: 1rem;
      color: #ffffff;
    }

    .discover-profile-chevron-img {
      object-fit: contain !important;
      height: 20px;
      width: 20px;
    }
  }
}
