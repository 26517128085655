@import url("../../../style/index.less");

.fanDirectDiscoverLiveWrapper {
  padding: 0 16px;

  .discoverLiveTitle {
    font-family: @primary-font-bold;
    font-size: 24px;
    color: #ffffff;
  }

  .discoverLiveContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    max-width: 100vw;

    .discoverLiveItem {
      position: relative;
      cursor: pointer;
      width: calc(50% - 4px);
      height: 180px;
      border-radius: 6px;
      overflow: hidden;
      flex-shrink: 0;
      box-sizing: border-box;
    }

    .discoverLiveImageWrapper {
      position: relative;
      width: 100%;
      height: 100%;

      .discoverLiveImage {
        width: 100%;
        height: 100%;
        object-fit: cover;

        &.blurImage {
          filter: blur(10px);
        }
      }

      .discoverLiveTopInfo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 5px 8px;
        display: flex;
        margin-top: 3px;

        .liveTag {
          display: flex;
          align-items: center;
          font-family: @primary-font-semi-bold;
          font-size: 12px;
          color: @white-color;
          background: linear-gradient(45deg, #ff3a9c 0%, #ff2736 100%);
          padding: 0 3px;
          border: unset;
          border-radius: 3px !important;
          margin-right: unset;
        }
      }

      .liveWhoLikesItPrivateOverlay {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: #ffffff;
        text-align: center;
        padding: 10px;

        .privateLinkText {
          font-family: @primary-font-bold;
          color: #ffffff;
          font-size: 22px;
          margin-bottom: 7px;
        }

        .privateLinkButtonButton {
          display: flex;
          align-items: center;
          background-color: #ffffff;
          padding: 5px 15px;
          font-family: @primary-font-bold;
          font-size: 14px;
          border-radius: 20px;
          border: none;
        }
      }

      .discoverLiveInfoOverlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 5px 10px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 70%);
        color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .discoverLiveName {
          font-family: @primary-font-black;
          font-size: 23px;
          line-height: 34px;
          color: #ffffff;
        }

        .discoverLiveName::first-letter {
          text-transform: uppercase;
        }

        .discoverLiveDescription {
          font-family: @primary-font-regular;
          font-size: 12px;
          color: #dadada;
          margin-bottom: 2px;
        }
      }
    }
  }

  .discoverLiveContainer::-webkit-scrollbar {
    display: none;
  }
}
