@import url("../../style/index.less");
.fanBookmarksFeedDetailsContentWrapper {
  height: @vh-100;
  padding-top: @header-height;
  padding-bottom: @footer-height;
  overflow-y: auto;
  scrollbar-width: none !important;

  .slideItemWrapper {
    padding-bottom: 0px;
  }

  .swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet {
    display: none;
  }
}
