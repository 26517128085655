@import url("../../../style/index.less");

.CreatorCreateNewAlbumContentWrapper {
  min-height: 100dvh !important;
  padding: calc(@creator-codePromotion-header-height + 25px) 25px 25px 25px;
  .createNewAlbumLabel {
    color: @white-color;
    font-size: 13px;
    font-family: @primary-font-bold;
  }
  .createNewAlbumInput {
    color: @white-color;
    font-size: 13px;
    font-family: @primary-font-bold;
    background-color: @light-black-color;
    border: 1px solid @light-black-color !important;
    height: 36px !important;
    display: flex;
    align-items: center;
    box-shadow: none;
  }
  .createNewAlbumButton {
    color: @black-color !important;
    font-weight: 700;
    font-size: 21px;
    font-family: @primary-font-bold;
    padding: 20px 50px;
    border-radius: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  }
  .bg-white {
    background-color: @white-color !important;
  }
  .bg-grey {
    background-color: #595959 !important;
  }
}
