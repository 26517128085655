@import url("../../../style/index.less");

.fanProfileHeaderTitle {
  font-family: @primary-font-bold;
  font-size: 16px;
  line-height: 19px;
  color: @white-color;
}

.fanProfileContentWrapper {
  min-height: 100dvh !important;
  padding: calc(@fan-profile-header-height) 0 25px 0;
}

.header-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 20px;
  margin-top: -30px;
  margin-bottom: 40px;
}
.header-text {
  font-family: @primary-font-bold;
  font-size: 16px;
  color: #ffffff;
  left: 20;
  text-align: center;
  flex-grow: 1;
}
.header-text-other-profile {
  font-family: @primary-font-bold;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  flex: 1;
  position: absolute;
  top: 5;
  left: 50%;
  transform: translateX(-50%);
}
.header-my-profile-text {
  font-family: @primary-font-heavy;
  font-size: 22px;
  color: #ffffff;
  left: 20;
  flex-grow: 1;
}

.settings-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-left: 10px;
}

.settings-icon-container img {
  width: 20px;
  height: auto;
}

/* .header-container > .header-text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
} */

.fanProfileTopWrapper {
  display: flex;
  align-items: center;
  padding: 5px 10px 5px 15px;
  margin-top: -25px;
}

.labelWrapper {
  padding: 5px 15px;
}

.nameTitle {
  font-family: @primary-font-semi-bold;
  color: #ffffff;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 5px;
  text-overflow: ellipsis;
  max-width: 100%;
}

.nameTitleOtherProfile {
  font-family: @primary-font-black;
  color: #ffffff;
  font-size: 26px;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 5px;
  text-overflow: ellipsis;
  max-width: 100%;
}

.leftItems {
  display: flex;
  align-items: center;
}

.colValues {
  //max-width: 33%;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;

  &.location {
    display: flex;
    align-items: center;
  }
}

.colAvatar {
  flex: 0 0 auto;
}

.colDetails {
  flex: 1 1 0%;
  min-width: 0;
}

.locationImg {
  margin-right: 3px;
}

.headerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.fanProfileBackground {
  width: 100%;
  height: 160px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.fanProfileAttributeRow {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.fanProfileAttributeValue {
  font-family: @primary-font-black;
  font-size: 20px;
  color: @white-color;
  margin-right: 3px;
}

.fanProfileValue {
  font-family: @primary-font-regular;
  font-size: 14px;
  color: @white-color;
  margin-right: 3px;
}

.fanProfileAttributeTitle {
  font-family: @primary-font-light;
  font-size: 12px;
  color: @white-color;
  white-space: nowrap;

  &.location {
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/* Style général pour les grands écrans */
.fanProfileAttributeValue {
  font-family: @primary-font-black;
  font-size: 20px; /* Taille par défaut pour les écrans larges */
  color: @white-color;
  margin-right: 3px;
}

@media (max-width: 350px) {
  .fanProfileAttributeValue {
    font-size: 11px;
  }
}

@media (min-width: 351px) and (max-width: 380px) {
  .fanProfileAttributeValue {
    font-size: 14px;
  }
}

@media (min-width: 381px) and (max-width: 420px) {
  .fanProfileAttributeValue {
    font-size: 16px;
  }
}

@media (min-width: 421px) and (max-width: 540px) {
  .fanProfileAttributeValue {
    font-size: 18px;
  }

  .colValues {
    margin-right: 25px;
  }
}

@media (min-width: 541px) {
  .fanProfileAttributeValue {
    font-size: 22px;
  }
}

.fanProfileLocationLabel {
  font-family: @primary-font-semi-bold;
  font-size: 14px;
  line-height: 13px;
  color: @white-color;
}

.fanProfileDescriptionLabel {
  //max-width: 250px;
  font-family: @primary-font-Thin;
  font-size: 15px;
  line-height: 20px;
  //text-align: center;
  color: @white-color;
}

.liveModifyButton {
  width: 80px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: @primary-font-bold;
  font-size: 12px;
  line-height: 14.32px;
  color: @black-color;
  background-color: @medium-green-color;
  border: 1px solid @medium-green-color;
  border-radius: 12px;

  &:hover {
    color: @black-color!important;
    background-color: @medium-green-color;
    border: 1px solid @medium-green-color!important;
  }

  &:active {
    border: 1px solid @medium-green-color!important;
  }
}

.fanProfileBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  font-family: @primary-font-bold;
  flex-grow: 1;
  border-radius: 13px;
  font-size: 13px;
  height: 0 !important;
  padding: 14px 18px;

  &:disabled {
    background-color: @light-black-color !important;
    border-color: @light-black-color!important;
    color: @white-color !important;
  }
  &:focus {
    outline: none !important;
  }
  &:active {
    color: @black-color !important;
    border-color: @black-color!important;
  }
}

.editProfileBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  font-family: @primary-font-bold;
  flex-grow: 1;
  border-radius: 13px;
  font-size: 13px;
  height: 0 !important;
  padding: 16px 25px;
  border: none;
  background-color: @background-button-color;
  color: #ffffff;
  margin-right: 3px;

  &:focus {
    outline: none !important;
  }
}

.border-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14.5px;
  background-color: transparent;
  border: 1px solid #ff00c7;
  padding: 1.5px;
}

.subscribeBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  font-family: @primary-font-bold;
  flex-grow: 1;
  border-radius: 13px;
  font-size: 13px;
  height: 0 !important;
  padding: 19px 15px;
  border: none;
  background-color: #ffffff;
  color: #000000;

  &:focus {
    outline: none !important;
  }
}

.share-icon {
  width: 38px;
}

/* Pour les iPhones 13 et similaires */
@media (min-width: 330px) and (max-width: 380px) {
  .editProfileBtn {
    padding: 16px 15px;
  }

  .subscribeBtn {
    font-size: 11px;
    padding: 19px 12px;
  }
}

/* Pour les iPhones 13 et similaires */
@media (min-width: 380px) and (max-width: 410px) {
  .editProfileBtn {
    padding: 16px 20px;
  }

  .subscribeBtn {
    font-size: 12px;
    padding: 19px 12px;
  }
}

/* Pour les iPhones 15 et plus grands */
@media (min-width: 430px) {
  .editProfileBtn {
    font-size: 14px;
    padding: 18px 27px;
  }

  .share-icon {
    width: 42px;
  }
}

.fanProfileWriteBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  font-family: @primary-font-bold;
  flex-grow: 1;
  border-radius: 13px;
  font-size: 13px;
  height: 0 !important;
  padding: 19px 20px;
  border: none;
  background-color: @background-button-color;
  color: #ffffff;
  margin-right: 3px;
}

.unsubscribe {
  padding: 20px 26px;
  background-color: @background-button-color !important;
  border: none !important;
  color: @white-color !important;
  font-size: 15px;
}

.fanProfileIconBtn {
  display: flex;
  justify-content: center;
  background-color: transparent;
  border: none;
  align-items: center;
  padding: 9px 20px;
}

.fanProfileIconBtn img {
  width: 40px !important;
}

.fanProfilePostWrapper {
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
  margin-top: 3px;
}

.fanProfilePost {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

// .fanProfilePrivatePost {
//   filter: blur(4px);
// }

.fanProfilePostLockIcon {
  position: absolute;
  top: 8px;
  right: 11px;
  width: 15px;
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.5));
}

.feedSpinner {
  max-height: 100%;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.feedVideoIcon {
  position: absolute;
  top: 8px;
  right: 30px;
  z-index: 10;
  width: 13px;
  height: 13px;
  filter: drop-shadow(1px 2px 6px black);
}

.divSpin {
  margin: 20px 0;
  padding: 30px 50px;
  text-align: center;
}

.spin {
  color: @medium-green-color;
  font-size: 48px !important;
}

.threedotRight {
  top: 0px !important;
}

.mt-10 {
  margin-top: 10px;
}

.editLive-container {
  display: flex;
  justify-content: space-between;
  max-width: 550px;
  margin-right: 15px;
  margin-left: 15px;
  padding: 10px 20px;
  border-radius: 20px;
  border: 2px solid #ff03a9;
  background: linear-gradient(101.52deg, #0d0d0d 3.31%, #0c071e 50.09%, #2b1487 98.79%);
}

.editLive-content {
  flex: 2;
  max-width: 60%;

  .editLive-title {
    font-family: @primary-font-black;
    font-size: 22px;
    line-height: 28px;
    color: @white-color;
  }

  .editLive-description {
    margin-top: 5px;
    font-family: @primary-font-regular;
    font-size: 14px;
    color: #dadada;
  }

  .editLiveButton {
    width: 100%;
    background-color: @white-color;
    color: #050505 !important;
    font-family: @primary-font-semi-bold;
    font-size: 16px;
    padding: 18px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 23px;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: auto;
  }
}

.editLive-image {
  flex: 1;
  max-width: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.feeds-container {
  margin-top: 15px;

  .ant-tabs-nav {
    margin: 0 !important;
    margin-bottom: -8px !important;

    .ant-tabs-nav-wrap {
      display: flex;
      justify-content: center;
      overflow: visible;

      .ant-tabs-tab {
        padding: 0;
        margin: 0 50px;
        text-align: center;

        .ant-tabs-tab-btn {
          width: 60px;
        }
      }

      .ant-tabs-tab-active {
        border-bottom: none !important;
        color: white !important;

        .ant-tabs-tab-btn {
          border-bottom: 2px solid white !important;
          color: white !important;
        }
      }
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  &.overlay-visible {
    .ant-tabs-nav {
      .ant-tabs-nav-wrap {
        z-index: 9999;
        .ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            border-bottom: none !important;
          }
        }
      }
    }
  }
}

.tab-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
  overflow: visible;

  .opacity-icon {
    opacity: 0.5;
  }

  .swipe-icon {
    position: absolute;
    top: calc(100% - 30px);
    width: 180px;
    z-index: 999999;
  }
}

// @media only screen and (max-width: 360px) {
//   .nameTitle {
//     font-size: 14px !important;

//     &.long {
//       font-size: 10px !important;
//     }
//   }
// }

// @media only screen and (max-width: 460px) {
//   .nameTitle {
//     font-size: 18px !important;
//   }

//   &.long {
//     font-size: 14px !important;
//   }
// }

// @media only screen and (max-width: 560px) {
//   .nameTitle {
//     font-size: 22px !important;
//   }

//   &.long {
//     font-size: 18px !important;
//   }
// }

@media only screen and (max-width: 767px) {
  .fanProfileInfoContainer {
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 8px;

    .fanProfileAttributeValue {
      font-size: 20px;
    }
  }
}
