@import url("../../style/index.less");

.mediaWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;

  .mediaContent {
    position: absolute;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .locksIcon {
    z-index: 100;
  }

  .videosIcon {
    z-index: 100;
    width: 13px;
    height: 13px;

    &.collection {
      width: 24px;
      height: 24px;
    }
  }
}

.ant-image-preview {
  pointer-events: none;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  background-color: rgb(0 0 0 / 80%);
}

.video-preview-wrapper {
  pointer-events: all;
}
