@import url("../../../style/index.less");

.logo {
  display: flex;
  justify-content: center;
  align-self: center;
}

.title-password-confirm {
  font-family: @primary-font-bold;
  font-size: 10vw;
  line-height: 11vw;
  text-align: center;
  color: @white-color;
}

.text {
  font-family: @primary-font-regular;
  font-style: normal;
  font-size: 25px;
  line-height: 24px;
  text-align: center;
}