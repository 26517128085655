@import url("../../style/index.less");

.public-auth-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
  overflow: hidden;

  .profile-public-show-icon-container {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 9999;

    .show-icon {
      width: 45px;
      margin-right: 30px;
    }
  }
}

.public-auth-modal-content {
  width: 100%;
  max-width: 980px;
  height: auto;
  min-height: 60%;
  background-color: #121212;
  border-top-left-radius: 28px;
  border-top-right-radius: 28px;
  box-sizing: border-box;
  position: relative;
  animation: slide-up 0.3s ease-out;
  display: flex;
  flex-direction: column;

  .modal-img-header {
    position: relative;
    width: 100%;
    height: 310px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;

    .header-arrow-back {
      position: absolute;
      left: 20px;
      top: 15px;
    }

    .modal-img-header-info {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #121212 100%);
      color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 10px;
      padding-top: 55px;

      .modal-img-header-info-text {
        font-family: @primary-font-bold;
        font-size: 32px;
        color: #ffffff;
        line-height: 38px;
      }
    }
  }

  .modal-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 10px 20px;
    padding-bottom: 20px;

    .center-loader {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.public-auth-background-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 65px;
  background: rgba(77, 77, 77, 0.4);
}

.public-auth-btn {
  width: 100%;
  height: 70px;
  font-family: @primary-font-bold;
  font-size: 16px;
  color: #ffffff;
  border: none;
  border-radius: 40px;
  background: rgba(77, 77, 77, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 10px;
  justify-content: flex-start;

  & > span {
    flex: 1;
    text-align: center;
    padding-right: 32px;
  }

  &:hover {
    color: @grey-color!important;
    border: 1px solid @grey-color!important;
  }

  &:active {
    border: 1px solid @grey-color!important;
  }

  &.small {
    height: 60px !important;
  }
}

.public-auth-bottom-text-label {
  font-family: @primary-font-bold;
  color: #ffffff;
  margin-right: 5px;
}

.public-auth-bottom-text-register {
  font-family: @primary-font-bold;
  color: @medium-green-color;
  cursor: pointer;
}

.public-auth-custom-input {
  border: none;
  border-radius: 40px;
  color: #ffffff;
  background: rgba(77, 77, 77, 0.2);
  padding: 8px 30px 8px 30px;
  width: 100%;
  height: 55px;
  font-size: 16px;

  input {
    background: transparent;
    color: #ffffff !important;
  }

  input::placeholder {
    color: @placeholder;
    text-align: center;
  }

  &.ant-input-affix-wrapper {
    background: rgba(77, 77, 77, 0.2) !important;
  }
}

.public-auth-custom-input::placeholder {
  color: @placeholder;
  text-align: center;
}

.public-register-custom-input {
  font-family: @primary-font-regular;
  color: #ffffff;
  height: 45px;
  background: @background-input;
  border-radius: 12px;
  border: 1px solid @border-input;

  input {
    font-family: @primary-font-regular;
    color: #ffffff;
    background-color: transparent;

    &::placeholder {
      color: @placeholder !important;
    }
  }

  &.ant-input-affix-wrapper {
    background-color: @background-input !important;
  }

  &.input-error {
    border: 1px solid #ff6600;
  }
}

.public-register-custom-input::placeholder {
  color: @placeholder;
}

.public-register-custom-input:focus {
  border-color: @border-input !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
}

.public-register-custom-input:hover {
  border-color: @border-input !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
}

.public-register-custom-input.uppercaseInput input {
  text-transform: uppercase;
}

.header-icon-grey {
  background-color: rgba(95, 95, 95, 0.8);
  height: 5px;
  width: 26%;
  border-radius: 15px;
}

.header-public-login {
  position: relative;
  width: 100%;

  .header-arrow-back {
    position: absolute;
    left: 20px;
    top: 15px;
  }
}

.login-view {
  .loly-logo {
    width: 40px;
  }

  .public-login-title {
    font-family: @primary-font-black;
    color: #ffffff;
    font-size: 30px !important;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .row-divider {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .row-forgot-password {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
}

.keyboard-open {
  transform: none;
  transition: none;
}

@media (max-width: 350px) {
  .modal-img-header {
    height: 230px !important;
  }

  .modal-img-header-info-text {
    font-size: 24px !important;
    line-height: 28px !important;
  }
}

@media (min-width: 351px) and (max-width: 380px) {
  .modal-img-header {
    height: 250px !important;
  }

  .modal-img-header-info-text {
    font-size: 26px !important;
    line-height: 30px !important;
  }
}

@media (min-width: 381px) and (max-width: 420px) {
  .modal-img-header {
    height: 250px !important;
  }

  .modal-img-header-info {
    padding-top: 35px !important;
  }

  .loly-logo {
    width: 30px !important;
  }

  .modal-img-header-info-text {
    font-size: 28px !important;
    line-height: 32px !important;
  }
}

@media (min-width: 421px) and (max-width: 540px) {
  .modal-img-header {
    height: 310px !important;
  }
  .row-forgot-password {
    margin-top: 40px !important;
  }

  .row-divider {
    margin-bottom: 20px !important;
    margin-top: 20px !important;
  }
}

@media (min-width: 541px) {
  .modal-img-header {
    height: 310px !important;
  }
  .row-forgot-password {
    margin-top: 40px !important;
  }
  .row-divider {
    margin-bottom: 20px !important;
    margin-top: 20px !important;
  }
}

@media only screen and (max-width: 768px) {
  .register-view.keyboard-open {
    transform: translateY(-30px);
    transition: transform 0.3s ease;
  }

  .login-view.keyboard-open {
    transform: translateY(-40px);
    transition: transform 0.3s ease;
  }
}
