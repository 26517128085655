@import "../../../style/index.less";

.fanSearchContentWrapper {
  min-height: 100dvh !important;
  padding: calc(@fan-search-header-height) 0 calc(@footer-height + 20px) 21px;

  .direct-links-wrapper {
    overflow-x: auto;
    width: 100%;
    white-space: nowrap;
    margin-top: 15px;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}

.background-category {
  background-color: #121212 !important;
}

.custom-margin-title {
  margin-top: -5px;
}

.cancel-button {
  font-family: @primary-font-regular;
  font-size: 16px;
  color: @grey-color;
}
.text-end {
  text-align: end;
}
.SearchSeeAllResults {
  font-size: 16px;
  font-family: @primary-font-bold;
  color: @medium-green-color;
}
.mx-auto {
  margin-left: auto !important ;
  margin-right: auto !important ;
}

.no-suggestion-found {
  color: #5ad965;
  font-family: "SF-Pro-Display-Bold";
  font-size: 16px;
  margin-top: 2.5rem;
  text-align: center;
}
// .content-title{
//   margin-bottom:-18px;
// }

.no-creator-text {
  font-family: @primary-font-regular;
  font-size: 15px;
  color: @grey-color;
  display: flex;
  text-align: center;
}
