@import url("../../../../style/index.less");

.discover-video-container {
  min-height: 100vh;
  background-color: black;
  overscroll-behavior-y: contain; // Prevent overscroll bounce

  .scroll-container {
    height: 100vh;
    width: 100%;
    overflow-y: scroll;
    scroll-snap-type: y proximity; // Changed from mandatory to proximity for better mouse wheel experience
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overscroll-behavior-y: contain;
    touch-action: pan-y pinch-zoom;

    &::-webkit-scrollbar {
      display: none;
    }

    .snap-section {
      scroll-snap-align: center;
      scroll-snap-stop: normal; // Changed from always to normal for smoother mouse wheel
      height: 100vh;
      width: 100%;
      position: relative;
      overflow: hidden;
    }
  }
}

.hls-video-player {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #000;
  overflow: hidden;
  cursor: pointer;

  .loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    .spinner {
      width: 40px;
      height: 40px;
      border: 3px solid rgba(255, 255, 255, 0.3);
      border-radius: 50%;
      border-top-color: #fff;
      animation: spin 1s ease-in-out infinite;
    }
  }

  .error-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 14px;
  }

  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s ease;
    z-index: 2;

    img {
      width: 45px;
      height: 45px;
    }
  }

  &:hover .play-icon {
    opacity: 0.7;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  .hls-video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.2);

    .overlay-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: white;

      .error-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;

        span {
          font-size: 14px;
          opacity: 0.8;
        }

        button {
          background: rgba(255, 255, 255, 0.2);
          border: 1px solid rgba(255, 255, 255, 0.3);
          color: white;
          padding: 8px 16px;
          border-radius: 4px;
          cursor: pointer;
          font-size: 14px;
          transition: all 0.2s ease;

          &:hover {
            background: rgba(255, 255, 255, 0.3);
          }
        }
      }

      .loading-spinner {
        width: 40px;
        height: 40px;
        border: 3px solid rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        border-top-color: white;
        animation: spin 1s linear infinite;
      }

      .play-icon {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.8;
        transition: opacity 0.2s ease;

        &:hover {
          opacity: 1;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.hls-video-player {
  video {
    width: 100%;
    height: 100%;
    //object-fit: none;
  }

  .progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background-color: rgba(31, 41, 55, 0.8);

    .progress {
      height: 100%;
      background-color: white;
      transition: width 0.2s ease;
    }
  }

  .video-controls {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.6), transparent);

    .username {
      color: white;
      font-weight: bold;
      margin: 0;
    }

    .description {
      color: white;
      font-size: 0.875rem;
      margin-top: 0.25rem;
    }

    .progress-text {
      color: white;
      font-size: 0.75rem;
      margin-top: 0.5rem;
    }
  }

  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .play-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.7;

      img {
        width: 45px;
        height: 45px;
      }
    }
  }

  .sidebar-actions {
    position: absolute;
    right: 1rem;
    bottom: 5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .action-button {
      padding: 0.5rem;
      border-radius: 9999px;
      background: transparent;
      border: none;
      cursor: pointer;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      .icon {
        width: 2rem;
        height: 2rem;
        color: white;
        transition: color 0.2s ease;

        &.liked {
          color: #ef4444;
          fill: #ef4444;
        }
      }
    }
  }

  .mute-button {
    position: absolute;
    top: 12px;
    right: 12px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
    transition: background-color 0.2s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }

    svg {
      width: 20px;
      height: 20px;
      fill: currentColor;
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.mute-button-text {
  font-family: @primary-font-regular;
  font-size: 12px;
  color: #ffffff;
  margin-left: 3px;
}
