@import url("../../../style/index.less");

.fan-collection-page-wrapper {
  min-height: 100dvh !important;
  padding: @creator-collection-detail-header-height 20px 30px 20px;

  .collection-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: -60px;
  }

  .collection-title-text {
    font-family: @primary-font-semi-bold;
    font-size: 32px;
    line-height: 38px;
    color: #ffffff;
  }

  .collection-price-text {
    font-family: @primary-font-semi-bold;
    font-size: 14px;
    color: #ffffff;
  }

  .img-container {
    position: relative;
    width: 100%;
    display: inline-block;
    overflow: hidden;
    border-radius: 20px;
    margin-top: 20px;

    .image-current-wrapper {
      display: flex;
      justify-content: center;
      position: relative;
      height: 320px;
      width: 100%;

      .current-image {
        width: 100%;
        max-width: 350px;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
      }

      .current-video-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 34px;
        height: 34px;
        transform: translate(-50%, -50%);
      }
    }
  }

  .img-switch-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    .preview-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60%;

      .preview-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 5px;
        border-radius: 10px;
        overflow: hidden;

        &.active {
          border: 1px solid #ffffff;
          border-radius: 10px;
          padding: 1px;
        }

        .preview-image {
          width: 50px;
          height: 50px;
          border-radius: 8px;
          transition:
            transform 0.3s ease,
            filter 0.3s ease;
          object-fit: cover;

          &.blur {
            filter: blur(5px);
          }
        }

        .video-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 18px;
          height: auto;
          pointer-events: none;
        }
      }

      // .preview-image {
      //   margin: 0 7px;
      //   transition:
      //     transform 0.3s ease,
      //     filter 0.3s ease;
      //   object-fit: cover;

      //   &.darkened {
      //     filter: brightness(50%);
      //     height: 30px;
      //     width: 30px;
      //     opacity: 0.6;
      //     border-radius: 6px;
      //   }

      //   &.current {
      //     transform: scale(1.1);
      //     width: 40px;
      //     height: 40px;
      //     box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
      //     border-radius: 8px;
      //   }

      //   &::hover {
      //     transform: scale(1.05);
      //     filter: brightness(80%);
      //   }
      // }
    }
  }

  .resume-container {
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    width: 100%;
    height: auto;
    border-radius: 15px;
    background: #4d4d4d33;

    .description-text {
      font-family: @primary-font-light;
      font-size: 15px;
      color: #f7f7f7;
    }
  }

  .collection-content-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border: 1.5px solid #ffffff;
    border-radius: 15px;
    padding: 15px 20px;
    margin-bottom: 70px;

    .collection-content-col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 10px;

      .collection-content-title {
        font-family: @primary-font-bold;
        font-size: 22px;
        color: #ffffff;
      }
      .collection-content-text {
        font-family: @primary-font-regular;
        font-size: 12px;
        color: #f7f7f7;
        text-align: center;
      }
    }
  }

  .collection-btn-sticky-row {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    padding: 0 15px;
    background: linear-gradient(to top, rgba(1, 1, 1, 1) 0%, rgba(1, 1, 1, 0) 100%);
    z-index: 1000;

    .collection-sticky-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border: none;
      border-radius: 20px;
      height: 55px;
      background: @medium-green-color;
      color: #000000;
      font-family: @primary-font-bold;
      font-size: 18px;
      // text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    }
  }

  .private-image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;

    .private-content-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      max-width: 80%;
      max-height: 80%;
      width: 100%;
      height: 100%;
    }

    .private-large-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
      position: absolute; /* Positionner l'image en arrière-plan */
      top: 0;
      left: 0;
    }

    .private-text-container {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 1;
      padding: 10px;

      .reserved-title {
        font-family: @primary-font-bold;
        font-size: 26px;
        color: #ffffff;
        margin-bottom: 10px;
        text-align: center;
      }

      .reserved-description {
        font-family: @primary-font-regular;
        font-size: 12px;
        color: #ffffff;
        margin-bottom: 20px;
        text-align: center;
      }
    }

    .pay-to-see-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: @primary-font-bold;
      font-size: 16px;
      color: @black-color;
      background-color: #ffffff;
      padding: 20px 25px;
      margin-top: 10px;
      border-radius: 20px;
    }
  }
}
