@import url("../../style/index");

.suggestionPageWrapper {
  padding: 0 8px;
  padding-bottom: 60px;

  .suggestionsSubTitle {
    font-family: @primary-font-bold;
    font-size: 18px;
    color: #ffffff;
  }

  /* FIRST AND THIRD SECTION STYLES */
  .suggestionsRectangleContainer {
    display: flex;
    overflow-x: auto;
    padding: 3px 0;
    gap: 5px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    white-space: nowrap;
    max-width: 100vw;

    .suggestionItemWrapper {
      display: flex;
      align-items: flex-end;

      .numberIcon {
        margin-right: -17px;
      }
    }

    .suggestionRectangleItem {
      position: relative;
      cursor: pointer;
      width: 135px;
      height: 190px;
      border-radius: 6px;
      overflow: hidden;
      flex-shrink: 0;
    }

    .suggestionImageWrapper {
      position: relative;
      width: 100%;
      height: 100%;

      .suggestionImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .suggestionOverlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 90px;
        padding: 0 3px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 70%);
        color: #ffffff;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;

        .suggestionText {
          font-family: @primary-font-black;
          font-size: 18px;
          margin-bottom: 4px;
          text-align: center;
          color: #ffffff;
        }

        .followButton {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 80%;
          height: 25px;
          background-color: #ffffff;
          border: none;
          font-family: @primary-font-bold;
          color: #000000;
          border-radius: 2px 2px 0 0;
          font-size: 13px;
          cursor: pointer;
        }
      }
    }
  }

  .suggestionsRectangleContainer::-webkit-scrollbar {
    display: none;
  }

  /* SECOND SECTION STYLES */
  .suggestionsContainer {
    display: flex;
    overflow-x: auto;
    padding: 3px 0;
    gap: 5px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    white-space: nowrap;
    max-width: 100vw;

    .suggestionItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      margin-right: 3px;

      .suggestionText {
        font-family: @primary-font-regular;
        color: #f1f1f1;
        margin-top: 8px;
        font-size: 12px;
        text-align: center;
      }
    }
  }

  .suggestionsContainer::-webkit-scrollbar {
    display: none;
  }

  /* FOURTH SECTION STYLES */
  .suggestionsWeeklySectionContainer {
    display: flex;
    overflow-x: auto;
    padding: 16px 0;
    gap: 10px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    white-space: nowrap;
    max-width: 100vw;

    .suggestionWeeklySectionItem {
      position: relative;
      cursor: pointer;
      width: 300px !important;
      height: 300px;
      border-radius: 10px;
      overflow: hidden;
      flex-shrink: 0;
    }

    .suggestionWeeklySectionImageWrapper {
      position: relative;
      width: 100%;
      height: 100%;

      .suggestionImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .suggestionVerifiedOverlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 5px 10px;
        display: flex;
        margin-left: 3px;
        margin-top: 3px;

        .suggestionVerifiedTitle {
          font-family: @primary-font-bold;
          font-size: 15px;
          color: #ffffff;
          margin-bottom: 2px;
          margin-left: 5px;
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        }
      }

      .suggestionInfoOverlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 5px 10px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 70%);
        color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .suggestionTitle {
          font-family: @primary-font-black;
          font-size: 40px;
          color: #ffffff;
          margin-bottom: 2px;
        }

        .suggestionTitle::first-letter {
          text-transform: uppercase;
        }

        .suggestionDescription {
          font-family: @primary-font-regular;
          font-size: 12px;
          color: #ffffff;
          margin-bottom: 2px;
        }
      }
    }
  }

  .suggestionsWeeklySectionContainer::-webkit-scrollbar {
    display: none;
  }
}

.suggestionFourTitleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 30px;
  width: 100%;
  padding: 0 12px 0 0;

  .weeklySelectionTitle {
    font-size: 56px;
    line-height: 65px;
    color: transparent;
    -webkit-text-stroke: 2.5px white;
    text-shadow: 0 0 10.5px rgba(255, 255, 255, 0.5);
    font-family: @primary-font-bold;
    display: inline-block;
  }
}

@media (min-width: 979px) {
  .suggestionsWeeklySectionContainer {
    max-width: 980px !important;
  }
}
