@import url("../../../style/index.less");

.creatorMessageContentWrapper {
  min-height: 100dvh !important;
  padding: @creator-message-header-height 22px 120px 22px;
}

.chatTabs {
  .ant-tabs-nav {
    margin: 0 0 20px 0;

    .ant-tabs-nav-list {
      width: 100%;
    }

    .ant-tabs-tab {
      border-radius: 20px;
      padding: 5px 8px 5px 8px;

      .ant-tabs-tab-btn {
        font-family: @primary-font-semi-bold;
        font-style: normal;
        font-size: 14px;
        color: @white-color;
      }

      + .ant-tabs-tab {
        margin: 0 0 0 10px;
      }
    }

    .ant-tabs-tab-active {
      background-color: @white-color;

      .ant-tabs-tab-btn {
        color: @black-color;
      }
    }

    .ant-tabs-ink-bar {
      display: none;
    }
  }

  &.creator-nav-tabs {
    .ant-tabs-nav {
      margin: 0 0 0 0;
    }
  }
}

@media only screen and (max-width: 380px) {
  .chatTabs {
    .ant-tabs-nav {
      .ant-tabs-tab {
        padding: 5px 8px 5px 8px;

        .ant-tabs-tab-btn {
          font-size: 12px;
        }

        + .ant-tabs-tab {
          margin: 0 0 0 7px;
        }
      }
    }
  }
}
