@import url("../../../style/index.less");

.creatorPersonalInformationContentWrapper {
  min-height: 100dvh !important;
  padding: calc(@creator-personal-informations-header-height + 20px) 20px 120px 20px;
}
.bg-light {
  background-color: @medium-green-color !important;
  color: @black-color !important;

  &:disabled {
    background-color: @light-black-color !important;
    color: @white-color !important;
  }
}
.user-type {
  height: 20px;
  display: inline-block;
}
.creator-phone-details {
  height: 60px;
}

.accountType {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 8px 18px;
}

.accountTypeBecomingCreator {
  background: rgba(77, 77, 77, 0.4);
  border-radius: 16px;
  padding: 8px 18px;
}

.typeAccountRow {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.individual-div {
  display: flex;
  align-items: center;
  padding: 8px 14px;
  background-color: #ffffff;
  border-radius: 20px;
  margin-right: 10px;
  height: 38px;

  &.professional {
    font-size: 11px !important;
  }
}

.individual-btn {
  display: flex;
  align-items: center;
  font-family: @primary-font-bold;
  padding: 8px 20px;
  height: 38px;
  background: rgba(77, 77, 77, 0.4);
  color: #ffffff;
  border-radius: 20px;
  margin-right: 10px;
  border: none;
}
