@import url("../../../../style/index.less");

.create-presentation-video-wrapper {
  min-height: 100dvh !important;
  padding: 20px 10px 20px 10px;

  .presentation-video-chart-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background: linear-gradient(180deg, rgba(46, 46, 54, 0) 0%, rgba(46, 46, 54, 0.4) 100%);
    width: 100%;

    .presentation-video-chart-data-container {
      display: flex;
      justify-content: space-between;
      padding: 15px 15px 0;

      .presentation-video-chart-data-item-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .presentation-video-chart-data-title {
          font-family: @primary-font-bold;
          font-size: 16px;
          color: #ffffff;
        }

        .presentation-video-chart-data-description {
          font-family: @primary-font-regular;
          font-size: 10px;
          color: #b7b7cb;
        }
      }
    }

    .presentation-video-no-chart-data-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      padding: 15px 10px;
      width: 100%;

      .presentation-video-no-chart-data-title {
        font-family: @primary-font-semi-bold;
        font-size: 24px;
        color: #ffffff;
      }

      .presentation-video-no-chart-data-description {
        font-family: @primary-font-regular;
        font-size: 11px;
        color: #7c7c83;
        text-align: center;
        margin-top: 6px;
      }
    }
  }
}
