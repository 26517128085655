@import url("../../../style/index.less");

.linkCopiedBanner {
  background-color: @medium-green-color;
  color: @white-color;
  width: 100vw;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;

  &.hidden {
    display: none;
  }
}

.unlockLinkDetailsContentWrapper {
  min-height: 100dvh !important;
  padding: @creator-unlock-link-details-header-height 20px 30px 20px;

  .unlockLinkDetailsWrapper {
    display: flex;
    flex-direction: column;
    gap: 14px;

    .firstRow {
      display: flex;
      align-items: center;

      .imagePriceWrapper {
        display: flex;
        margin-top: 10px;

        .decrypt-img {
          border-radius: 16px;
          object-fit: cover;
          max-height: 150px;
          min-height: 150px;
          max-width: 150px;
          min-width: 150px;
        }

        .unlockVideoIcon {
          position: absolute;
          z-index: 100;
          width: 30px;
          height: 30px;
          min-width: 30px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;
        }
      }
    }

    .actionBtnWrapper {
      .ant-space-item {
        flex-grow: 1;
      }
    }

    .statWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
    }

    .redButton {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 25px;
      background-color: transparent;
      border-color: @red-color;
      border-radius: 25px !important;

      &:hover,
      &:active {
        color: @red-color;
        border-color: @red-color;
      }
    }

    .transactionWrapper {
      display: flex;
      gap: 23px;
      width: 100%;
      margin-bottom: 10px;

      :first-child {
        margin-right: auto;
      }

      .statsBadge {
        color: @black-color;
        background-color: @white-color;
        border-radius: 15px;
        text-align: center;
        font-size: 10px;
        padding: 5px 8px;

        &.negative {
          color: @white-color;
          background-color: @light-black-color;
        }
      }
    }
  }
}

.center-content {
  flex: 3;
  text-align: center;
}

.input-title {
  height: 40px;
  font-family: @primary-font-bold;
  font-size: 22px;
  color: @white-color;
  background: transparent;
  text-align: center;
  background-color: rgb(27, 27, 27);
  border-radius: 30px;
  border: none;
}

.detailWrapper {
  width: 100%;
  height: 100%;
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  .viewsWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    padding: 0 6px 2px 6px;
    margin-right: 10px;
    border-radius: 20px;
    height: 100%;
  }

  .detailMiddleRow {
    margin-top: 7px;
    margin-bottom: 10px;
    overflow: hidden;

    .title-decrypt-text {
      font-family: @primary-font-black;
      font-size: 22px;
      color: #ffffff;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .unitPriceWrapper {
    display: inline-block;
    background-color: #ffffff;
    padding: 8px 12px;
    border-radius: 20px;
    width: auto;
  }

  .detailTopRow {
    align-items: center;
  }

  .revenue-label {
    margin-right: 8px;
  }

  .truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.delete-access-button {
  font-family: @primary-font-semi-bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px !important;
  background-color: #ffffff;
  border-radius: 25px !important;
  font-size: 18px !important;
}

.whiteOutlinedButtonWithRoundedBorder {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-color: #ffffff;
  border-radius: 20px !important;
  height: 50px !important;

  &:active,
  &:hover {
    border-color: @white-color !important;
    color: @white-color !important;
  }
}

.font-12-light {
  font-family: @primary-font-light;
  font-size: 12px;
}

.detail-decrypt-price {
  font-family: @primary-font-semi-bold;
  font-size: 14px;
  color: #000000;
}
