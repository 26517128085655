@import url("../../../../../style/index.less");

.video-upload-thumbnail-container {
  position: relative;
  border-radius: 12px;
  height: 175px;

  &.selected {
    padding: 2px;
    border: 1px solid #ffffff;
  }

  .presentation-video-thumbnail-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
  }

  .video-upload-thumbnail {
    object-fit: cover;
    border-radius: 10px;
    width: 100%;
    height: 100%;
  }

  .trash-icon {
    position: absolute;
    width: 35%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .status-wrapper {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: linear-gradient(to top, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0));
    padding: 3px;

    .status-icon {
      width: 45%;

      svg {
        width: 90%;
        height: 90%;
        fill: white;
      }
    }

    .status-info-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      padding-bottom: 5px;

      .status-title {
        font-family: @primary-font-heavy;
        font-size: clamp(14px, 4.5vw, 24px);
        color: #ffffff;

        &.rejected {
          color: #ff3064;
        }

        &.exhausted {
          color: #ffd230;
        }
      }

      .status-description {
        font-family: @primary-font-regular;
        font-size: clamp(10px, 2.4vw, 18px);
        color: #9ba1b3;
        text-align: center;
      }
    }
  }
}
