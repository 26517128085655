@import url("../../../style/index.less");

.fanLivesContentWrapper {
  padding-top: @fan-lives-header-height;
  min-height: 100dvh !important;
}

.gradient-background-live-event {
  background: linear-gradient(180deg, #0c0734 0%, #111111 46.5%);
}

.header-live {
  top: 0;
  width: 100vw;
  min-width: 350px;
  max-width: 980px;
  background-color: transparent !important;
  line-height: unset !important;
  padding-inline: unset !important;
  padding: 17px 14px 14px 17px !important;
  z-index: 100;
  height: @header-height !important;
  margin-top: -80px;
}

.label-tabs {
  display: flex;
  align-items: center;
}

.icon-tabs {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-right: 5px;
}

.live-tabs {
  .ant-tabs-nav {
    margin: 0 0 20px 14px;

    .ant-tabs-nav-list {
      width: 100%;
      border: none;
      box-shadow: none;
    }

    .ant-tabs-tab {
      border-radius: 20px;
      padding: 5px 15px;

      .ant-tabs-tab-btn {
        font-family: @primary-font-semi-bold;
        font-style: normal;
        font-size: 16px;
        color: @white-color;
      }

      + .ant-tabs-tab {
        margin: 0 0 0 10px;
      }
    }

    .ant-tabs-tab-active {
      background-color: @white-color;

      .ant-tabs-tab-btn {
        color: @black-color;
      }
    }

    .ant-tabs-ink-bar {
      display: none;
    }
  }

  .ant-tabs-nav::before {
    border: none !important;
  }
}

.new-live-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 15px;
  background: #ffffff1a;
  border-radius: 20px;
  border: none;

  .new-live-text {
    font-family: @primary-font-semi-bold;
    font-size: 15px;
    color: #ffffff;
    margin-left: 5px;
  }
}
