@import url("../../style/index.less");

.social-items-row {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 15px;

  .social-items-container {
    display: flex;
    justify-content: center;
    border-radius: 20px;
    gap: 15px;

    .shareModalSocialLinkLabel {
      font-family: @primary-font-regular;
      font-size: 12px;
      line-height: 12px;
      color: @grey-color;
    }
  }
}

.share-modal-icon {
  width: 54px !important;
}
