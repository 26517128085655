@import "../../style/index.less";

.slideItemWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: transparent;
  //padding-bottom: 80px;
}

.slideItemHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: unset !important;
  line-height: unset !important;
  padding-inline: unset !important;
  padding: 11px 16px !important;
  background: transparent !important;
}

.slideItemNameLabel {
  font-family: @primary-font-bold;
  font-size: 15px;
  line-height: 18px;
  color: @white-color !important;
}

.main-container-img {
  max-height: @fan-home-page-main-container-height !important;
}

.slideItemPostImg {
  position: relative;
  width: 100%;
  height: 100%;
  // background-size: cover;
  // background-position: center;
  // background-repeat: no-repeat;

  .postImg {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media only screen and (min-width: 1024px) {
      object-fit: contain;
    }
  }
}

.slideItemBookmark {
  position: absolute !important;
  top: 14px !important;
  right: 19px !important;
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.5));
  width: 17px;
  height: 18px;
}

.slideItemFooter {
  height: unset !important;
  line-height: unset !important;
  background: transparent !important;
  padding-inline: unset !important;
  padding: 14px 16px !important;
}

.slideItemLike {
  width: 23px;
  height: 20px;
}

.slideItemLikeLabel {
  font-family: @primary-font-regular;
  color: @white-color;
  font-size: 15px;
  line-height: 15.51px;
}

.slideItemLikeSubLabel {
  font-family: @primary-font-bold !important;
  color: @white-color;
  font-size: 15px;
  line-height: 15.51px;
  margin-left: 5px;
}

.slideItemLikeDescription {
  font-family: @primary-font-light !important;
  color: @white-color;
  font-size: 14px;
  font-weight: 100;
  line-height: 15.51px;
  margin-left: 8px;
}

.mb-8 {
  margin-bottom: 8px;
}
