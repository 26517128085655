@import url("../../style/index.less");

.messageInput {
  display: flex;
  align-items: center;
  font-family: @primary-font-regular;
  height: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  background: transparent;
  padding: 5px 15px 5px 25px;
  background-color: @background-input !important;
  border: none !important;
  border-radius: 28px;

  input {
    color: @white-color;
    background: transparent;

    &::placeholder {
      color: @placeholder;
    }
  }

  .messageSendLabel {
    font-family: @primary-font-bold;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    color: @medium-green-color;
  }
}
