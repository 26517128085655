@import url("../../../style/index.less");

.gradient-background-subs {
  background: linear-gradient(180deg, #07192d 0%, #121212 54.5%);
}

.fanSubscriptionPageContentWrapper {
  min-height: 100dvh !important;
  padding: calc(@creator-codePromotion-header-height) 15px 20px 15px;

  .subs-container {
    display: flex;
    width: 100%;
    align-items: center;
    border-radius: 90px;
    padding: 12px 6px;
    background: rgba(77, 77, 77, 0.3);
    overflow: hidden;

    .ant-space-item {
      width: 100%;
    }

    .fanSubscriptionPageAvatarImg {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      object-fit: cover;
      margin-left: 5px;
    }

    .detailsWrapper {
      flex: 1;
      width: 100%;
      height: 100%;
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;

      .fanSubscriptionCreatorName {
        font-size: 24px;
        color: @white-color;
        font-family: @primary-font-black;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .fanSubscriptionStatus {
        font-size: 11px;
        font-family: @primary-font-regular;
        color: @white-color;
      }
      .fanSubscriptionAssetButton {
        width: auto;
        height: 24px;
        background-color: @white-color;
        box-shadow: 0px 1px 3px 0px #00000026;
        color: @black-color;
        font-size: 11px;
        font-family: @primary-font-bold;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        padding: 2px 20px;
      }
    }

    .chevron-right {
      width: 60px;
      height: 60px;
      margin-right: 8px;
      margin-left: auto;
      padding: 20px;
      border-radius: 50%;
      background-color: rgba(34, 34, 34, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .inactive {
    color: #ffffff !important;
    background-color: #ff3064 !important;
    border: none;
  }
}
.FanSubscriptionPageModel {
  max-width: 320px;

  .ant-modal-content {
    background: linear-gradient(to top, #050505, #232323) !important;
    padding: 16px 0 0 0;
    border-radius: 25px;

    .ant-modal-header {
      background-color: transparent;
      padding: 0 20px;
      margin-bottom: 10px;
      margin-top: 10px;

      .ant-modal-title {
        font-family: @primary-font-bold;
        font-size: 28px;
        line-height: 30px;
        text-align: center;
        color: @white-color;
      }
    }
  }
  .justify-center {
    justify-content: center;
  }
  .ant-modal-footer {
    text-align: center;
    margin-top: 0;
    .fanSubscriptionValid {
      font-family: @primary-font-regular;
      font-size: 16px;
      line-height: 23.87px;
      text-align: center;
      color: @white-color;
      display: inline-block;
      margin-top: 10px;
    }
    .fanSubscriptionStatus {
      font-family: @primary-font-regular;
      font-size: 16px;
      line-height: 19.09px;
      text-align: center;
      color: @grey-color;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .fanSubscriptionValidStatus {
      font-family: @primary-font-regular;
      font-size: 16px;
      line-height: 19.09px;
      text-align: center;
      color: @grey-color;
      margin-top: 5px;
      margin-bottom: 10px;
      padding: 0 20px;
    }
    .row {
      width: 100%;
      justify-content: center;
      padding: 15px 0;

      .closeBtn {
        font-family: @primary-font-regular;
        font-style: normal;
        font-size: 18px;
        line-height: 21px;
        color: @white-color;
      }

      .CancelBtn {
        font-family: @primary-font-bold;
        font-style: normal;
        font-size: 18px;
        line-height: 24px;
        color: @black-color;
        height: 100%;

        .confirm-btn-background {
          font-family: @primary-font-bold;
          background-color: @white-color;
          padding: 15px 25px;
          border-radius: 30px;
        }
      }
      .SubscriptionExtendBtn {
        font-family: @primary-font-bold;
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        color: @black-color;
        height: auto;

        .confirm-btn-background {
          font-family: @primary-font-bold;
          background-color: @white-color;
          padding: 10px 20px;
          border-radius: 20px;
          text-wrap: pretty;
        }
      }
    }
  }
}

.noSubscriptionsMessage {
  width: 100%;
  font-family: @primary-font-regular;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 35px;
}
